@charset 'UTF-8';

@import "reset", "mixins", "grid-system", "variables", "components", "filemanager";

html,
body {
	margin: 0px;
}

body {
	font-size: 13px;
	font-family: $font;
	background: #8e8e8e;

	&.right-panel-active {
		.design-panel {
			margin-right: 45px;
		}
	}
}

header {
	background: $color;
	height: 45px;
	box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.52);
	position: fixed;
	z-index: 99999;
	width: 100%;
	font-size: 14px;
	top: 0;

	.badge {
		background: #2d8892;
		color: #fff;
		font-weight: 600;
		font-size: 11px;
		margin-left: 12.5px;
		line-height: 23px;
		padding: 0px 10px;
		border-radius: 11px;
		margin-top: -2px;
		position: relative;
		top: 2px;
		cursor: pointer;

		strong {
			font-size: 12px !important;
			margin-left: 2.5px;
		}
	}

	.redirect {
		background: #2d8892;
		color: #fff;
		font-weight: 600;
		font-size: 10px;
		margin-left: 12.5px;
		padding: 5px 9.5px;
		border-radius: 11px;
		margin-top: -2px;
		position: relative;
		top: 2px;
	}

	.site-picker {
		align-items: center;
		padding: 0px 12.5px 0px 10px;

		.text {
			font-size: 11px;
			font-weight: 600;
			position: relative;
			top: -1px;
			margin-right: -4px;
		}

		.ms-ComboBox {
			border-color: #2c2c2c !important;

			input {
				color: rgb(250, 250, 250) !important;
			}

			&:after {
				border-color: #727272 !important;
			}
		}
	}

	figure.logo {
		height: 100%;
		padding-left: 5px;

		strong {
			font-size: 16px;
			color: #fff;
			padding: 0px;
		}

		img {
			width: 70px;
			margin-top: -4px;
			filter: brightness(0) invert(1);
		}

		i {
			font-size: 1.9em;
			color: #fff;
		}

		a,
		span.link {
			display: flex;
			align-items: center;
			padding: 8px 5px;
			margin-top: 2px;
			cursor: pointer;
		}
	}

	.new-indicator {
		align-items: center;
		display: flex;
		width: 40px;
		justify-content: center;
		color: #cccccc;
		cursor: pointer;
		@include t(all, 0.2s);

		&:hover {
			background: $soft-color;
		}
	}

	.header-tools {
		.settings-item i {
			font-size: 1.25em !important;
		}

		.site-picker {
			align-items: center;
			padding: 0px 12.5px 0px 10px;

			.text {
				font-size: 11px;
				font-weight: 600;
				position: relative;
				top: -1px;
				margin-right: -4px;
			}

			.ms-ComboBox {
				border-color: #2c2c2c !important;
				height: 30px;

				input {
					color: rgb(250, 250, 250) !important;
					height: 30px;
					line-height: 30px;
				}

				&:after {
					border-color: #727272 !important;
				}

				button {
					height: 30px !important;
					line-height: 30px !important;
				}
			}
		}

		.advanced {
			padding: 3px 10px;
			margin-right: -1px;
			display: flex;
			align-items: center;

			.ms-Toggle {
				margin-bottom: 0px;
				margin-top: -2px;

				&.is-checked {
					button {
						background: #33926e !important;
					}
				}

				.ms-Label {
					font-size: 11px !important;
					color: #efefef;
					margin-left: 0px;
					margin-right: 5px;
				}

				.ms-Toggle-stateText {
					font-style: italic;
				}

				.ms-Toggle-background {
					margin-right: 5.5px;
				}
			}
		}

		.notifications {
			// border-left: 1px solid rgba(236, 236, 236, 0.1) !important;
			border-left: 1px solid rgba(0, 0, 0, 0.1);

			i.icon-close {
				font-size: 9px !important;
			}
		}

		.loader {
			position: relative;
			display: block;
			background: transparent;
			z-index: 88;
		}

		.notification-indicator {
			padding-right: 20px !important;
			padding: 0px 15px;
			cursor: pointer;
			line-height: 45px;
			height: 100%;
			display: flex;
			align-items: center;

			i {
				font-size: 21px;
				color: #f0f0f0;
			}
		}

		.quick-view {
			position: absolute;
			min-width: 200px;
			top: 55px;
			background: #4a8c62;
			color: #fff;
			right: calc(100% - 57px);
			padding: 10px 15px;
			pointer-events: none;
			box-shadow: 1px 0px 5px -3px rgba(0, 0, 0, 0.52);
			opacity: 0;
			border-bottom: 4px solid #457b59;
			-webkit-transition: all 0.2s;
			-moz-transition: all 0.2s;
			-o-transition: all 0.2s;
			transition: all 0.2s;

			&.error {
				background: #bf3c3c;
				border-bottom: 4px solid #9c2d2d;

				&:before {
					border-bottom: 9px solid #bf3c3c;
				}
			}

			&.warning {
				background: #fac34d;
				color: #000;
				border-bottom: 4px solid #d4a640;

				&:before {
					border-bottom: 9px solid #fac34d;
				}
			}

			&.default {
				background: #bdbdbd;
				border-bottom: 4px solid #9e9e9e;

				&:before {
					border-bottom: 9px solid #bdbdbd;
				}
			}

			&.active {
				opacity: 1;
			}

			&:before {
				content: "";
				position: absolute;
				width: 0;
				height: 0;
				right: 22px;
				top: -6px;
				border-left: 9px solid transparent;
				border-right: 9px solid transparent;
				border-bottom: 9px solid #4a8c62;
				pointer-events: none;
			}

			.title {
				font-size: 13px;
				font-weight: 500;
				line-height: 18px;

				i {
					font-size: 13px;
					position: relative;
					top: 0.5px;
					padding-right: 6px;
				}
			}

			.desc {
				padding-left: 19px;
				font-size: 11.3px;
				font-weight: 300;
				line-height: 15px;
				letter-spacing: 0.2px;
				margin-top: 3px;
			}

			.hide-quick-view {
				line-height: 18px;
				position: absolute;
				top: 5px;
				right: 5px;

				i.icon-close {
					font-size: 11px !important;
				}
			}
		}

		.badge {
			position: absolute;
			width: 17px !important;
			height: 17px;
			border-radius: 100%;
			background: #e71010;
			color: #fff;
			font-size: 9px;
			right: 9px;
			top: 8px;
			text-align: center;
			line-height: 17px;
			padding: 0px;

			&.not-found {
				background: #33926e;

				i {
					font-size: 8px;
				}
			}

			&.error {
				background: #e71010;

				i {
					font-size: 7px !important;
				}
			}

			&.connecting {
				background: #e09053;

				.ms-Spinner-circle {
					width: 11px;
					height: 11px;
					margin-top: 2.7px;
					border-color: #d5d5d5 #ffffff #ffffff;
				}
			}
		}

		.item {
			cursor: pointer;
			display: flex;
			align-items: center;
			border-left: 1px solid rgba(0, 0, 0, 0.1);
			-webkit-transition: all 0.2s;
			-moz-transition: all 0.2s;
			-o-transition: all 0.2s;
			transition: all 0.2s;
			position: relative;

			&:hover {
				.tooltip {
					-moz-transform: scaleY(1) scaleX(1);
					-webkit-transform: scaleY(1) scaleX(1);
					-o-transform: scaleY(1) scaleX(1);
					transform: scaleY(1) scaleX(1);
				}
			}

			.tooltip {
				left: 0;
				top: calc(100% + 18px);
				transform-origin: top;
				color: #fff;
				font-size: 11px;
				background: #464646;
				-moz-transform: scaleY(0) scaleX(1);
				-webkit-transform: scaleY(0) scaleX(1);
				-o-transform: scaleY(0) scaleX(1);
				transform: scaleY(0) scaleX(1);

				&:before {
					top: -6px;
					transform: rotate(90deg);
					left: 20px;
					border-right-color: #464646;
				}
			}

			&:first-child {
				border-left: none;
			}

			&:hover {
				background: rgba(0, 0, 0, 0.05);
			}

			&:after {
				position: absolute;
				right: 0;
				content: "";
				background: rgba(236, 236, 236, 0.1);
				width: 1px;
				height: 100%;
				top: 0;
			}

			&.version {
				i {
					padding-right: 8.5px;
				}

				strong {
					font-weight: 400;
					font-size: 0.87em;
					letter-spacing: 0.15px;
					font-weight: 500;

					.text {
						font-size: 0.95em;
						font-style: italic;
						font-weight: 600;
					}
				}
			}

			> {

				span,
				a {
					cursor: pointer;
					padding: 0px 15px;
					color: #fff;
					height: 100%;
					display: flex;
					align-items: center;
				}

				span i,
				a i {
					font-size: 1.05em;

					&.fa-play {
						font-size: 0.95em;
					}
				}

				span i.icon-corner-up-left-outline,
				a i.icon-corner-up-left-outline,
				span i.icon-corner-up-right-outline,
				a i.icon-corner-up-right-outline {
					font-size: 1.4em;
				}
			}
		}

		.profile-wrapper {
			position: absolute;
			top: 45px;
			right: 0;
			z-index: 9;
			background: $dark-color;
			width: 100%;
			padding: 0px;
			pointer-events: none;
			transform-origin: top;
			transform: scaleY(0);
			-webkit-transform: scaleY(0);
			-moz-transform: scaleY(0);
			-o-transform: scaleY(0);
			@include t(all, 0.2s);
			border-top: 1px solid rgb(34, 34, 34);

			&.active {
				transform: scaleY(1);
				-webkit-transform: scaleY(1);
				-moz-transform: scaleY(1);
				-o-transform: scaleY(1);
				pointer-events: auto;
			}

			.title {
				color: #fff;
				font-size: 16px;
				margin-bottom: 10px;
			}

			.profile-indicator-list {
				.item {
					border-left: none;

					&:last-child {

						&:after,
						&:before {
							display: none;
						}
					}

					position: relative;

					&:before {
						position: absolute;
						content: "";
						background: #4d4d4d;
						width: 100%;
						height: 1px;
						left: 0;
						bottom: -2px;
						-webkit-transition: all 0.2s;
						-moz-transition: all 0.2s;
						-o-transition: all 0.2s;
						transition: all 0.2s;
					}

					a,
					span {
						color: rgb(240, 240, 240);
						font-size: 11px;
						padding: 9.5px 15px;
						font-weight: 500;
						display: block;
						width: 100%;
						@include t(all, 0.2s);

						&:hover {
							background: #3e3e3e;
						}

						i {
							font-size: 13px;
							padding-right: 7.5px;
							position: relative;
							top: 2px;
						}
					}
				}
			}
		}

		.header-profile {
			padding: 0px 12px;
			color: #fff;
			font-size: 0.9em;
			font-weight: 200;

			.email {
				font-style: italic;
				font-size: 0.97em;
			}

			.name {
				font-weight: 500;
				text-transform: uppercase;
				font-size: 0.97em;
			}

			.profile-icon {
				margin-left: 10px;
				float: right;
				width: 37px;
				height: 37px;
				border-radius: 100%;
				background: #fff;
				color: #4b4b84;
				padding: 0px !important;
				justify-content: center;
				font-weight: 700;
			}
		}

		.language {
			border-left: none;
			padding: 0px 10px 0px 7.5px;

			.language-indicator {
				i {
					color: #e0e0e0;
					position: relative;
					top: 2px;
					font-size: 16px;
				}

				.ms-Dropdown-title {
					background: transparent;
					border: none;
					color: #e0e0e0 !important;

					span {
						font-style: italic;
						padding: 0px;
						padding-right: 7px;
					}
				}
			}
		}
	}
}

.badge-count {
	background: $red-color;
	color: #fff;
	text-align: center;
	font-size: 10px;
	padding: 2.5px 6px;
	border-radius: 20px;
	margin-left: 7.5px;
	margin-top: -2px;
}

/**
  * Loader
  **/

.loader {
	position: fixed;
	top: 0;
	width: 100%;
	height: 100%;
	left: 0;
	background: rgba(43, 42, 42, 0.93) !important;
	z-index: 99999988;
	display: flex;
	justify-content: center;
	opacity: 0;
	pointer-events: none;
	align-items: center;
	-webkit-transition: all 0.2s;
	-moz-transition: all 0.2s;
	-o-transition: all 0.2s;
	transition: all 0.2s;
	opacity: 0;
	pointer-events: none;
	align-items: center;

	&.full {
		left: 0;
		top: 0;
		width: 100% !important;
		height: 100% !important;
	}

	&.verification-loader {
		top: 0px;
		width: 100%;
		height: 100%;
		z-index: 99999999;
		left: 0px;
		background: #101010 !important;

		.ms-Spinner {
			margin-bottom: 0px !important;
			margin-left: 0px !important;
		}
	}

	&.absolute {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;

		.ms-Spinner-label {
			font-size: 11px;
			color: #cecece;
		}
	}

	&.small {
		background: rgba(255, 255, 255, 0.93) !important;

		.ms-Spinner-circle {
			width: 15px;
			height: 15px;
		}

		.ms-Spinner {
			margin-bottom: 0px !important;
		}
	}

	&.accordion {
		background: rgba(255, 255, 255, 0.8) !important;

		.ms-Spinner-circle {
			width: 20px;
			height: 20px;
			margin-top: 21px;
		}
	}

	&.full {
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		z-index: 9999999;
	}

	.largeContainer .ms-Spinner-circle,
	.ms-Spinner-circle.ms-Spinner--xLarge {
		width: 45px;
		height: 45px;
	}

	&.active {
		opacity: 1;
		pointer-events: auto;
	}

	.ms-Spinner-circle {
		width: 30px;
		height: 30px;
	}

	.ms-Spinner-label {
		color: #d8d8d8;
	}

	.ms-Spinner {
		margin-bottom: 22px;
	}
}

.table-loader {
	height: 100%;
	position: absolute;
	left: 0;
	top: -1px;
	width: 100%;
	pointer-events: none;

	.loader {
		left: 0px !important;
		position: absolute !important;
		padding-top: 0px !important;
		align-items: center !important;
		width: 100% !important;
	}
}

.workspace-list {
	padding: 0px 0px 0px 20px;
	white-space: nowrap;
	display: block;
	overflow-x: auto;
	max-width: calc(100% - 700px);

	&::-webkit-scrollbar {
		height: 5.5px;
	}

	&::-webkit-scrollbar-track {
		background: #dddddd;
	}

	&::-webkit-scrollbar-thumb {
		background: #546688;
	}

	.difference-bar {
		width: 0px;
		height: 8px;
		display: block;
		background: rgba(255, 255, 255, 0.88);
		border-radius: 100%;
		-webkit-transition: all 0.25s;
		-moz-transition: all 0.25s;
		-o-transition: all 0.25s;
		transition: all 0.25s;
		opacity: 0;
		pointer-events: none;

		&.active {
			opacity: 1;
			margin-right: 7.5px;
			width: 8px;
		}
	}

	.workspace-item {
		display: inline-flex;
		height: 100%;
		cursor: pointer;
		font-weight: 400;
		align-items: center;
		font-size: 0.87em;
		-webkit-transition: all 0.15s;
		-moz-transition: all 0.15s;
		-o-transition: all 0.15s;
		transition: all 0.15s;
		padding: 0px 10px 0px 10px !important;
		border-right: 1px solid rgba(235, 235, 235, 0.06);
		position: relative;

		.workspace-edit {
			background: #757575;
			width: 20px;
			text-align: center;
			display: flex;
			align-items: center;
			position: absolute;
			left: 7px;
			justify-content: center;
			top: -27px;
			height: 27px;
			@include t(all, 0.25s);

			i {
				color: #fff;
				font-size: 11px;
				background: $light-color;
				height: 24px;
				width: 0px;
				display: flex;
				justify-content: center;
				align-items: center;
				border-radius: 100%;
				padding: 0px !important;
				transform-origin: left;
				@include t(all, 0.25s);
			}
		}

		a {
			display: flex;
			align-items: center;
			height: 100%;
			padding: 0px 0px 0px 15px;
			-webkit-transition: all 0.15s;
			-moz-transition: all 0.15s;
			-o-transition: all 0.15s;
			transition: all 0.15s;
			user-drag: none;
			user-select: none;
			-moz-user-select: none;
			-webkit-user-drag: none;
			-webkit-user-select: none;
			-ms-user-select: none;
		}

		&:hover {
			background: rgba(0, 0, 0, 0.04);
		}

		&.active {
			background: $soft-color;
			padding: 0px 10px 0px 0px;

			&:hover {
				padding: 0px 10px 0px 20px;

				.workspace-edit {
					transition-delay: 0.2s;
					top: 0px;
				}
			}

			.delete-indicator {
				-webkit-transform: scale(1);
				-moz-transform: scale(1);
				-ms-transform: scale(1);
				-o-transform: scale(1);
				transform: scale(1);
				pointer-events: auto;
				margin-left: 10px;
			}
		}

		.text {
			color: #fff;
			letter-spacing: 0.1px;

			&::selection {
				background: transparent;
			}

			.type-text {
				display: block;
				font-size: 0.67em;
				color: #f0f0f0;
				margin-top: -1px;

				&::selection {
					background: transparent;
				}
			}
		}

		.delete-indicator {
			margin-left: 0px;
			width: 18px;
			height: 18px;
			border-radius: 100%;
			-webkit-transform: scale(0);
			-moz-transform: scale(0);
			-ms-transform: scale(0);
			-o-transform: scale(0);
			transform: scale(0);
			transform-origin: center;
			pointer-events: none;
			-webkit-transition: all 0.15s;
			-moz-transition: all 0.15s;
			-o-transition: all 0.15s;
			transition: all 0.15s;

			i {
				font-size: 0.75em;
				text-align: center;
				display: block;
				line-height: 18px;
				color: #ffffff;
			}
		}
	}
}

.hamburger {
	padding: 0px 0px 0px 9.5px;
	display: inline-block;
	cursor: pointer;
	transition-property: opacity, filter;
	transition-duration: 0.15s;
	transition-timing-function: linear;
	font: inherit;
	color: inherit;
	text-transform: none;
	background-color: transparent;
	border: 0;
	margin: 0;
	overflow: visible;
	display: flex;
	align-items: center;
}

.hamburger:hover {
	opacity: 0.7;
}

.hamburger.is-active:hover {
	opacity: 0.7;
}

.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner::before,
.hamburger.is-active .hamburger-inner::after {
	background-color: #f3f3f3;
}

.hamburger-box {
	width: 20px;
	height: 18px;
	display: inline-block;
	position: relative;
	margin-top: 18px;
}

.hamburger-inner {
	display: block;
	top: 50%;
	margin-top: -2px;
}

.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
	width: 14px;
	height: 1px;
	background-color: #f3f3f3;
	position: absolute;
	transition-property: transform;
	transition-duration: 0.15s;
	transition-timing-function: ease;
	display: block;
}

.hamburger-inner::before,
.hamburger-inner::after {
	content: "";
	display: block;
}

.hamburger-inner::before {
	top: -10px;
}

.hamburger-inner::after {
	bottom: -10px;
}

.hamburger--slider .hamburger-inner {
	top: 2px;
}

.hamburger--slider .hamburger-inner::before {
	top: 4px;
	transition-property: transform, opacity;
	transition-timing-function: ease;
	transition-duration: 0.15s;
}

.hamburger--slider .hamburger-inner::after {
	top: 8px;
}

.hamburger--slider.is-active .hamburger-inner {
	transform: translate3d(0, 10px, 0) rotate(45deg);
}

.hamburger--slider.is-active .hamburger-inner::before {
	transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0);
	opacity: 0;
}

.hamburger--slider.is-active .hamburger-inner::after {
	transform: translate3d(0, -20px, 0) rotate(-90deg);
}

.hamburger--slider.is-active .hamburger-box {
	margin-top: 13px;
}

.hamburger--slider.is-active .hamburger-inner::after {
	top: 19px;
}

/*
	   * Slider Reverse
	   */
.hamburger--slider-r .hamburger-inner {
	top: 2px;
}

.hamburger--slider-r .hamburger-inner::before {
	top: 10px;
	transition-property: transform, opacity;
	transition-timing-function: ease;
	transition-duration: 0.15s;
}

.hamburger--slider-r .hamburger-inner::after {
	top: 20px;
}

.hamburger--slider-r.is-active .hamburger-inner {
	transform: translate3d(0, 10px, 0) rotate(-45deg);
}

.hamburger--slider-r.is-active .hamburger-inner::before {
	transform: rotate(45deg) translate3d(5.71429px, -6px, 0);
	opacity: 0;
}

.hamburger--slider-r.is-active .hamburger-inner::after {
	transform: translate3d(0, -20px, 0) rotate(90deg);
}

.ms-DatePicker {
	&.custom-datepicker {
		&.dark {
			.ms-TextField {
				&.is-disabled {
					.ms-TextField-fieldGroup {
						border-color: #656565 !important;
						background: #4e4e4e !important;
					}
				}
			}

			.ms-TextField-fieldGroup {
				height: 38px;
				border-color: rgb(211, 211, 211);
				font-family: $font;
				background: rgba(0, 0, 0, 0.1);
				border: 1px solid rgba(255, 255, 255, 0.13);
				color: rgb(192, 192, 192);

				input,
				textarea {
					color: rgb(206, 206, 206);
					font-size: 13px;

					&::-webkit-input-placeholder {
						font-size: 13px;
					}

					&::-webkit-scrollbar {
						width: 2px;
					}

					&::-webkit-scrollbar-track {
						background: #747474;
					}

					&::-webkit-scrollbar-thumb {
						background: #bbbbbb;
					}

					&::-webkit-input-placeholder {
						color: rgb(155, 155, 155);
					}
				}
			}
		}
	}
}

.ms-Dropdown-container.custom-dropdown {
	.ms-Dropdown {
		&.is-disabled {
			.ms-Dropdown-title {
				border: 1px solid #d3d3d3 !important;
				color: #4a4a4a;
			}
		}
	}

	.ms-Dropdown-label {
		margin-bottom: 1px !important;
	}
}

.ms-Dropdown.custom-dropdown {
	&.is-disabled {
		.ms-Dropdown-title {
			background: #504f4f !important;
			border: 1px solid #858585 !important;
			color: #4a4a4a;
			border: 1px solid #d3d3d3;
		}
	}

	.ms-Dropdown-title {
		background: #414141;
		border: 1px solid #5a5a5a !important;
		height: 38px;
		line-height: 38px;
		color: #d4d4d4 !important;
		font-size: 13px;

		span {
			font-size: 13px;
		}
	}

	.ms-Dropdown-caretDownWrapper {
		i {
			font-size: 13px;
			color: #808080;
		}
	}
}

.ms-TextField-errorMessage {
	font-size: 11px;
}

.tox-tinymce.tox-tinymce--disabled {
	.tox-editor-header {
		display: none;
	}

	iframe {
		background: #f0f0f0;
	}
}

.ms-TextField.custom-textfield {
	font-size: 11.5px;
	font-family: $font;

	&.available {
		.ms-TextField-description {
			color: #ffffff;
			margin-top: 3px;
			background: #469175;
			padding: 2.5px 7.5px;
			display: inline-block;
		}
	}

	&.error-input {
		.ms-TextField-description {
			color: #ffffff;
			margin-top: 3px;
			background: #914c46;
			padding: 2.5px 7.5px;
			display: inline-block;
		}
	}

	&.is-disabled .ms-TextField-fieldGroup {
		background: #f0f0f0;

		input {
			color: #4a4a4a;
		}

		textarea {
			color: #4a4a4a;
		}
	}

	&.is-active .ms-TextField-fieldGroup {
		border-color: #5590da;
	}

	&.dark {
		.ms-Label {
			font-size: 10px;
			color: #e8e8e8;
			font-weight: 400;
			padding: 0px 0px 3px 1px;
		}

		&.is-active .ms-TextField-fieldGroup {
			background: rgba(37, 37, 37, 0.1);
		}

		.ms-TextField-fieldGroup {
			background: rgba(0, 0, 0, 0.1);
			border: 1px solid rgba(255, 255, 255, 0.13);
			color: rgb(192, 192, 192);

			input,
			textarea {
				color: rgb(206, 206, 206);

				&::-webkit-scrollbar {
					width: 2px;
				}

				&::-webkit-scrollbar-track {
					background: #747474;
				}

				&::-webkit-scrollbar-thumb {
					background: #bbbbbb;
				}

				&::-webkit-input-placeholder {
					color: rgb(155, 155, 155);
				}
			}
		}

		&.is-disabled {
			.ms-TextField-fieldGroup {
				background: rgba(109, 107, 107, 0.48);
				border-color: #717171 !important;
				color: #4a4a4a;
			}

			textarea {
				color: #4a4a4a;
			}
		}
	}

	.ms-TextField-fieldGroup {
		height: 38px;
		border-color: rgb(211, 211, 211);
		font-family: $font;
	}

	.ms-TextField-description {
		font-size: 10px;
		color: #cccccc;
		display: block;
		margin-top: 1px;
		font-weight: 500;
		margin-left: 2px;
	}

	.ms-Label {
		margin-bottom: 1px !important;
	}

	input,
	textarea {
		font-size: 13px;
		font-weight: 500;
		outline: none;
		background: transparent;
		font-family: $font  !important;

		&::-webkit-input-placeholder {
			font-size: 13px;
			font-weight: 400;
		}
	}
}


.language-list {
	margin-top: 15px;
	padding: 10px;
	background: #fafafa;
	border: 1px solid #d3d3d3;

	.language-picker {
		width: calc(100% - 30px);
		max-width: calc(100% - 30px);
	}

	.remove {
		cursor: pointer;
		align-self: flex-end;
		padding: 8.5px;
		font-size: 12px;

		&.passive {
			opacity: 0;
			pointer-events: none;
		}
	}

	.ms-Button {
		margin-top: 10px;
	}
}

.custom-currency {
	font-size: 13px;
	font-weight: 500;
	width: 100%;
	outline: none;
	border: none;
	border: 1px solid rgba(255, 255, 255, 0.13);
	height: 38px;
	border-color: #d3d3d3;
	padding: 0px 7.5px;
	color: rgb(50, 49, 48);
	background: #fff;
	font-family: $font;

	&::-webkit-input-placeholder {
		font-size: 0.97em;
		font-weight: 400;
	}

	&:disabled {
		border: 1px solid #d3d3d3 !important;
		background-color: rgb(243, 242, 241) !important;
		color: rgb(161, 159, 157) !important;
	}
}

.ms-SearchBox.custom-searchbox {
	font-size: 11px;
	font-weight: 500;
	border: none;
	border-radius: 0px;
	height: 25px;

	input {
		font-weight: 400;
		font-family: $font;
		border-radius: 0px;
	}
}

.ms-Dropdown-container.custom-dropdown {
	font-size: 12px;

	&.dark {
		.ms-Dropdown {
			border: none;
		}

		.ms-Dropdown-title {
			background: rgba(0, 0, 0, 0.1);
			color: silver;
			border-color: rgb(211, 211, 211);
		}

		.ms-Dropdown-caretDownWrapper i {
			color: #fff;
		}
	}

	.ms-Dropdown {
		font-size: 0.9em;
		letter-spacing: 0.2px;

		&.is-open {
			.ms-Dropdown-title {
				border-color: $blue-color  !important;
			}
		}

		.ms-Dropdown-title {
			border-color: rgb(211, 211, 211);
			top: 0px;
			left: 0px;
		}

		.ms-Dropdown-title,
		.ms-Dropdown-caretDownWrapper {
			height: 38px;
			font-weight: 500;
			letter-spacing: 0px;
			font-size: 13px;
			line-height: 38px;
			border-width: 1px;
		}

		.ms-Dropdown-title i,
		.ms-Dropdown-caretDownWrapper i {
			font-size: 13px;
			line-height: 27px;
			margin-right: 4px;
			color: rgb(75, 75, 75);
		}
	}
}

.custom-dropdown-callout {
	font-size: 12px;

	.ms-Dropdown-item {
		font-size: 12px;
		font-weight: 500;
		border-bottom: 1px solid #e6e6e6;
		min-height: 38px;
	}

	.ms-ContextualMenu-list {
		max-height: 850px;

		&::-webkit-scrollbar {
			width: 4px;
		}

		&::-webkit-scrollbar-track {
			background: #f0f0f0;
		}

		&::-webkit-scrollbar-thumb {
			background: #b1b1b1;
		}

	}

	.ms-ContextualMenu-link {
		border-bottom: 1px solid #ececec;
	}

	.ms-Dropdown-optionText,
	.ms-ContextualMenu-itemText {
		font-size: 12px !important;
		font-weight: 500 !important;
	}

	.ms-Checkbox-checkbox {
		width: 16px;
		height: 16px;
		border-color: #848484;
		margin-right: 6px;
	}
}

.ms-ComboBox-callout.custom-combobox-callout {
	font-size: 12px;
	max-width: 400px !important;

	.ms-Callout-main {
		max-height: 350px !important;
	}

	.ms-ComboBox-optionText {
		font-size: 12px !important;
	}

	.ms-Checkbox-checkmark {
		font-size: 12px !important;
	}

	.ms-ComboBox-option {
		font-size: 1em;
		font-weight: 500;
		border-bottom: 1px solid #e6e6e6;
		min-height: 38px;
	}

	.ms-Dropdown-optionText {
		font-size: 12px !important;
		font-weight: 500 !important;
	}

	.ms-Checkbox-checkbox {
		width: 16px;
		height: 16px;
		border-color: #848484;
		margin-right: 6px;
	}
}

.ms-Slider {
	&.custom-slider {
		&.dark {
			.ms-Label {
				color: #ececec;
			}

			.ms-Slider-inactive {
				background: #7b7b7b;
			}
		}
	}
}

.ms-Checkbox.custom-checkbox {
	&.dark {
		.ms-Checkbox-text {
			font-size: 11.5px !important;
			font-weight: 500;
			color: #e8e8e8;
		}

		.ms-Checkbox-label:hover {
			.ms-Checkbox-checkbox {
				border-color: #585858;
			}
		}
	}
}

.ms-ComboBox-container.custom-combobox {
	font-size: 11.5px;

	&.dark {
		.ms-Label {
			font-size: 10px;
			color: #e8e8e8;
			font-weight: 400;
			padding: 0px 0px 3px 1px;
		}

		.ms-ComboBox {
			background: rgba(0, 0, 0, 0.1);
			color: silver;
			border-color: rgb(211, 211, 211);

			&.is-disabled {
				border-color: #717171 !important;
				background: #5c5b5b !important;

				input {
					color: #4a4a4a !important;
				}
			}

			input {
				background: transparent;
				color: rgb(206, 206, 206);
				font-size: 11px;

				&::-webkit-input-placeholder {
					color: rgb(155, 155, 155);
					font-size: 11px;
				}
			}

			.ms-ComboBox-CaretDown-button {
				color: rgb(155, 155, 155);
				background: transparent !important;
			}
		}
	}


	.ms-Button-flexContainer i {
		font-size: 0.75em;
	}

	.ms-ComboBox {
		font-size: 13px;
		font-weight: 300;
		letter-spacing: 0.2px;
		height: 38px;
		line-height: 38px;
		border-color: rgb(211, 211, 211);

		&:after {
			border-color: rgb(211, 211, 211);
		}

		&.is-disabled {
			input {
				color: #4a4a4a !important;
			}
		}

		.ms-ComboBox-CaretDown-button {
			height: 38px;
			line-height: 38px;
			top: 0;
			right: 0;
		}

		input {
			height: 38px;
			line-height: 38px;
			font-size: 13px;
			font-weight: 500;
			margin-top: -2px;
			display: block;
			font-family: $font;

			&::-webkit-input-placeholder {
				font-weight: 500;
				font-size: 13px;
			}
		}
	}
}

.button-wrapper {
	position: relative;
}

button {
	&.type-plus {
		max-width: 25px;
		padding: 0px !important;
		margin-right: 7.5px;
		border: none;
		display: flex;
		background: $light-color;
		height: 23px;
		justify-content: center;
		color: #fff;
		font-size: 9px;
		cursor: pointer;
	}
}

.ms-ComboBox-callout.custom-combobox-callout {
	font-size: 11.5px;
	width: 100%;
	max-width: 150px;

	.ms-ComboBox-option {
		font-size: 1em;
		font-weight: 500;
	}
}

.dialog-selector {
	margin-left: -25px;
	background: #f6f6f6;
	width: calc(100% + 49px);
	padding: 10px 22px;

	&>.col {
		padding: 0px 5px;
	}
}

.chartjs-render-monitor {
	animation: inherit !important;
}

.show-password {
	position: absolute;
	right: 25px;
	bottom: 7.5px;
	font-size: 13px;
	cursor: pointer;
}

.ms-Toggle.custom-toggle {
	margin-bottom: 0px;
	margin-top: 5px;
	margin-left: 5px;

	&.custom-label {
		.ms-Toggle-label {
			margin: 5px 0px 0px 0px !important;
		}
	}

	&.is-disabled {
		&.is-checked button {
			background: #cfcfcf;
		}
	}

	.ms-Toggle-innerContainer {
		margin-left: 5px;
		margin-top: 5px;
	}

	&.align-right {
		.ms-Toggle-innerContainer {
			margin-left: auto;
		}
	}

	&.is-checked button {
		background: $blue-color;
	}

	&>.ms-Label {
		margin-top: 4px;
	}

	.ms-Label {
		margin-left: 8px;
		font-size: 0.8em;
		font-weight: 400;
		cursor: pointer;
		color: rgb(46, 46, 46);
		font-weight: 300;
		font-family: $font;

		&::selection {
			background: transparent;
		}
	}

	.ms-Toggle-stateText {
		margin: 0px 0px 0px 10px;
		position: relative;
		top: -1px;
	}

	button {
		width: 30px;
		height: 15px;
		font-size: 14px;
		padding-left: 0.1em;
		padding-right: 0.11em;

		.ms-Toggle-thumb {
			width: 9px;
			height: 9px;
			border-width: 4px !important;
		}
	}
}

.ms-Label {
	font-size: 13px !important;
	font-weight: 500 !important;
	margin-bottom: 3px !important;
}

.menu-designer {
	margin-top: 15px;
}

.menu-tree-designer {
	background: #fff;
	padding: 15px;
	width: calc(100% + 15px);
	border: 1px solid #d7d5d5;
	margin-top: -16px;

	.menu-info {
		font-weight: 500;
		font-size: 11px;
		background: #f1f9ff;
		padding: 10px;
		align-items: center;
		border: 1px solid #e3ecf3;

		i {
			padding-right: 3.5px;
		}
	}

	.rst__tree {
		margin-top: 10px;
		margin-left: -20px;

		.rst__lineBlock,
		.rst__absoluteLineBlock {
			display: none;
		}

		.rst__lineBlock {
			&:before {
				display: none;
			}

			&:after {
				display: none;
			}
		}

		.rst__expandButton {
			display: none;
		}

		.rst__toolbarButton {
			button {
				background: transparent;
				border: none;
				cursor: pointer;
			}
		}

		.rst__row {
			border: 1px solid #dedede;
			align-items: center;
		}

		.rst__rowWrapper {
			padding: 0px 10px 5px 0;
		}

		.rst__moveHandle {
			background: #f3f3f3;
			border: 0;
			box-shadow: 0 0 0 0;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 14px;
			width: 35px;
			border-right: 1px solid #dedede;

			&:after {
				position: absolute;
				content: "\f25a";
				font-family: "Font Awesome 5 Free" !important;
			}
		}

		.rst__rowContents {
			box-shadow: 0px 0px 0px;
			min-width: 350px;
			border: none;
			background: #fffefe;
		}

		.rst__rowTitle {
			font-size: 11.5px;
			font-weight: 600;
		}

		.rst__lineChildren {
			display: none;
		}

		.rst__collapseButton {
			display: none;
		}
	}
}

.form-groups {
	margin: 0px -15px;
	position: relative;

	&.menu-item-group {
		.form-accordion {
			width: 100%;
			margin-bottom: 0px;
			margin-bottom: -1px;

			.title {
				font-size: 13px;

				&.passive {
					background: #f5f5f5 !important;
				}
			}
		}

		.accordion-content {
			border: 1px solid #d7d5d5;
			border-top: none;
			padding: 10px;
			position: relative;

			&:after {
				clear: both;
				content: "";
				display: block;
			}
		}

		.menu-not-found {
			font-size: 11px;
			font-weight: 400;
			align-items: center;

			i {
				padding-right: 5px;
			}
		}

		.menu-items-footer {
			align-items: center;
			border-top: 1px dotted #c0c0c0;
			margin-top: 5px;

			.select-all {
				color: #268cda;
				font-weight: 500;
				border-bottom: 1px solid #0078d4;
				font-size: 11px;
				cursor: pointer;
				margin-top: 4px;
			}
		}

		.menu-items {
			max-height: 200px;
			overflow-y: auto;

			&::-webkit-scrollbar {
				width: 4px;
			}

			&::-webkit-scrollbar-track {
				background: #f0f0f0;
			}

			&::-webkit-scrollbar-thumb {
				background: #b1b1b1;
			}

			.menu-item {
				font-size: 11px;

				&:first-child {
					.ms-Checkbox-label {
						padding-top: 0px;
					}
				}

				.ms-Checkbox-label {
					padding: 2.5px 0px;
					width: 100%;
				}

				.ms-Checkbox-text {
					font-size: 11px;
					font-weight: 500;
				}

				.ms-Checkbox-checkbox {
					width: 15px;
					height: 15px;
					margin-top: 3px;
					margin-right: 2px;
					font-size: 10px;
				}

				.ms-Checkbox {
					width: 100%;
				}
			}
		}
	}

	.title-form {
		padding: 8px 0px 10px 0px;
		background: #ffffff;
		border: 1px solid #d7d5d5;
	}

	.form-group {
		padding: 15px;

		&>.title {
			color: #363636;
			font-weight: 600;
			font-size: 13.5px;

			button {
				margin-left: auto;
				font-size: 10.1px;
				padding: 5px 10px;
				background: #0078d4;
				border: 0;
				color: #fff;
				cursor: pointer;
				font-weight: 500;
				margin-bottom: -3px;

				&.is-disabled {
					background: #ebebeb;
					color: rgb(157, 157, 157);
				}
			}
		}

		&>.wizard-title {
			background: #f0faff;
			border: 1px solid #ececec;
			padding: 15px;
			font-size: 11.5px;
			align-items: center;
			color: #232323;

			button {
				height: 26px;
			}
		}

		.list-td {
			color: #333 !important;
		}

		.form-wrapper {
			padding: 10px 15px;
			margin-top: 0px;
			background: #ffffff;
			border: 1px solid #dfdfdf;
			margin-top: -1px;
			min-height: auto;

			&>.meta-dropdown-values {
				margin-top: 0;

				.meta-dropdown-value {
					margin-bottom: 5px;
					padding-bottom: 7.5px;

					.ms-Dropdown-label {
						margin-bottom: 1px !important;
					}
				}
			}

			&.compact {
				padding: 0px;
				min-height: auto;
				border: none;
			}

			.table-list {
				margin-top: 0px;
			}

			.ms-Toggle-thumb {
				width: 5px !important;
				height: 5px !important;
				border-width: 5px !important;
			}

			.information-list {
				.item {
					padding: 5px;

					&>.title {
						border-bottom: 1px dotted #dadada;
						margin-bottom: 10px;
						padding-bottom: 5px;
						font-size: 12px;
						font-weight: 500;
					}

					&>.text {
						font-size: 12px;
						line-height: 17.5px;
						color: #333;
					}
				}
			}

			&>.item {
				margin-bottom: 5px;
				position: relative;

				.ms-Toggle {
					margin: 0px !important;

					.ms-Toggle-label {
						padding: 0 !important;
						margin: 5px 0 6px 0 !important;
					}

					.ms-Toggle-innerContainer {
						margin: 0px !important;
						margin-top: 10px;
					}

				}

				.url-type {
					position: absolute;
					right: 0;
					z-index: 9;
					top: 3.5px;

					span {
						font-weight: 600;
						font-size: 10.1px;
						background: #f6f6f6;
						margin-left: -1px;
						color: #333;
						padding: 2px 10px;
						cursor: pointer;
						border: 1px solid #c6c6c6;

						&.active {
							background: #0078d4;
							color: #f6f6f6;
							border-color: #0078d4;
						}

						&.passive {
							background: #ececec;
							color: #ccc !important;
							border-color: #ececec !important;
						}
					}
				}

				&.upgrade-item {
					.ms-Button {
						height: 38px;
						margin-top: 7.5px;
						font-size: 13px;
						margin-top: 30px;
						max-width: 100px;
						padding-right: 10px !important;

						i {
							font-size: 13px;
						}
					}
				}

				.ms-Label {
					color: rgb(50, 49, 48);
				}

				&>div {
					width: 100%;
				}
			}
		}
	}
}

.form-buttons {
	position: fixed;
	bottom: 0;
	background: #343434;
	width: calc(100% - 40px);
	left: 40px;
	z-index: 999;
	padding: 10px;

	.status {
		background: #ececec;
		padding: 4.5px 15px;
		font-size: 10.5px;
		margin-right: 10px;
		color: #151515;
		font-style: italic;
		border: 1px solid #d6d6d6;
	}

	&.absolute {
		position: absolute;
		width: auto;
		display: block;
		background: transparent;
		justify-content: flex-end;
		padding: 7px 0 8px 5px;
		right: 20px;
		top: 30px;
		height: auto;
		z-index: 9;
		bottom: inherit;
		left: inherit;
		display: flex;
		align-items: center;
	}

	.ms-Button {
		font-size: 11px;
		margin-right: 10px;

		i {
			font-size: 11px;

			&[data-icon-name="CheckMark"] {
				font-size: 16px;
			}
		}
	}
}

.ms-MessageBar {
	margin-top: 15px;

	&.not-equal {
		background: #ffecec;

		.ms-MessageBar-text {
			font-weight: 500;
		}
	}

	&.rejected {
		background: #ffecec;
		border-left: 4px solid #d06b6b;
	}

	.ms-MessageBar-text {
		font-size: 13px;
	}

	.title {
		font-weight: 700;
		font-size: 13.5px;
		color: rgb(33, 33, 33);
	}

	button {
		padding: 0px 10px;
	}

	.custom-button {
		&.green {
			height: 24px;
			margin-left: 10px;
			background: #33926e;
			border: 1px solid #308866;

			&:hover {
				background: #308866;
			}
		}
	}
}

.modal-form {
	.ms-Toggle {
		margin: 0px !important;

		.ms-Toggle-label {
			margin: 5px 0px 0px 0px !important;
		}
	}
}

.ms-Modal {
	&.workflow-modal {
		.react-monaco-editor-container {
			.overflow-guard {
				border: 1px solid #a1a1a1;
				padding: 10px 0px;
			}
		}
	}

	.translator-bar {
		margin-bottom: 7.5px;
		background: #ffffff;
		padding: 0px 11px;

		&>.row {
			margin-left: auto;
		}
	}

	&.full-modal {
		.ms-Modal-scrollableContent {
			height: 100%;
			overflow: hidden;
		}

		.modal-indicators {
			margin-left: 0;
			margin-top: 0 !important;
			/* width: calc(100% + 30px); */
			padding: 2px 8px !important;
			/* margin-left: -15px; */
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			padding: 5px 10px 10px 10px !important;

			button {
				margin-top: 4px;
				font-size: 11px;
				font-weight: 600;
			}
		}

		.ms-Dialog-main {
			width: 100%;
			height: 100%;
		}

		.left-panel {
			height: calc(100vh - 141px);
			margin-top: -1px;
		}

		.design-panel {
			padding: 0px;
			margin-top: -1px;
			margin-right: 0px !important;

			.design-container {
				height: 100%;
			}
		}

		.panel-inner {
			margin-top: -1px;
			background: #2f2f2f;
			width: 259px;
			position: relative;

			.fa-Accordion {
				max-height: calc(100vh - 171px);
				overflow-y: auto;

				&::-webkit-scrollbar {
					width: 5.5px;
				}

				&::-webkit-scrollbar-track {
					background: #888888;
				}

				&::-webkit-scrollbar-thumb {
					background: #444444;
				}
			}
		}
	}

	&.custom {
		&.small .ms-Dialog-main {
			width: 480px;
			height: auto;
			max-height: 100%;
		}

		&.medium .ms-Dialog-main {
			width: 850px;
			max-width: 95%;
			height: auto;
			max-height: 100%;
		}

		&.large .ms-Dialog-main {
			width: 1200px;
			max-width: 95%;
			height: auto;
			max-height: 100%;
		}

		&.auto .ms-Dialog-main {
			width: auto;
			height: auto;
			max-height: 100%;
		}

		.ms-Dialog-main {
			width: calc(100% - 40px);
			height: calc(100% - 40px);
			padding: 0px;

			.modal-header {
				padding: 13.5px 25px;
				background: $soft-color;
				align-items: center;
				// border-bottom: 1px dotted #ccc;
				position: sticky;
				top: 0;
				z-index: 11;

				.title {
					font-weight: 500;
					font-size: 15px;
					color: #e6e6e6;

					.sub-text {
						font-size: 11px;
						color: #e3e3e3;
						font-weight: 300;
						width: 500px;
					}
				}

				.modal-header-input {
					margin-top: 3px;
					border-left: 3px solid #ccc;
					padding-left: 5px;

					strong {
						width: 55px;
						font-size: 10px;
						margin-right: 10px;
					}
				}

				.close {
					margin-left: auto;
					color: #e6e6e6;
					font-size: 13px;
					border-radius: 100%;
					text-align: center;
					cursor: pointer;
					-webkit-transition: all 0.2s;
					-moz-transition: all 0.2s;
					-o-transition: all 0.2s;
					transition: all 0.2s;
				}
			}

			.modal-content {
				padding: 15px 15px 0px 15px;
				max-height: calc(100vh - 160px);
				overflow-y: auto;
				font-size: 13px;
				// & > div {
				//   padding: 20px;
				// }

				.property-title {
					font-size: 11.5px;
					background: #f4f4f4;
					padding: 10px 12.5px;
					margin-bottom: -10px;
				}

				.absolute.buttons {
					position: absolute;
					right: 0;
					z-index: 9;
					display: flex;
					justify-content: flex-end;
					margin-top: -7px;
				}

				.full {
					&>div {
						width: 100%;
					}
				}

				.ms-DetailsHeader {
					padding-top: 0px !important;
				}

				&::-webkit-scrollbar {
					width: 5px;
				}

				&::-webkit-scrollbar-track {
					background: #e7e7e7;
				}

				&::-webkit-scrollbar-thumb {
					background: #bbbbbb;
				}

				.modal-group {
					>.text {
						background: #ffffff;
						color: #fff;
						padding: 10px 2.5px;
						margin-bottom: 10px;
						font-weight: 600;
						border-bottom: 1px solid $blue-color;
						color: $blue-color;
						font-size: 1em;
						text-transform: uppercase;
					}

					&:first-child>.text {
						color: #525252;
						border-bottom: 1px solid #525252;
					}
				}
			}
		}

		.ms-Label {
			font-size: 11px;
			font-weight: 600;
		}
	}

	.modal-indicators {
		margin-top: 17.5px;
		z-index: 9999;
		margin-bottom: 0;
		border-top: 1px solid #d8d8d8;
		position: sticky;
		bottom: 0;
		background: #f7f7f7;
		justify-content: flex-end;
		display: flex;
		padding: 2px 15px 13px !important;
		width: calc(100% + 30px);
		margin-left: -15px;
	}

	.change-style {
		.info {
			background: #e4f0f9;
			padding: 10px;

			span {
				max-width: calc(100% - 30px);
				flex: 0 0 calc(100% - 30px);
				font-size: 11px;
				font-weight: 500;
			}

			i {
				padding-right: 5px;
				margin-top: 2px;
			}
		}
	}

	&.warning {
		.ms-Dialog-main {
			width: 380px;
			min-height: auto;
			height: auto;
			max-height: 100%;
			box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.22);

			.modal-header {
				padding: 10px 15px;
				background: #f5f5f5;
				align-items: center;
				// border-bottom: 1px dotted #ccc;
				position: sticky;
				top: 0;
				z-index: 11;

				>i {
					font-size: 1.25em;
					padding-right: 7.5px;
					position: relative;
					top: 0.5px;
				}

				.title {
					font-weight: 500;
					font-size: 16px;

					.sub-text {
						font-size: 0.8em;
						color: gray;
						font-weight: 300;
						width: 500px;
					}
				}

				.close {
					margin-left: auto;
					width: 30px;
					height: 30px;
					border-radius: 100%;
					background: rgba(0, 0, 0, 0.2);
					text-align: center;
					line-height: 30px;
					font-size: 10px;
					cursor: pointer;
					-webkit-transition: all 0.2s;
					-moz-transition: all 0.2s;
					-o-transition: all 0.2s;
					transition: all 0.2s;
					color: #fff;

					&:hover {
						background: rgba(0, 0, 0, 0.1);
					}
				}
			}

			.description {
				padding: 20px 15px 10px 15px;
				color: #1a1a1a;
				letter-spacing: 0.25px;
				font-size: 0.92em;
			}

			.modal-indicators {
				padding: 10px !important;
				text-align: right;
				margin-top: 25px !important;

				&.no-gutter {
					button {
						margin-left: 5px !important;
					}
				}

				button {
					font-size: 13px !important;
					font-weight: 600;
					margin: 0px !important;
				}
			}
		}

		&.error .ms-Dialog-main {
			border-bottom: 2px solid #b34848;

			.modal-header {
				background: #b34848;

				>i,
				.title {
					color: #ffffff;
				}
			}
		}

		&.success .ms-Dialog-main {
			border-bottom: 2px solid #409777;

			.modal-header {
				background: #409777;

				>i,
				.title {
					color: #ffffff;
				}
			}
		}
	}
}


.ms-Dialog-content {
	.ms-MessageBar {
		margin-top: 10px;
		margin-bottom: 6px;

		i {
			font-size: 15px;
		}

		.ms-MessageBar-innerText {
			font-family: $font;
			font-size: 12px;
		}
	}
}

.general-modal {
	.ms-Pivot {
		margin-bottom: -0.5px;
		margin: 0px -5px;
		margin-top: -11px;
		margin-bottom: 5px;

		.ms-Button {
			font-size: 13px;
			font-weight: 500;
			opacity: 0.7;

			&.is-selected {
				opacity: 1;
			}

			&.is-disabled {
				opacity: 0.4;
			}
		}
	}

	.title-container {
		background-color: $soft-color;
		border-bottom: 1px solid #ccc;
		display: flex;
		flex-direction: row;
		padding: 15px;

		.title-left {
			flex: 1;
			flex-direction: row;
		}

		.title-right {
			width: 20px;
			height: 20px;
			margin-top: 4px;
			text-align: center;
			cursor: pointer;
		}

		.title-left {
			.title {
				font-size: 15px;
				font-weight: bold;
				color: #e6e6e6;
				margin-top: 1px;
			}

			.description {
				font-size: 10.5px;
				color: #c3c3c3;
			}
		}

		.title-right i {
			font-size: 13px;
			color: #e1e1e1;
		}
	}
}

.parent-object-field {
	.content {
		padding: 20px;
		background: #f9f9f9;
		border: 1px solid #ececec;
		margin-top: 1px;
	}

	&>.property-item {
		.col {
			text-align: right;
			font-size: 15px;
			margin-right: 6px;
		}
	}
}

.date-time-field {
	.DayPickerInput-Overlay {
		position: fixed !important;
		left: inherit !important;
	}

	.col {
		padding: 0px 5px;
	}

	.custom-combobox {
		margin-top: 3px;
	}
}

.image-string-field {
	width: 38px;
	height: 38px;
	object-fit: cover;
}

.thumb-title {
	padding: 5px 12.5px 12.5px 12.5px;
	background: #fdfdfd;
	border: 1px solid #eeeeee;
}

.filter-buttons {
	align-self: flex-end;
	padding: 15px 15px 0px 15px;
}

.meta-form {
	.ms-Toggle {
		margin: 0px;

		.ms-Toggle-label {
			margin: 0;
			padding-bottom: 0px;
		}
	}

	.image-field {
		width: 100%;

		button {
			margin-top: 0px !important;
			height: 38px !important;
			margin-left: 5px !important;
			font-size: 12px !important;
		}
	}

	&.filter-form {
		margin: 0px -10px 5px -10px;

		.col {
			padding: 0px 11px;
		}

		.stock-picker-container {
			.ms-Label {
				margin-bottom: 0.5px !important;
			}
		}

		.remove {
			padding: 0 10px;
			align-self: flex-end;
			position: relative;
			top: -10px;
			cursor: pointer;
		}

		.ms-Toggle {
			.ms-Toggle-label {
				font-weight: 400 !important;
				color: #5c5c5b;
				margin-top: 2.5px !important;
			}
		}
	}
}

.image-field {
	button {
		margin-top: 0 !important;
		height: 38px !important;
		margin-left: 5px !important;
		font-size: 12px !important;
	}

	.remove {
		cursor: pointer;
		padding: 7.5px 10px 7.5px 10px;
		background: #ececec;
		text-align: center;
		margin-left: 7.5px;
		pointer-events: none;
		opacity: 0;

		&.active {
			pointer-events: auto;
			opacity: 1;
		}

		i {
			font-size: 13px;
		}
	}
}

.text-field {
	button {
		margin-top: 0 !important;
		height: 30px !important;
		margin-left: 5px !important;
		font-size: 10.5px !important;
	}

	.remove {
		cursor: pointer;
		padding: 5px 10px 5px 10px;
		background: #ececec;
		text-align: center;
		margin-left: 7.5px;
		pointer-events: none;
		pointer-events: auto;
		opacity: 1;

		i {
			font-size: 11px;
		}
	}

	margin-bottom: 5px;
}

.property-item {
	&.image-field {
		width: 100%;

		&>.col {
			align-items: center;
			display: flex;

			.ms-TextField {
				margin-right: 8px;
				-ms-flex-preferred-size: 0;
				flex-basis: 0;
				-webkit-box-flex: 1;
				-ms-flex-positive: 1;
				flex-grow: 1;
				max-width: 100%;
			}

			button {
				margin-top: 0px !important;
				height: 30px !important;
				margin-right: 5px;
			}
		}
	}

	&.video-field,
	&.icon-field {
		width: 100%;

		&>.col {
			align-items: center;
			display: flex;

			.ms-TextField {
				margin-right: 8px;
				-ms-flex-preferred-size: 0;
				flex-basis: 0;
				-webkit-box-flex: 1;
				-ms-flex-positive: 1;
				flex-grow: 1;
				max-width: 100%;
			}

			button {
				margin-top: 0px !important;
				height: 30px !important;
				margin-right: 5px;
			}
		}
	}
}

.general-informations {
	width: calc(100% + 15px);
	background: #f6f6f6;
	border: 1px solid #dfdfdf;

	.general-information-item {
		border-bottom: 1px solid #dfdfdf;
		padding: 12.5px 15px;
		display: flex;
		align-items: center;

		.title {
			font-weight: 500;
			color: rgb(61, 61, 61);
			align-items: center;
			font-size: 11px;
			min-width: 175px;
			display: flex;

			i {
				margin-right: 5px;
				font-size: 11px;

				&.icon-info {
					font-size: 11px;
				}
			}
		}

		.text {
			font-size: 11px;
			color: #242424;
			font-weight: 400;

			&.server {
				cursor: pointer;
				text-decoration: underline;
			}

			&.count {
				font-weight: 600;
				font-size: 11px;
			}

			&.status {
				cursor: pointer;
				color: #616161;
				background: #eaeaea;
				display: inline-block;
				padding: 5px 7.5px;
				margin-top: -1.5px;
				font-size: 10px;

				&.success {
					background-color: #33926e;
				}

				&.error {
					background-color: #33926e;
				}

				i {
					padding: 0px 5px;
				}
			}

			&.user {
				cursor: pointer;
				color: #fbfbfb;
				background: #0078d4;
				display: inline-block;
				padding: 2px 5px;
				font-size: 10px;
			}
		}

		&:last-child {
			border-bottom: none;
		}

		&:nth-last-child(2) {
			border-bottom: none;
		}
	}
}

.page-buttons {
	margin-bottom: 15px;
	padding-bottom: 13px;
	border-bottom: 1px solid #dfdfdf;
	width: calc(100% + 15px);

	.ms-Button {
		margin-right: 5px;

		&.is-disabled {
			background: #eeeeee;
			color: #bbb;
			border: none;
		}
	}
}

.workspace-list {
	margin: 0px -5px;

	.workspace-item {
		padding: 0px 10px 0px 10px !important;

		.workspace-item-wrapper {
			background: #f6f6f6;
			color: #232323;
			border: 1px solid #dddddd;
			font-size: 11px;
			padding: 12.5px;
			font-weight: 500;
			cursor: pointer;
			align-items: center;
			-webkit-transition: all 0.2s;
			-moz-transition: all 0.2s;
			-o-transition: all 0.2s;
			transition: all 0.2s;

			&:hover {
				background: #ebebeb;
			}

			i {
				margin-left: auto;
				font-size: 10px;
				margin-right: 5px;
			}
		}
	}
}

.badge.connecting {
	position: absolute;
	right: 55px;
	top: -8.5px;

	.loader {
		position: absolute;

		.ms-Spinner-circle {
			width: 15px;
			height: 15px;
		}
	}
}

.ms-Overlay {
	background: rgba(0, 0, 0, 0.8);
}

/** Preferences **/
.preferences-panel {
	.translator-bar {
		&>i {
			display: none;
		}

		&>.title {
			display: none;
		}

		&>.row {
			margin-left: 0px;
		}

		.language-picker-item {
			span {
				&.code {
					display: block !important;
				}
			}
		}

		padding: 0px;
	}

	.custom-compound {
		max-width: 100% !important;
		width: 100%;
		margin-top: 10px;
	}

	.ms-Panel-main {
		width: 640px;
	}

	.ms-Dialog-actions {
		padding: 0px 20px 30px 0px;
	}

	.ms-Panel-headerText {
		font-size: 18px;
		margin-top: 1.5px;
		font-weight: 600;
	}

	.ms-Panel-commands {
		margin-top: 30px;
	}

	.ms-Panel-footer {
		background: #f1f1f1;
		border-top: 1px solid #dddddd;

		.ms-Button {
			font-size: 11px;
		}
	}

	.sub-text {
		font-size: 12.5px;
		margin-top: 7px;
		margin-bottom: 20px;
	}

	.item {

		// .ms-ComboBox {
		// 	&:after {
		// 		border-color: #9b9b9b;
		// 	}
		// }
		// .ms-Label {
		// 	margin-top: 10px;
		// 	border-bottom: 1px solid #ececec;
		// 	padding-bottom: 10px;
		// 	margin-bottom: 10px !important;
		// }
		.text {
			align-items: center;
			font-weight: 500;
			font-size: 11px;
		}

		.icon {
			width: 29.5px;
			height: 29.5px;
			border-radius: 100%;
			margin-right: 7.5px;
			display: flex;
			background: #178df7;
			text-align: center;
			color: #fff;
			font-size: 11px;
			font-weight: 600;
			justify-content: center;
			align-items: center;
		}
	}

	// .ms-ComboBox-container {
	// 	.ms-Label {
	// 		border-bottom: none;
	// 		margin-bottom: 0px !important;
	// 	}
	// }
	button.show-user-profile {
		padding: 8px 15px;
		margin-top: 20px;
		font-weight: 600;
		border: 1px solid #1f74c0;
		background: #178df7;
		cursor: pointer;
		color: #fff;
		font-size: 10.5px;
		@include t(all, 0.25s);

		&:hover {
			background: #1f74c0;
		}
	}
}

.connection-container {
	background: #404040;
	margin: 58px 11px 11px 11px;
	height: calc(100vh - 70px);
	box-shadow: 2px 1px 4px -1px rgba(0, 0, 0, 0.05);
	padding: 75px;

	> {
		.title {
			font-size: 1.6em;
			color: #fff;
			display: block;
			font-weight: 700;
		}

		.sub-title {
			font-size: 0.9em;
			letter-spacing: 0.2px;
			color: #d8d8d8;
			display: block;
			border-bottom: 1px solid #4b4b4b;
			padding-bottom: 30px;
		}

		.wrapper {
			margin-top: 30px;
		}
	}

	.col-title {
		font-size: 1.15em;
		color: #fff;
		font-weight: 600;
	}

	.col-sub-title {
		font-size: 0.85em;
		color: #cecece;
		margin-top: 5px;
	}

	.not-found-app {
		font-size: 0.95em;
		padding: 5px;
		color: #cacaca;
	}

	.select-exist {
		padding: 0 50px 0 0;
		position: relative;

		&:after {
			position: absolute;
			right: 0;
			height: 100%;
			top: 0;
			border-right: 1px solid #4b4b4b;
			content: "";
			width: 1px;
		}

		.datasource-list {
			margin-top: 20px;
			margin: 20px -5px;
			max-height: calc(100vh - 380px);
			overflow-y: auto;

			&::-webkit-scrollbar {
				width: 5px;
			}

			&::-webkit-scrollbar-track {
				background: #e7e7e7;
			}

			&::-webkit-scrollbar-thumb {
				background: #bbbbbb;
			}

			.item {
				padding: 5px;

				.item-wrapper {
					background: #4a4a4a;
					border: 1px solid #555;
					@include t(all, 0.25s);
					position: relative;
					z-index: 1;

					&:hover {
						&:before {
							width: 100%;
						}
					}

					&:before {
						position: absolute;
						left: 0;
						top: 0;
						width: 0;
						content: "";
						height: 100%;
						pointer-events: none;
						background: #535353;
						-webkit-transition: all 0.35s;
						-moz-transition: all 0.35s;
						-o-transition: all 0.35s;
						transition: all 0.35s;
						z-index: -1;
					}

					a {
						padding: 7.5px 7.5px 7.5px 10px;
						width: calc(100% - 100px);

						i {
							padding-right: 7px;
							padding-top: 2px;
							color: #e2e2e2;
							font-size: 0.95em;
						}
					}
				}

				.row {
					align-items: flex-start;
					padding: 6px;
				}

				img {
					width: 36px;
					height: auto;
					/* background: #f1f1f1; */
					padding: 5.5px;
					border-radius: 100%;
					margin: -4px 2px -3px -5px;
					filter: brightness(0) invert(0.8);
				}

				.ds-title {
					font-weight: 500;
					display: block;
					font-size: 0.95em;
					color: #e2e2e2;
				}

				.ds-date {
					display: block;
					color: #b9b9b9;
					font-size: 0.75em;
					margin-top: 1px;
				}

				.ds-settings {
					margin-left: auto;
					margin-top: 10.5px;
					font-size: 1.2em;
					cursor: pointer;
					margin-right: 5px;
					color: #e2e2e2;
				}

				.ds-users {
					margin-top: 10.5px;
					font-size: 1.3em;
					cursor: pointer;
					margin-right: 12.5px;
					margin-left: 5px;
					color: #cacaca;
				}
			}
		}
	}

	.create-new {
		padding: 0px 20px;
		text-align: center;

		>img {
			padding: 15px 0px;
			width: 55px;
		}

		.ms-Button {
			margin-top: 25px;
			font-size: 11px;
		}
	}
}

.left-panel {
	max-width: 305px;
	height: calc(100vh - 45px);
	margin-top: 45px;
	position: sticky;
	z-index: 9999;
	top: 45px;

	.left-panel-wrapper {
		position: relative;
		height: 100%;

		.panel-side {
			height: 100%;
			width: 45px;
			background: $color;
			display: flex;
			flex-direction: column;

			.item {
				cursor: pointer;
				color: #fff;
				padding: 14.3px 10px;
				text-align: center;
				position: relative;
				@include t(all, 0.2s);

				&:hover {
					background: rgb(78, 78, 78);
				}

				&.active {
					background: $light-color  !important;
				}

				@include t(all, 0.25s);

				&:last-child {

					&:before,
					&:after {
						display: none !important;
					}
				}

				i {
					font-size: 17px;
				}

				&:after {
					position: absolute;
					content: "";
					background: #515151;
					left: 0;
					bottom: 0;
					width: 100%;
					height: 1px;
				}

				&:before {
					position: absolute;
					content: "";
					background: #3c3c3c;
					left: 0;
					bottom: -1px;
					width: 100%;
					height: 1px;
				}
			}

			.align-bottom {
				margin-top: auto;
			}
		}

		.panel-inner {
			width: 260px;
			padding-bottom: 15px;
			overflow-y: auto;
			background: $dark-color;
			height: calc(100vh - 45px);
			position: relative;

			&::-webkit-scrollbar {
				width: 5px;
			}

			&::-webkit-scrollbar-track {
				background: #888;
			}

			&::-webkit-scrollbar-thumb {
				background: rgb(90, 90, 90);
			}

			.panel-head {
				background: $light-color;
				color: #ffffff;
				align-items: center;
				padding: 11px 10px;
				position: sticky;
				top: 0px;
				z-index: 1;

				.title {
					font-size: 15px;
					font-weight: 700;
				}

				.sub-title {
					color: #e2e2e2;
					background: transparent;
					border: none;
					width: 100%;
					display: block;
					font-size: 8.5px;
					font-weight: 400;
				}

				.close {
					color: #e2e2e2;
					margin-left: auto;
					font-size: 13px;
					cursor: pointer;
				}
			}
		}
	}
}

.tooltip-wrapper {
	position: relative;

	&:hover {
		&>.tooltip {
			transform: scaleX(1);
			-moz-transform: scaleX(1);
			-webkit-transform: scaleX(1);
			-o-transform: scaleX(1);
		}
	}

	.tooltip {
		position: absolute;
		left: calc(100% + 10px);
		min-width: 150px;
		background: $color;
		font-size: 11px;
		padding: 5px;
		z-index: 99;
		pointer-events: none;
		font-weight: 500;
		top: 50%;
		margin-top: -12.5px;
		transform: scaleX(0);
		-moz-transform: scaleX(0);
		-webkit-transform: scaleX(0);
		-o-transform: scaleX(0);
		@include t(all, 0.25s);
		transform-origin: left;

		&:before {
			position: absolute;
			left: -4px;
			content: "";
			width: 0;
			height: 0;
			border-top: 4px solid transparent;
			border-bottom: 4px solid transparent;
			border-right: 4px solid $color;
		}
	}
}

.external-panel-list {
	left: 260px;
	top: 45px;
	width: 0px;
	pointer-events: auto;
	opacity: 0.2;
	padding-bottom: 15px;
	overflow-y: auto;
	background: #3d3d3d;
	height: calc(100vh - 45px);
	position: fixed;
	overflow-x: hidden;
	z-index: 99;
	@include t(all, 0.35s);

	.panel-head {
		min-width: 300px;
		background: #4c4c4c !important;
		color: #fff;
		align-items: center;
		padding: 11px 10px;
		position: sticky;
		top: 0;
		z-index: 1;
		margin-bottom: 7px;
		border-bottom: 1px solid #4d4d4d;

		.title {
			font-size: 14px !important;
		}
	}

	.panel-list {
		min-width: 300px;

		a:hover {
			background: #474747 !important;
		}
	}
}

.panel-list {
	margin-top: -7px;

	.panel-buttons {
		padding: 10px;
		background: #353535;
		position: absolute;
		bottom: 0;
		width: 100%;
		display: flex;
		justify-content: flex-end;

		.ms-Button {
			font-size: 11px;
			padding: 0px 12.5px;
		}
	}

	.item {
		position: relative;
		z-index: 99;

		&.file-manager {
			&>.row {
				padding-left: 9px !important;
				border-left: 6px solid #2d8892;

				&:hover {
					background: #2d8892 !important;
				}
			}
		}

		&.commerce {
			&>.row {
				padding-left: 9px !important;
				border-left: 6px solid #43856c;

				&:hover {
					background: #43856c !important;
				}
			}
		}

		&.content-type {
			&>.row {
				padding-left: 9px !important;
				border-left: 6px solid #da5c14;

				&:hover {
					background: #da5c14 !important;
				}
			}
		}

		&.active {

			a,
			&>.row {
				background: $color;
			}
		}

		&:hover {
			.external-panel-list {
				width: 300px;
				opacity: 1;
				pointer-events: all;
				transition-delay: 0.4s;
			}
		}

		a,
		&>.row {
			padding: 8.5px 15px;
			display: flex;
			cursor: pointer;
			align-items: center;
			color: rgb(241, 241, 241);
			font-size: 11px;
			border-bottom: 1px solid #474747;
			@include t(all, 0.25s);

			.circle {
				width: 7px;
				height: 7px;
				background: #868686;
				border-radius: 100%;
				margin-left: auto;
				@include t(all, 0.25s);
				@include s(0);

				&.has-difference {
					@include s(1);
				}
			}

			&:hover {
				background: $color;
			}

			i {
				font-size: 11px;
				position: relative;
				padding-right: 11.5px;
				width: 21px;
				top: -1px;
			}

			i.parent {
				margin-left: auto;
				font-size: 11px;
				top: 0.5px;
				padding-right: 0px;
				width: auto;
			}
		}
	}
}

.indicator-bar {
	position: fixed;
	bottom: 0;
	width: 100%;
	z-index: 99;
	background: $color;
	box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.16);
	height: 35px;

	.indicator-bar-wrapper {
		align-items: center;
		padding: 1px 7.5px;
		height: 100%;
	}
}

.panel-search {
	margin-bottom: 7.5px;
	padding: 6px;
	background: $light-color;
	border-top: 1px solid #4e4e4e;

	.ms-SearchBox {
		background: #4c4c4c;
		color: #fff;

		i {
			color: rgb(199, 199, 199) !important;
			font-size: 11px;
		}

		.ms-Button--icon {
			&:hover {
				background: transparent;
			}
		}

		input {
			color: rgb(221, 221, 221);
			font-weight: 300;

			&::-webkit-input-placeholder {
				font-weight: 500;
				color: rgb(199, 199, 199);
			}
		}
	}
}

.datasource-container {
	max-width: calc(100% - 45px);
	flex-basis: 0px;
	-webkit-box-flex: 1;
	flex-grow: 1;
	padding: 10px 11.5px;
	overflow: auto;
	margin-top: 45px;
	position: relative;

	&::-webkit-scrollbar {
		width: 7.5px;
		height: 7.5px;
	}

	&::-webkit-scrollbar-track {
		background: rgb(110, 110, 110);
	}

	&::-webkit-scrollbar-thumb {
		background: rgb(90, 90, 90);
	}

	.datasource-wrapper {
		background: #fff;
		padding: 0px;
		box-shadow: 2px 1px 4px -1px rgba(0, 0, 0, 0.05);
		height: calc(100vh - 67px);
		overflow: auto;
		position: relative;

		&::-webkit-scrollbar {
			width: 7.5px;
			height: 7.5px;
		}

		&::-webkit-scrollbar-track {
			background: rgb(110, 110, 110);
		}

		&::-webkit-scrollbar-thumb {
			background: rgb(90, 90, 90);
		}

		.dd-delete {
			font-size: 11px !important;
		}

		&>.title {
			padding: 8px;
			background: #f7f7f7;
			font-size: 11px;
			border-bottom: 1px solid #e3e3e3;
			position: sticky;
			display: flex;
			top: 0;
			z-index: 11;

			.title-wrapper {
				align-items: center;
				display: inline-flex;
				background: #585858;
				padding: 5px 10px;
				height: 25px;
				color: #f6f6f6;

				&.new {
					margin-left: 5px;
					padding-left: 19px;
					font-weight: 700;
					background: 0 0;
					position: relative;
					font-style: italic;
					color: #3a3a3a;

					&:before {
						position: absolute;
						background: #bebcbc;
						width: 6px;
						height: 6px;
						border-radius: 100%;
						content: "";
						left: 6px;
						top: 10px;
					}

					i {
						font-size: 14px;
						padding-right: 5px;
					}
				}
			}

			strong {
				margin-left: 2.5px;
			}

			img {
				width: 15px;
				margin: 0px 5px 0px 2.5px;
				filter: brightness(0) invert(1);
			}
		}

		.group {
			padding: 15px 20px 5px 20px;

			.title {
				font-weight: 700;
				margin-bottom: 5px;
				font-size: 13px;

				.sub-title {
					font-weight: 500;
					font-size: 10.5px;
				}
			}

			.items {
				margin: 0px -5px;

				.item {
					padding: 7.5px;

					.item-wrapper {
						padding: 8px;
						align-items: center;
						font-size: 11px;
						background: #f5f5f5;
						border: 1px solid #dfdddd;
						color: #272727;
						cursor: pointer;

						.text {
							font-weight: 700;
							font-size: 11px;
						}

						.date {
							font-size: 10px;
							font-weight: 400;
							margin-top: -1.5px;
						}

						i {
							font-size: 15px;
							padding: 0px 7px;
						}
					}
				}
			}
		}

		.content-wrapper {
			background: #fff;
			padding: 0;
			box-shadow: 2px 1px 4px -1px rgba(0, 0, 0, 0.05);
			height: calc(100vh - 109px);
			overflow: auto;
			position: relative;

			&::-webkit-scrollbar {
				width: 7.5px;
				height: 7.5px;
			}

			&::-webkit-scrollbar-track {
				background: rgb(110, 110, 110);
			}

			&::-webkit-scrollbar-thumb {
				background: rgb(90, 90, 90);
			}
		}
	}

	.table-wrapper {
		padding: 10px;
	}

	table {
		border: 1px solid #ebebeb;

		thead {
			background: #f9f9f9;

			th {
				font-size: 0.87em;
				font-weight: 500;
				color: #272727;
				padding: 10px;
				border-left: 1px solid #ebebeb;
				min-width: 20px;

				&:first-child {
					border-left: none;
				}
			}
		}

		tbody {
			tr {
				&:last-child td {
					border-bottom: none;
				}

				&:hover td {
					background: #eef9ff;
				}
			}

			td {
				border-bottom: 1px solid #ebebeb;
				border-left: 1px solid #ebebeb;
				-webkit-transition: all 0.25s;
				-moz-transition: all 0.25s;
				-o-transition: all 0.25s;
				transition: all 0.25s;

				&:first-child {
					border-left: none;
				}
			}
		}

		td {
			font-size: 0.87em;
			text-align: center;

			.ms-TextField.group-textfield.is-disabled {
				border-bottom: none !important;

				.ms-TextField-fieldGroup {
					height: 29px;
				}
			}

			.ms-Checkbox {
				margin-left: 7.5px;

				&.is-disabled {
					.ms-Checkbox-checkbox {
						border: none;

						i {
							color: #2a2a2a;
						}
					}
				}
			}

			.ms-ComboBox {
				height: 38px !important;
				line-height: 38px !important;
			}

			.ms-ComboBox-container.custom-combobox {
				.ms-ComboBox {
					&:focus {
						&:after {
							border: 1px solid #ececec;
						}
					}

					input {
						background: transparent;
					}

					&:after {
						border-color: transparent;
					}

					.ms-Button {
						height: 27px;
						background: transparent !important;
					}
				}
			}
		}

		.remove-tb-row {
			display: flex;
			align-items: center;
			justify-content: center;
			height: 100%;
			cursor: pointer;
			font-size: 13px;
			padding: 0px 10px;

			i {
				position: relative;
				top: -1px;
				color: #af3e3e;
				font-weight: 500;
			}
		}

		.set-tb-key {
			display: flex;
			align-items: center;
			justify-content: center;
			height: 100%;
			cursor: pointer;
			-webkit-transition: all 0.2s;
			-moz-transition: all 0.2s;
			-o-transition: all 0.2s;
			transition: all 0.2s;
			font-size: 11px;
			padding: 0px 10px;
			position: relative;

			&::selection {
				background: transparent;
			}

			&.active i {
				color: #eeaf51;
			}

			&:hover {
				.primary-tooltip {
					opacity: 1;
				}
			}

			.primary-tooltip {
				position: absolute;
				left: 0px;
				background: #4d4d4d;
				width: 80px;
				text-align: center;
				padding: 2.5px;
				font-weight: 500;
				font-size: 10px;
				z-index: 1;
				top: -30px;
				color: #fff;
				@include t(all, 0.25s);
				opacity: 0;
				pointer-events: none;

				&:after {
					position: absolute;
					content: "";
					bottom: -3px;
					left: 4px;
					width: 0;
					height: 0;
					border-left: 5px solid transparent;
					border-right: 5px solid transparent;
					border-top: 5px solid #4d4d4d;
				}
			}

			i {
				position: relative;
				top: -1px;
				font-weight: 600;
				-webkit-transition: all 0.2s;
				-moz-transition: all 0.2s;
				-o-transition: all 0.2s;
				transition: all 0.2s;

				&::selection {
					background: transparent;
				}
			}
		}
	}
}

.react-monaco-editor-container {
	margin-bottom: 10px;
	max-width: 100% !important;
}

.result-resizable {
	bottom: 12px !important;
	top: inherit !important;
	width: 100% !important;
	left: 12px !important;
	min-width: calc(100% - 23px) !important;
	right: inherit;
	background: #585858;

	.result-panel {
		position: relative;
	}
}

.resizable-panel {
	width: 320px;

	&.absolute {
		position: absolute !important;
		top: 50px;
	}

	&.absolute-left {
		left: 0;
	}

	&.absolute-right {
		right: 0;
	}

	&.auto-width {
		width: auto !important;
		min-width: auto !important;
	}
}

.result-panel {
	left: 0px;
	width: 100%;
	position: absolute;
	bottom: 0px;
	background: #f6f6f6;
	min-height: 300px;

	.ms-Pivot {
		border-bottom: 1px solid #636363;
		background: $soft-color;

		.ms-Button {
			height: 30px;
			padding: 0px 23px;
			font-weight: 500;
			font-size: 0.9em;
			background: $soft-color;
			@include t(all, 0.1s);
			color: #c0c0c0;

			&.is-selected {
				background: #585858;
				color: #ececec;
			}

			&:first-child {
				border-right: none;
			}
		}
	}

	.message-text {
		padding: 15px;
		font-size: 0.9em;
		font-style: italic;
		color: #2a303a;

		&.error {
			background: #b04c4c;
			color: #fff;
			display: inline-block;
			padding: 7.5px 15px;
			margin: 10px;
			font-size: 0.81em;
			font-style: normal;
			font-weight: 300;
			letter-spacing: 0.3px;

			strong {
				font-weight: 400;
				letter-spacing: 0px;
				font-weight: 500;
				display: block;
				font-size: 1.05em;
			}
		}

		&.success {
			background: $green-color;
			color: #fff;
			display: inline-block;
			padding: 7.5px 15px;
			margin: 3px 3px 10px 3px;
			font-size: 10px;
			font-style: normal;
			font-weight: 300;
			letter-spacing: 0.3px;

			strong {
				font-weight: 500;
				letter-spacing: 0px;
				display: block;
				font-size: 1.05em;
			}
		}
	}

	.close {
		position: absolute;
		right: 8px;
		top: 4px;
		z-index: 1;
		font-size: 0.65em;
		cursor: pointer;
		background: #b94747;
		width: 24px;
		height: 24px;
		border-radius: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #ffffff;
	}
}

.pivot-container {
	padding: 7.5px;
	overflow-x: auto;

	&::-webkit-scrollbar {
		width: 0px;
		height: 5px;
	}

	&::-webkit-scrollbar-track {
		background: #f1f1f1;
	}

	&::-webkit-scrollbar-thumb {
		background: #d3d3d3;
	}

	.fa-condition-builder {
		background: transparent;
		padding: 0px;
		margin-top: -2.5px;
	}
}

.ms-DetailsList.custom {
	width: 100%;
	border-left: 1px solid #cecece;
	border-right: 1px solid #cecece;
	border-bottom: 1px solid #cecece;
	background: #ffffff;

	.ms-DetailsHeader {
		padding-top: 0px;
		height: 30px;

		.ms-DetailsHeader-cell {
			height: 35px;
			line-height: 35px;
			font-weight: 600;
			background: #e9e9e9;
			border-top: 1px solid #cecece;
			border-bottom: 1px solid #cecece;
			border-right: 1px solid #cecece;

			&:last-child {
				border-right: 1px solid #585858;
			}
		}

		.ms-DetailsHeader-cellName {
			font-size: 0.9em;
			font-weight: 700;
			color: #1b1b1b;
		}
	}

	.ms-DetailsRow-cell {
		font-size: 0.9em;
		font-weight: 500;
		height: 30px;
		line-height: 30px;
		min-height: 30px;
		border-right: 1px solid #cecece;
		background: #ffffff;
		color: rgb(32, 32, 32);
		padding: 0px 11px;
		@include t(all, 0.15s);

		&.center {
			display: flex;
			align-items: center;
		}
	}

	.ms-List-cell {
		height: 30px;
		min-height: 30px;

		&:hover {
			.ms-DetailsRow-cell {
				background: #e6f2fb !important;
			}
		}

		&:nth-child(2n) {
			.ms-DetailsRow-cell {
				background: #ebebeb;
			}
		}

		&:hover .ms-DetailsRow {
			background: #e6f2fb !important;
		}

		.ms-DetailsRow {
			height: 30px;
			background: transparent !important;
			min-height: 30px;
		}
	}

	.ms-DetailsList-contentWrapper {
		&::-webkit-scrollbar {
			width: 0px;
			height: 5px;
		}

		&::-webkit-scrollbar-track {
			background: $light-color;
		}

		&::-webkit-scrollbar-thumb {
			background: $light-color;
		}
	}
}

.ms-Dialog-subText {
	margin-bottom: 10px;
	margin-top: -10px;
}

.ms-Dialog-actions.absolute {
	position: absolute;
	bottom: 0;
	right: 0;
	padding: 12px;
	background: #f6f6f6;
}

.insert-row {
	margin-top: 10px;
	font-size: 0.8em;
	padding: 0px 15px;
	height: 27.5px;
	line-height: 27.5px;

	.ms-Button-label {
		font-weight: 600;
	}
}

.kit-list {
	width: 100%;
	margin-top: -7.5px;

	.item {
		position: relative;
		left: 1px;
		padding: 10px;
		text-align: center;
		border: 1px solid #414141;
		margin-bottom: -1px;
		margin-right: -1px;
		font-size: 0.85em;
		color: rgb(209, 209, 209);
		font-weight: 500;
		cursor: grab;
		@include t(all, 0.25s);

		&::selection {
			background: transparent;
		}

		&:hover {
			background: rgb(126, 126, 126);

			.info {
				color: #fff;
			}
		}

		&:nth-child(3n) {
			border-right: none;
		}

		&:nth-child(1),
		&:nth-child(2),
		&:nth-child(3) {
			border-top: none;
		}

		// &:nth-last-child(1), &:nth-last-child(2), &:last-child {
		//   border-bottom: none;
		// };
		img {
			margin: 10px auto 6px;
			width: 45px;
			display: table;

			&::selection {
				background: transparent;
			}
		}

		&>i {
			margin: 10px auto 6px;
			font-size: 25px;
			display: table;

			&::selection {
				background: transparent;
			}
		}
	}
}

.dialog-table {
	.not-found-table {
		background: #e0f2ff;
		width: 100%;
		padding: 10px;
		font-size: 10.5px;
		font-weight: 500;
		color: #000;
		display: flex;
		align-items: center;
		margin-top: 5px;

		i {
			padding-right: 5px;
			font-size: 11px;
			color: #0078d4;
		}
	}

	margin-top: 10px;

	.dialog-table-header {
		padding: 10px 0px;
		background: #f6f6f6;
		font-size: 12.5px;
		font-weight: 600;
		border-bottom: 1px solid #dad9d9;
	}

	.dialog-table-content {
		max-height: 400px;
		overflow-y: auto;
		margin-bottom: 45px;

		&::-webkit-scrollbar {
			width: 2px;
		}

		&::-webkit-scrollbar-track {
			background: #747474;
		}

		&::-webkit-scrollbar-thumb {
			background: #bbbbbb;
		}

		.row {
			padding: 7.5px 0px;
			font-size: 11px;
			font-weight: 500;
			align-items: center;
			border-bottom: 1px solid #e2e2e2;
			@include t(all, 0.25s);

			&:hover {
				background: #e6f2fb !important;
			}

			&:nth-child(2n) {
				background: #f6f6f6;
			}

			&:last-child {
				border-bottom: none;
			}
		}

		.remove {
			font-size: 15px;
			cursor: pointer;
			color: #d87272;
		}
	}
}

.query-builder {
	.query-builder-tabs {
		background: #f5f5f5;
		border-bottom: 1px solid #e3e3e3;

		.tab-item {
			padding: 7.5px 30px;
			font-weight: 600;
			font-size: 11.5px;
			cursor: pointer;
			border-right: 1px solid #e3e3e3;
			border-bottom: 1px solid transparent;
			@include t(all, 0.2s);
			color: rgb(143, 143, 143);

			span {
				background: #0078d4;
				color: #fff;
				font-weight: 500;
				font-size: 8.5px;
				margin-left: 7px;
				margin-right: -9px;
				padding: 2px 10px;
				position: relative;
				top: -1px;
			}

			&:last-child {
				border-right: none;
			}

			&.active {
				color: #0078d4;
				border-bottom: 1px solid #0078d4;
			}
		}
	}

	.query-builder-content {
		.wizard-info {
			background: #e0f2ff;
			width: calc(100% - 30px);
			padding: 10px 15px;
			font-size: 11px;
			font-weight: 500;
			color: #000;
			display: flex;
			align-items: center;
			margin-top: 12px;
			margin-bottom: 10px;
			margin-left: 15px;
			font-style: italic;

			i {
				padding-right: 6.5px;
				font-size: 11px;
				color: #0078d4;
			}
		}
	}
}

.fa-condition-builder {
	padding: 10px 15px;
	width: 100%;
	background: #fafafa;
	font-size: 12.5px;

	.fa-cb-item {
		position: relative;
		width: 100%;

		&:before {
			position: absolute;
			content: "";
			background: #ccc;
			width: 1px;
			height: calc(100% - 85px);
			left: 11px;
			top: 30px;
			z-index: 0;
			pointer-events: none;
		}

		.fa-cb-head {
			button {
				z-index: 9;
				height: 27px;
				display: inline-flex;
				align-items: center;
				margin-right: -1px;
				border: 1px solid $blue-color;
				background: #fff;
				color: $blue-color;
				font-weight: 600;
				letter-spacing: -0.3px;
				padding: 0px 15px;
				outline: none;
				cursor: pointer;
				font-size: 10px;
				transition: all, 0.25s;
				-moz-transition: all, 0.25s;
				-o-transition: all, 0.25s;
				-webkit-transition: all, 0.25s;

				&:hover {
					background: #f3f3f3;
				}

				i {
					font-size: 16px;
					padding-right: 6.5px;
				}

				&.purple {
					background: $blue-color;
					color: #fff;
				}

				&.red {
					margin-left: 10px;
					border: 1px solid #cb3739;
					color: #cb3739;

					i {
						font-size: 16px;
					}
				}
			}

			.button-group {
				margin-right: 3px;

				&:last-child {
					margin-left: auto;
				}
			}
		}

		.ms-Button {
			font-size: 12.5px;
		}

		.not-found {
			i {
				padding-right: 6.5px;
				font-size: 11.5px;
				color: #986200;
			}

			background: #f8ecd5;
			width: 100%;
			padding: 10px 15px;
			font-size: 11px;
			font-weight: 500;
			color: #000;
			display: flex;
			align-items: center;
			margin-top: 8px;
			margin-bottom: 7px;
			font-style: italic;
		}

		.fa-cb-body {
			padding: 20px 0px 7px 25px;

			.rule-list .rule-item {
				background: #fff;
				border: 1px solid #e7e7e7;
				margin-bottom: 7px;
				padding: 7.5px 30px 8px 10px;
				align-items: center;
				transition: all, 0.25s;
				-moz-transition: all, 0.25s;
				-o-transition: all, 0.25s;
				-webkit-transition: all, 0.25s;
				position: relative;
				padding-right: 30px;

				.small-element {
					margin-right: 0px !important;
				}

				&.has-group {
					background: rgba(209, 209, 209, 0.1);
					border: 1px solid rgba(209, 209, 209, 0.5);
					padding-right: 12px;

					&:hover {
						background: rgba(209, 209, 209, 0.15);
					}
				}

				&:before {
					position: absolute;
					content: "";
					left: -15px;
					width: 15px;
					bottom: 41px;
					background: #ccc;
					height: 1px;
				}

				&:hover {
					background: #fafafa;
				}

				.sub-text {
					position: relative;
					font-size: 10.5px;

					/* background: rgba(0, 0, 0, 0.6); */
					color: #424242;
					min-width: 80px;

					/* opacity: 0; */
					transition: all, 0.25s;
					-moz-transition: all, 0.25s;
					-o-transition: all, 0.25s;
					-webkit-transition: all, 0.25s;

					/* transform: scale(0); */
					/* -webkit-transform: scale(0); */
					transform-origin: center;
					margin-top: -1px;
					display: block;
					max-width: calc(90%);
					font-weight: 600;
					margin-bottom: 2px;
				}

				.ms-Label {
					font-size: 11px !important;
				}

				.ms-Dropdown-container,
				.ms-ComboBox-container,
				.ms-TextField {
					margin-right: 10px;
					font-size: 11px;
				}

				.ms-Dropdown-container input,
				.ms-ComboBox-container input,
				.ms-TextField input,
				.ms-Dropdown-container input::-webkit-input-placeholder,
				.ms-ComboBox-container input::-webkit-input-placeholder,
				.ms-TextField input::-webkit-input-placeholder,
				.ms-Dropdown-title,
				.ms-TextField-fieldGroup,
				.ms-ComboBox {
					font-size: 11px;
				}

				.ms-ChoiceFieldGroup-flexContainer {
					display: flex;

					.ms-ChoiceField {
						padding: 0px 5px;
						margin-top: 0px;

						.ms-ChoiceField-field {
							&.is-checked:after {
								display: block;
							}

							&:before {
								width: 16px;
								height: 16px;
								top: 2px;
							}

							&:after {
								width: 6px;
								height: 6px;
								left: 3px;
								top: 5px;
								display: none;
							}
						}

						.ms-ChoiceFieldLabel {
							font-size: 11px;
							padding-left: 21px;
						}
					}
				}

				.remove-rule-item {
					margin-left: auto;
					font-size: 11px;
					padding-right: 7px;
					margin-left: 5px;
					cursor: pointer;
					padding: 10px;
					transition: all, 0.25s;
					-moz-transition: all, 0.25s;
					-o-transition: all, 0.25s;
					-webkit-transition: all, 0.25s;
					text-align: center;
					-webkit-touch-callout: none;

					/* iOS Safari */
					-webkit-user-select: none;

					/* Safari */
					-khtml-user-select: none;

					/* Konqueror HTML */
					-moz-user-select: none;

					/* Firefox */
					-ms-user-select: none;

					/* Internet Explorer/Edge */
					user-select: none;
					position: absolute;
					right: 0px;
					margin-top: -1px;

					&:hover {
						color: #a02b2b;
					}
				}
			}

			.binding-selector {
				.ms-Label {
					margin-bottom: 3px;
				}

				&>.row {
					position: relative;
					top: 5px;
				}

				.operand-type {
					margin-right: -1px !important;
					width: 97px !important;
				}
			}
		}
	}

	.fa-condition-tools {
		display: flex;
		margin: 0px 0px -5px 0px;
		justify-content: flex-end;

		button {
			padding: 9px 14px;
			margin-left: 7.5px;
			outline: none;
			border: none;
			appearance: none;
			cursor: pointer;
			font-weight: 500;
			-webkit-appearance: none;
			font-size: 11px;
			display: flex;
			align-items: center;
			transition: all, 0.25s;
			-moz-transition: all, 0.25s;
			-o-transition: all, 0.25s;
			-webkit-transition: all, 0.25s;
			font-family: "Roboto", sans-serif;

			&.success {
				background: #57947e;
				color: #fff;
			}

			&.reset {
				background: #e4e4e4;
				color: #272727;
				font-size: 10px;
				margin-bottom: 6px;
			}

			&:hover {
				opacity: 0.8;
			}

			i {
				padding-right: 5px;

				&[data-icon-name="CheckMark"] {
					font-size: 14px;
				}
			}
		}
	}
}

.rule-callout {

	.ms-Button,
	.ms-ComboBox-optionText {
		font-size: 11px;
	}
}

.normalscale {
	pointer-events: auto;
	@include t(all, 0.25s);
}

.grayscale {
	filter: grayscale(1);
	pointer-events: none;
}

.key-values {
	margin-left: 15px;
	width: calc(100% - 30px);

	.item {
		background: #fafafa;
		border: 1px solid #e8e7e7;
		padding: 7px 8px;
		align-items: center;
		margin-bottom: 5px;

		.ms-Label {
			font-size: 10.5px !important;
			padding-top: 0px;
		}

		.remove {
			cursor: pointer;

			&::selection {
				background: transparent;
			}

			i {
				font-size: 11px;
				margin-top: 22px;

				&::selection {
					background: transparent;
				}
			}
		}
	}
}

.pivot-container {
	.fa-condition-builder {
		&::-webkit-scrollbar {
			width: 2px;
			height: 5px;
		}

		&::-webkit-scrollbar-track {
			background: #c7c7c7;
		}

		&::-webkit-scrollbar-thumb {
			background: #909090;
		}
	}

	.fa-cb-item {
		.fa-cb-head {
			position: sticky;
			background: #ffffff;
			z-index: 9;
			padding: 5px;
			top: 0px;
		}

		.fa-cb-item {
			.fa-cb-head {
				position: relative !important;
				top: 0px;
				background: transparent;
				z-index: 1;
			}
		}
	}
}

.join-group {
	padding: 5px;
	border-bottom: 1px solid #dedede;
	padding-bottom: 7px;

	&.passive {
		background: #ededed;

		.inner-join {
			display: none;
		}
	}

	&:last-child {
		border-bottom: none;
	}

	&>.title {
		font-weight: 500;
		position: relative;
		padding-left: 5px;
		font-size: 11px;
		align-items: center;

		.collapse {
			width: 17.5px;
			height: 17.5px;
			border-radius: 100%;
			content: "";
			background: #d2d2d2;
			display: flex;
			justify-content: center;
			z-index: 9;
			font-weight: 600;
			font-size: 16px;
			line-height: 14.5px;
			cursor: pointer;
			margin-right: 9px;
			margin-left: 0px;
			position: relative;
			top: 1px;
			left: -1px;
			@include t(all, 0.15s);

			&::selection {
				background: transparent !important;
			}

			* {
				&::selection {
					background: transparent !important;
				}
			}
		}

		.code {
			padding: 0 7.5px;
			font-weight: 600;
			color: #056eda;
		}
	}

	.inner-join {
		background: #ececec;
		margin-top: 5px;
		padding: 10px;
		width: calc(100% - 30px);
		margin-left: 30px;
		font-size: 11px;
		font-weight: 400;
		position: relative;

		&:before {
			position: absolute;
			left: -20px;
			top: -17px;
			background: #d2d2d2;
			content: "";
			width: 1px;
			height: 100%;
		}

		.item {
			padding: 1.5px 0px;
			position: relative;

			span {
				font-style: italic;
				font-weight: 500;
			}

			&:before {
				position: absolute;
				left: -29px;
				background: #d2d2d2;
				content: "";
				width: 20px;
				height: 1px;
				top: 10px;
			}
		}
	}
}

.joins {
	&::-webkit-scrollbar {
		width: 2px;
		height: 5px;
	}

	&::-webkit-scrollbar-track {
		background: #c7c7c7;
	}

	&::-webkit-scrollbar-thumb {
		background: #909090;
	}
}

.not-found-warning {
	i {
		padding-right: 6.5px;
		font-size: 11.5px;
		color: #986200;
	}

	background: #f8ecd5;
	width: 100%;
	padding: 10px 15px;
	font-size: 11px;
	font-weight: 500;
	color: #000;
	display: flex;
	align-items: center;
	margin-top: 3px;
	margin-bottom: 7px;
	font-style: italic;
}

.page-title {
	width: 100%;

	&.show-back {
		.title {
			width: auto;
			flex: 0 0 calc(100% - 30px);
		}
	}

	button {
		border: 0;
		border-bottom: 1px solid #dfdfdf;
		background: 0 0;
		width: 30px;
		text-align: center;
		margin-top: -14px;
		cursor: pointer;

		.line {
			width: 1px;
			background: #595959;
			display: block;
			height: 15px;

			&:first-child {
				@include r(45deg);
			}

			&:nth-child(2) {
				@include r(-45deg);
				margin-top: -5px;
			}
		}
	}

	.title {
		font-size: 19px;
		color: #404040;
		display: block;
		font-weight: 700;
		width: 100%;

		.version,
		.type {
			font-weight: 400;
			font-size: 11px;
		}

		.edit-title {
			font-weight: 400;

			strong {
				margin-left: 5px;
				max-width: calc(100% - 581px);
				display: inline-block;
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
				position: relative;
				top: 6px;
			}

			a {
				background: #33926e;
				color: #fff;
				font-weight: 600;
				font-size: 11px;
				margin-left: 8.5px;
				border-radius: 11px;
				position: relative;
				top: -3px;
				padding: 4px 11px;
				display: inline-block;

				&.preview {
					background: #f5975c;
				}
			}
		}
	}

	.text {
		font-size: 12px;
		color: #707070;
		display: block;
		font-weight: 400;
		border-bottom: 1px solid #dfdfdf;
		padding-bottom: 20px;
		width: 100%;
	}
}

.page-content {
	margin-left: 280px;
	padding: 15px;
	width: 100%;
}

.page {
	padding: 30px 30px 40px;
	position: relative;
	width: 100%;
	min-height: calc(100% - 45px);
	background: #f5f5f5;
	max-width: calc(100% - 58px);
	flex-basis: 0;
	-webkit-box-flex: 1;
	flex-grow: 1;
	margin: 45px 0px 0px 0px;
}

.dashboard-items {
	margin: 0px -10px;
	margin-top: 15px;

	&-content-types {
		&>.title {
			border-left: 6px solid #da5c14 !important;
		}
	}

	&>.title {
		padding: 13px 12px 14px;
		font-size: 14px;
		font-weight: 600;
		border-bottom: 1px solid #ded7d7;
		width: calc(100% - 13px);
		margin-left: 5px;
		border-left: 6px solid #2d8892;
		background: #f0f0f0;

		.title-text {
			font-size: 10.5px;
			color: #636363;
		}
	}

	&.orange {
		&>.title {
			border-left: 6px solid #da5c14;
		}
	}

	.childrens {
		background: #e7e7e7;
		padding: 12px;
		margin-left: 4px;
		width: calc(100% - 12px);
	}

	.dashboard-item {
		padding: 5px;

		i {
			font-size: 22px;
			color: #fff;
			margin-right: 5px;
		}

		.badge-count {
			display: inline-block;
			position: absolute;
			left: 15px;
			background: #ca4545;
			margin-top: -2.5px;
			font-size: 11px;
		}

		&.highlight {
			a {
				background: #2d8892;
				border-color: #267179;

				&:hover {
					background: #2c7a85;
				}
			}
		}

		&.blue {
			a {
				background: #4583b4;
				border-color: #356c97;

				&:hover {
					background: #4f90c2;
				}
			}
		}

		&.green {
			a {
				background: #6a9661;
				border-color: #537b4b;

				&:hover {
					background: #58834f;
				}
			}
		}

		&.dark-red {
			a {
				background: #a10025;
				border-color: #b4173c;

				&:hover {
					background: #b4173c;
				}
			}
		}

		&.dark-blue {
			a {
				background: #5e7b83;
				border-color: #324348;

				&:hover {
					background: #557179;
				}
			}
		}

		&.red {
			a {
				background: #85525d;
				border: 1px solid #6e414b;

				&:hover {
					background: #905c68;
				}
			}
		}

		&.turqoise {
			a {
				background: #3b8786;
				border-color: #307271;

				&:hover {
					background: #307271;
				}
			}
		}

		&.coffee {
			a {
				background: #aa8b7b;
				border-color: #c6a899;

				&:hover {
					background: #b99a8b;
				}
			}
		}

		&.orange {
			a {
				background: #cb7220;
				border-color: #9d6d41;

				&:hover {
					background: #d67e2b;
				}
			}
		}

		a {
			padding: 21.5px 15px;
			background: #666666;
			border: 1px solid #4d4b4b;
			align-items: center;
			@include t(all, 0.25s);
			min-height: 90px;

			&:hover {
				background: #5f5f5f;
			}
		}

		img {
			width: 37.5px;
			filter: brightness(0) invert(1);
		}

		.title {
			margin-left: 7.5px;
			font-weight: 700;
			color: #f0f0f0;
			font-size: 12.5px;
			flex: 0 0 calc(100% - 55px);
			max-width: calc(100% - 55px);

			.sub-text {
				font-weight: 500;
				font-size: 10.5px;
			}
		}
	}
}

.table-footer {
	display: flex;
	margin: 10px 0px;
	background: #ffffff;
	border: 1px solid #dfdfdf;
	padding: 10px;
	font-size: 11px;
	font-weight: 500;

	.table-page-sizer {
		align-items: center;

		.ms-Dropdown-title {
			height: 20px !important;
			line-height: 20px !important;
			padding: 0px 10px;
			margin-top: -5px;
		}

		.ms-Dropdown-caretDownWrapper {
			display: none;
		}

		.ms-Dropdown-container {
			position: relative;
			top: 2px;
			margin-left: 5px;
		}

		.excel {
			padding: 6px 12px;
			display: flex;
			align-items: center;
			background: #389666;
			margin-right: 10px;
			color: #fff;
			font-size: 10px;
			margin-top: -5px;
			margin-bottom: -5px;
			cursor: pointer;

			i {
				padding-right: 5px;
			}
		}

		.total {
			margin-right: 15px;
			border-right: 1px solid rgb(150, 150, 150);
			padding-right: 15px;

			strong {
				font-weight: 700;
			}
		}
	}
}

.table-pager {
	display: flex;
	padding: 0px;
	margin: 0px;

	.previous {
		margin-right: 5px;
	}

	.next {
		margin-left: 5px;
	}

	li {
		&.selected {
			a {
				background: #33926e;
				color: #fff;
			}
		}

		a {
			padding: 5px 10px;
			@include t(all, 0.15s);
		}
	}

	.previous,
	.next {
		a {
			background: #f3f3f3;
			padding: 5px 10px;
		}
	}
}

span.table-circle {
	display: flex;
	align-items: center;

	.text {
		position: relative;
		top: 2px;
		font-size: 11px;
		font-weight: 600;
	}

	i {
		width: 17.5px;
		height: 17.5px;
		display: flex;
		align-items: center;
		justify-content: center;
		background: #33926e;
		color: #fff;
		border-radius: 100%;
		font-size: 9px;
		margin-top: -9px;
		margin-bottom: -11px;
		margin-right: 5px;
	}

	&.close {
		i {
			background: #c17070;
			font-size: 7px;
		}
	}
}

.table-list {
	background: #ffffff;
	border: 1px solid #dfdfdf;
	margin-top: 10px;

	.table-not-found {
		padding: 15px;
		align-items: center;
		font-size: 11px;
		font-weight: 500;
		background: #fffcf6;

		i {
			padding-right: 5px;
			color: #544931;
			font-size: 13px;
			padding-left: 5px;
		}
	}

	&.compact {
		border: 1px solid rgb(211, 211, 211);
		margin-top: 0px;

		.table-body {
			&::-webkit-scrollbar {
				width: 5px;
			}

			&::-webkit-scrollbar-track {
				background: #f1f1f1;
			}

			&::-webkit-scrollbar-thumb {
				background: rgb(190, 190, 190);
			}
		}
	}

	.table-head {
		padding: 15px 20px;
		border-bottom: 1px solid #dfdfdf;

		.list-th {
			font-weight: 700;
			color: #212121;
			padding: 0 5px;
			font-size: 12px;
		}
	}

	.table-body {
		// max-height: 75vh;
		overflow-y: auto;
		align-items: center;

		&::-webkit-scrollbar {
			width: 5px;
		}

		&::-webkit-scrollbar-track {
			background: #f1f1f1;
		}

		&::-webkit-scrollbar-thumb {
			background: rgb(190, 190, 190);
		}

		.item {
			align-items: center;
			padding: 10px 20px;
			background: #f9f9f9;
			border-bottom: 1px solid #e0e0e0;
			@include t(all, 0.25s);

			&:hover {
				background: rgba(176, 217, 241, 0.2) !important;
			}

			&:nth-child(2n) {
				background: #ffffff;
			}

			&:last-child {
				border-bottom: none;
			}

			&:hover {
				.operations {
					opacity: 1;
				}
			}
		}

		.list-td {
			align-items: center;
			font-size: 12px;
			font-weight: 700;
			padding: 0 5px;
			color: #0073aa;
			word-break: break-word;

			.status-bar {
				display: block;
				color: #ea7226;
				display: flex;
				align-items: center;
				font-weight: 600;
				cursor: pointer;

				&.status-bar-green {
					color: #33926e;
				}

				&.status-bar-red {
					color: #923333;
				}
			}

			a {
				color: #0073aa;
			}

			.person-active {
				color: $green-color;
				font-weight: 600;
				margin-left: 2px;
			}

			.person-passive {
				color: $red-color;
				margin-left: 2px;
				font-weight: 600;
			}

			.person-pending {
				color: $yellow-color;
				font-weight: 600;
				margin-left: 2px;
			}

			.approve-button {
				background: #ffffff !important;
				border: 1px solid #949290 !important;
				border-radius: 0px !important;
				display: inline-flex;
				align-items: center;
				height: 20px;
				padding: 0px 10px;
				cursor: pointer;
				justify-content: center;
				color: #efefef;
				font-size: 10px;
				border: none;
				font-weight: 600;
				color: #161616 !important;

				.ms-Button-label {
					font-family: $font  !important;
				}

				i {
					margin-right: 0px !important;
					padding-right: 0px !important;
					font-size: 10px !important;
				}

				&.active {
					background: #ad5e5e;
				}
			}

			.date,
			.status {
				display: block;
				width: 100%;
				color: #565656;
			}

			.date {
				margin-bottom: -15px;
				font-size: 10px;
			}

			.status-active {
				width: 15px !important;
				height: 15px;
				border-radius: 100%;
				background: #33926e;
				color: #fff;
				font-size: 9px;
				right: 9px;
				top: 8px;
				text-align: center;
				line-height: 15px;

				i {
					font-size: 8px;
				}
			}

			.status-passive {
				width: 15.5px !important;
				height: 15.5px;
				border-radius: 100%;
				color: #fff;
				font-size: 9px;
				right: 9px;
				top: 8px;
				text-align: center;
				line-height: 15.5px;
				background: #b32d2d;

				i {
					font-size: 7px;
				}
			}

			&.blue {
				color: #3b79a7;
				text-decoration: underline;
			}

			.operation {
				background: #e4e4e4;
				padding: 5px 25px;
				color: #373737;
				font-size: 11px;
			}

			.compact-operations {
				.compact-operation.blue {
					font-size: 11px;
					padding-right: 12.5px;
					color: #2671aa;
					cursor: pointer;
				}

				.compact-operation.red {
					font-size: 11px;
					padding-right: 12.5px;
					color: #a04141;
					cursor: pointer;
				}

				.compact-operation.green {
					font-size: 11px;
					padding-right: 7.5px;
					color: #505050;
					cursor: pointer;
					@include t(all, 0.25s);

					&.show {
						color: #33926e;
					}
				}

				i {
					padding-right: 5px;
				}
			}

			.icon {
				width: 29.5px;
				height: 29.5px;
				border-radius: 100%;
				margin-right: 7.5px;
				display: flex;
				background: $blue-color;
				text-align: center;
				color: #fff;
				font-size: 11px;
				font-weight: 600;
				justify-content: center;
				align-items: center;

				&.small {
					margin-left: -5px;
					width: 25px;
					height: 25px;
					font-size: 9px;
					font-weight: 700;
				}
			}

			&.operations {
				button {
					background: #ad5e5e;
					display: inline-flex;
					align-items: center;
					height: 20px;
					min-width: 70px;
					cursor: pointer;
					justify-content: center;
					color: #efefef;
					font-size: 11px;
					border: none;
					font-weight: 600;

					i {
						font-size: 11px;
						padding-right: 3.5px;
					}
				}

				.more {
					padding-left: 0px;

					&.disabled {
						opacity: 0.6;
					}
				}

				a,
				.more {
					background: #178df7;
					display: inline-flex;
					align-items: center;
					cursor: pointer;
					height: 20px;
					min-width: 70px;
					justify-content: center;
					color: #efefef;
					font-size: 12px;
					padding-right: 6px;
					margin-right: 7.5px;
					font-weight: 600;
					padding: 0px 10px;

					&.white {
						background: #dddddd;
						color: #3f3f3f;
					}

					&.disabled {
						opacity: .3;
						pointer-events: none;
					}

					i {
						font-size: 11px;
						padding-right: 2.5px;
					}
				}
			}
		}
	}

	.operations.full {
		width: 100%;
		margin-top: -4px;
		opacity: 0;
		padding: 0px;
		margin-bottom: -3px;
		margin-left: 2px;
		@include t(all, 0.25s);

		.more {
			padding-left: 1px;
		}

		a,
		.more {
			background: transparent;
			display: inline-flex;
			align-items: center;
			cursor: pointer;
			justify-content: center;
			color: #0073aa;
			font-size: 11px;
			font-weight: 600;
			border-left: 1px solid rgb(221, 221, 221);
			padding-right: 10px;

			&.disabled {
				opacity: .3;
				pointer-events: none;
			}

			&:first-child {
				border-left: none;
			}

			i {
				font-size: 11px;
				padding-right: 2.5px;
			}

			&:nth-child(3) {
				margin-left: 7.5px;
				padding-left: 5px;
				border-left: 1px solid rgb(221, 221, 221);
				border-right: none;
			}
		}

		button {
			background: transparent;
			display: inline-flex;
			align-items: center;
			font-weight: 600;
			cursor: pointer;
			justify-content: center;
			color: #ad5e5e;
			font-size: 11px;
			padding-right: 6px;
			margin-right: 0px;
			border: none;
			border-left: 1px solid rgb(221, 221, 221);

			&.is-only {
				padding-left: 0px;
				border-left: inherit;
			}

			i {
				font-size: 11px;
				padding-right: 3.5px;
			}
		}
	}
}

.status-button {
	background: $green-color;
	display: inline-flex;
	align-items: center;
	height: 20px;
	cursor: pointer;
	justify-content: center;
	color: #efefef;
	font-size: 12px;
	border: none;
	font-weight: 600;
	padding: 0px 10px;
	margin-right: 10px;

	&.active {
		background: #ad5e5e;
	}

	&.disabled {
		opacity: 0.6;
	}

	&.warning {
		background: #e47a29;
	}
}

.absolute-create {
	position: absolute;
	right: 30px;
	top: 32px;
	background: #33926e;
	color: #efefef;
	font-weight: 600;
	display: flex;
	align-items: center;
	@include t(all, 0.25s);
	font-size: 12px;
	margin-right: 10px;

	&.absolute-wrapper {
		background: transparent !important;

		button {
			font-size: 12px;
			margin-left: 10px;

			&.warning {
				background: #ca8f6e;
				color: #fff;
				border: 1px solid #ca8f6e;

				&:hover {
					background: #d59570;
					border: 1px solid #d59570;
				}
			}
		}
	}

	i {
		font-size: 12px;

		&[data-icon-name="CheckMark"] {
			font-size: 16px;
		}
	}

	&:hover {
		background: #3ca17b;
	}
}

.table-filter {
	align-items: center;
	padding: 6px 0px 9px 0px;
	background: #ffffff;
	margin-top: 9px;
	border: 1px solid #dfdfdf;
}

.login-page {
	height: 100vh;
	display: flex;
	align-items: center;
	overflow: hidden;
	position: relative;
	background: url("../images/home-page.png");
	background-size: cover;

	&:before {
		position: absolute;
		left: 0;
		width: 100%;
		background: rgba(0, 0, 0, 0.62);
		height: 100%;
		content: "";
		top: 0;
		z-index: 0;
	}

	.login-wrapper {
		background: rgba(255, 255, 255, 1);
		width: 450px;
		position: fixed;
		padding: 15px 35px;
		right: 0;
		margin: 0px auto;
		box-shadow: 0px 0px 6px -2px rgba(0, 0, 0, 0.39);
		height: 100%;
		align-items: center;
		display: flex;
		flex-direction: column;

		.logo {
			img {
				width: 150px;
				margin-bottom: -20px;
				margin-top: 20px;
			}
		}

		&.relative {
			position: relative;
			height: auto;

			.login-screen {
				margin-top: 0px;
			}
		}

		.top-text {
			padding: 20px 0px 20px 0px;
			border-bottom: 1px solid #ebebeb;
			width: 100%;

			.title {
				color: #2d2d2d;
				font-size: 18px;
				font-weight: 800;
				text-align: center;
				margin-bottom: 0;
				letter-spacing: -0.5px;

				img {
					filter: brightness(0.1);
					width: 180px;
				}

				i {
					font-size: 49.5px;
				}
			}
		}

		.login-screen {
			padding: 20px 0px 40px 0px;
			width: 100%;
			margin-top: 30px;

			&.p-none {
				padding-bottom: 15px;
			}

			.text {
				.title {
					font-weight: bold;
					font-size: 17px;
					color: #2f2f2f;

					&.light {
						font-weight: 400;
						font-size: 17px;

						strong {
							position: relative;
						}
					}
				}

				.sub-title {
					font-size: 11px;
					font-weight: 400;
					color: #333333;
					margin: 5px 0px;
					display: block;
				}
			}

			.form-item {
				margin-top: 10px;

				&.error {
					input {
						border-color: #a54141;
					}

					.error-message {
						font-size: 11px;
						font-weight: 600;
						margin-top: 5px;
						display: block;
						color: #a54141;
					}
				}

				label {
					display: block;
					font-weight: 500;
					margin-bottom: 5px;
					font-size: 12.5px;
				}

				input {
					width: 100%;
					padding: 12.5px;
					font-size: 11.5px;
					border: 1px solid #d3d3d3;
					@include t(all, 0.2s);

					&:focus {
						border-color: rgb(66, 139, 211);
					}
				}
			}

			.login-bottom {
				display: block;
				width: 100%;
			}

			.submit-wrapper {
				display: block;
				width: 100%;

				button {
					background: #202020;
					color: #fff !important;
					text-align: center;
					border-radius: 0px;
					margin-top: 15px;
					border: none;
					padding: 14px;
					font-weight: 600;
					font-family: inherit !important;
					-webkit-appearance: none;
					-moz-appearance: none;
					appearance: none;
					outline: none;
					cursor: pointer;
					width: 130px;
					font-size: 14px;
					border: 1px solid #202020 !important;
					@include t(all, 0.2s);
					float: left;
					margin-bottom: 15px;
					height: auto;
					position: relative;

					&.passive {
						pointer-events: none;
					}

					.loader {
						background: rgba(0, 0, 0, 0.88) !important;
					}

					&:hover {
						background: transparent !important;
						cursor: pointer;
						color: #202020 !important;
					}
				}
			}

			.item-information {
				padding: 20px 15px 15px 15px;
				margin: 0px 0px 10px 0px;
				border-top: 1px solid #dddddd;
				font-weight: 500;
				display: flex;
				font-size: 11px;
				font-style: italic;
				color: #464646;
				line-height: 15px;

				.checkbox {
					width: 30px;
				}

				.text {
					flex: 0 0 calc(100% - 30px);
					padding-left: 10px;
					max-width: calc(100% - 30px);
				}

				&:last-child {
					border-bottom: none;
				}

				.title {
					font-size: 13px;
					font-style: normal;
					margin-bottom: 5px;
				}

				font-size: 11px;
				font-style: italic;
				color: #464646;
				line-height: 15px;

				i {
					color: #5c5c5c;
					font-size: 15px;
					margin-top: 0px;
					display: inline-block;
					padding-bottom: 1px;
					padding-right: 2.5px;
					position: relative;
					top: 1px;
				}
			}

			.form-buttons {
				text-align: right;
				justify-content: flex-end;
				position: absolute;
				bottom: 0;
				padding: 15px;
				background: #eaeaea;
				width: 100%;
				left: 0;
				border-top: 1px solid #d9d9d9;

				button {
					margin-left: 5px;
					border: none;
					background: #ececec;
					border: 1px solid #d1d1d1;
					font-weight: 500;
					font-family: $font;
					padding: 5px 15px;
					font-size: 11.5px;
					cursor: pointer;
					@include t(all, 0.2s);

					&:hover {
						background: #d1d1d1;
					}

					&.primary {
						background: #3787e4;
						color: #fff;
						border: 1px solid #2d6ebb;

						&:hover {
							background: #2d6ebb;
						}
					}
				}
			}

			.forgot {
				a {
					font-weight: 500;
					font-size: 11px;
					color: #202020;
				}
			}
		}
	}

	.login-message {
		position: absolute;
		bottom: 0;
		left: 0;
		background: #ececec;
		width: 100%;
		padding: 12.5px 15px;
		font-size: 11px;
		text-align: center;
		font-weight: 600;
		opacity: 0;
		@include t(all, 0.15s);
		pointer-events: none;

		i {
			font-size: 15px;
			position: relative;
			top: 1px;
			margin-right: 5px;
		}

		&.success {
			background: #33aa5b;
			color: #ffffff;
		}

		&.error {
			background: #a54141;
			color: #ffffff;
		}

		&.active {
			opacity: 1;
		}
	}
}

.form-accordion {
	&>.title {
		background: #ffffff !important;
		padding: 10px;
		align-items: center;
		border: 1px solid #d7d5d5;
		font-weight: 600;
		cursor: pointer;

		&::selection {
			background: none;
		}

		&.passive {
			i {
				@include r(180deg);
			}
		}

		i {
			margin-left: auto;
			@include t(all, 0.25s);
		}
	}

	.accordion-content {
		background: #fff;
	}
}

.page-sidebar {
	padding-right: 0px;
	width: 300px;
	padding-left: 20px;
	margin-top: 15px;

	.bar-category {
		margin-bottom: 10px;

		&:first-child {
			position: sticky;
			top: 46px;
			z-index: 999;
		}

		.content {
			padding: 10px;
			background: #ffffff;
			border: 1px solid #d7d5d5;
			margin-top: -1px;

			.top-buttons {
				margin-bottom: 10px;

				.ms-Button {
					font-size: 11px;
					font-weight: 600;
					padding: 0px 5px;

					.ms-Button-label {
						font-weight: 600;
					}
				}

				.ms-Button:last-child {
					margin-left: auto;
				}
			}

			.item {
				font-size: 11px;
				align-items: center;
				margin-bottom: 6.5px;

				i {
					font-size: 13.5px;
					margin-right: 3.5px;
				}

				strong {
					margin-left: 5px;
					font-size: 11px;
					font-style: italic;
				}
			}

			.category-items {
				max-height: 200px;
				overflow-y: auto;

				&::-webkit-scrollbar {
					width: 4px;
				}

				&::-webkit-scrollbar-track {
					background: #eeeeee;
				}

				&::-webkit-scrollbar-thumb {
					background: #bbbbbb;
				}

				&::-webkit-input-placeholder {
					color: rgb(155, 155, 155);
				}

				.not-found {
					font-size: 11px;
					padding: 5px 0px;
					align-items: center;
					font-weight: 500;
					font-style: italic;

					i {
						padding-right: 3.5px;
					}
				}

				.category-item {
					border-bottom: 1px dotted #dadada;
					padding: 3px 0px;

					.ms-Checkbox-checkbox {
						width: 15px;
						height: 15px;
						margin-top: 2px;

						i {
							font-size: 9px;
						}
					}

					&:last-child {
						border-bottom: none;
					}

					.children {
						padding-left: 30px;
						width: 100%;
					}

					.collapse {
						cursor: pointer;
						font-size: 9px;
						background: #e8e8e8;
						height: 13px;
						width: 13px;
						display: flex;
						align-items: center;
						justify-content: center;
						margin-top: 3px;
						margin-right: 4px;
					}
				}
			}
		}

		.footer {
			background: #f5f5f5;
			padding: 10px;
			border: 1px solid #d7d5d5;
			margin-top: -1px;
			align-items: center;

			.trigger {
				font-size: 11px;
				color: #0078d4;
				border-bottom: 1px dotted #0078d4;
				cursor: pointer;
				margin-top: -1px;
				font-weight: 600;

				&.red {
					color: #cc4646;
					font-weight: 600;
					border-bottom: 1px dotted #cc4646;
				}
			}

			.ms-Button {
				font-size: 11px;
				height: 27.5px;
			}
		}

		.title {
			background: #fff;
			padding: 10px;
			align-items: center;
			border: 1px solid #d7d5d5;
			font-weight: 600;
			cursor: pointer;

			&.passive {
				i {
					@include r(180deg);
				}
			}

			i {
				margin-left: auto;
				@include t(all, 0.25s);
			}
		}
	}
}

body.designer-mode {
	.page-sidebar {
		display: none;
	}

	.absolute-wrapper {
		display: none;
	}

	.left {
		.ms-MessageBar {
			display: none;
		}

		.form-groups {
			display: none;
		}
	}

	.page {
		max-width: 100% !important;

		.page-title {
			display: none !important;
		}

		.designer {
			min-height: calc(100vh - 153px);
		}

		&>button {
			display: none !important;
		}
	}
}

.anchor-field {
	width: 100%;
	// background: #f8fcff;
	min-width: 800px;
	// .property-item:hover {
	// 	background: #eaf0f7 !important;
	// }
}

.designer {
	padding: 15px;
	background: #fff;
	margin-top: -1px;
	border: 1px solid #dfdfdf;

	.sortable-ghost {
		&:after {
			opacity: 1;
		}

		* {
			opacity: 0 !important;
			pointer-events: none !important;
		}

		background: #fff;
	}

	.designer-content {
		border: none !important;

		&>.droppable-area {
			overflow: hidden;
		}
	}

	&>.droppable-area {
		background: #fcfcfc !important;
		// border: 2px dotted #d6d6d6 !important;
		position: relative;
		min-height: 300px !important;
		padding: 0px !important;
	}

	.fa-Grid {
		background: #f7f7f7;
		margin: 0px !important;

		&.is-default {
			&>.droppable-area {
				min-height: 300px;
			}

			&>.component-header {
				width: auto;
				left: 0;
				text-align: center;
				justify-content: center;
				align-items: center;
				padding: 6.5px 15px;
				background: #ececec;
			}
		}

		// & > .droppable-area {
		//     flex-wrap: nowrap !important;
		//     > * {
		//         flex: 0 0 100px;
		//     }
		// }
	}

	.fa-Container {
		background: #fff;
		margin: 0px !important;
	}

	.fa-Layout-Container {
		margin: 0px !important;
		background: #fff;

		&>.blank-wrapper {
			position: absolute;
			background: transparent !important;
			min-height: 300px !important;

			.blank-box {
				position: absolute !important;
			}
		}
	}

	.fa-Slideshow {
		&>.droppable-area {
			display: flex;

			&>* {
				-ms-flex-preferred-size: 0;
				flex-basis: 0;
				-ms-flex-positive: 1;
				flex-grow: 1;
				max-width: 100%;
				z-index: 1;
			}
		}
	}

	.fa-Section,
	.fa-Animation,
	.fa-Container,
	.fa-Grid {
		@include t(all, .25s);

		&:hover {
			background: rgba(0, 120, 212, 0.05);

			>.fa-droppable-act {
				border: 1px dotted #88aeda !important;
			}
		}
	}

	.fa-Section,
	.fa-Slideshow,
	.fa-Scene {
		&>.blank-wrapper {
			position: absolute !important;
			background: transparent !important;
			min-height: 300px !important;

			.blank-box {
				position: absolute !important;
			}
		}

		&>.droppable-area {
			min-height: 300px !important;
		}
	}

	.fa-Col {
		margin: 0px !important;
		background: #fff;
		@include t(all, 0.25s);
		border: none;

		&.is-default {
			background: #f7f7f7;

			&>.blank-wrapper {
				position: absolute;
				background: transparent !important;
				min-height: 300px;

				.blank-box {
					position: absolute;
				}
			}

			&>.droppable-area {
				min-height: 300px !important;
			}

			&>.component-header {
				width: auto;
				left: 0;
				text-align: center;
				justify-content: center;
				align-items: center;
				padding: 6.5px 15px;
				background: #ececec;
				opacity: 1 !important;
			}

			&:hover {
				background: #f7f7f7;

				>.fa-droppable-act {
					border: 1px dotted rgba(187, 187, 187, 0.7) !important;
				}
			}
		}

		&:hover {
			background: rgba(0, 120, 212, 0.05);

			>.fa-droppable-act {
				border: 1px dotted #88aeda !important;
			}
		}

		.droppable-area {
			.component-header {
				opacity: 0.5;
				pointer-events: none;
			}
		}
	}

	div[role="tabpanel"] {
		background: #f9f9f9;
		border: 1px solid #e0e0e0;
		margin-top: -1px;
	}

	.designer-header {
		z-index: 999;
		margin-bottom: 10px;
		position: sticky;
		top: 45px;
		background: #ffff;

		.designer-mode-indicator {
			position: absolute;
			right: 0;
			top: 0;
			align-items: center;
			background: #f9f9f9;
			padding: 10px;
			font-size: 10.5px;
			font-weight: 500;
			cursor: pointer;

			i {
				padding-right: 5px;
			}
		}

		.ms-Button {
			margin-right: 0px;
			font-size: 12.3px;
			font-weight: 600;
			border: 1px solid transparent;
			border-bottom: none;
			color: #0078d4;
			height: 37px;

			&.is-selected {
				border: 1px solid #e0e0e0;
				border-bottom: none;
				color: #000;
				background: #f9f9f9;
			}

			&::before {
				display: none;
			}
		}
	}

	.designer-components {
		padding: 10px 10px 0px 10px;

		.item {
			cursor: pointer;
			text-align: center;
			padding-right: 10px;
			border-right: 1px dotted #d3d1d1;
			margin-right: 10px;
			margin-bottom: 10px;
			width: 62px;

			&:last-child {
				border-right: none;
			}

			&::selection {
				background: transparent;
			}

			.icon {
				text-align: center;

				&::selection {
					background: transparent;
				}

				img {
					width: 42.5px;
					filter: brightness(0.6);

					&::selection {
						background: transparent;
					}
				}
			}

			.text {
				font-size: 10px;
				font-weight: 600;
				margin-top: 5px;
				min-height: 23px;
				display: flex;
				align-items: center;
				justify-content: center;
				color: #505050;
				font-weight: 700;

				&::selection {
					background: transparent;
				}
			}
		}
	}
}

.droppable-area {
	padding: 5px;
	position: relative;
	z-index: 1;
	margin-top: -3px;
	flex-wrap: wrap;
	border: 1px dotted rgba(187, 187, 187, 0.7);

	&:first-child {
		margin-top: 0px;
	}

	// .fa-droppable-row,
	// [class*="col-"].fa-draggable .droppable-area {
	// 	border: 1px dotted rgba(136, 136, 136, 0.7);
	// }
	// .fa-draggable, .fa-draggable.designing {

	// }
	.fa-sortable-item {
		width: 100% !important;
		height: 75px;
		display: block;
		flex: 0 0 100%;
		max-width: 100%;
		border: 1px dashed #d1d1d1;
		background: rgb(245, 252, 248);
		@include t(all, 0.25s);

		&.size-1 {
			-ms-flex: 0 0 8.333333%;
			flex: 0 0 8.333333%;
			max-width: 8.333333%;
			height: 120px;
		}

		&.size-2 {
			-ms-flex: 0 0 16.666667%;
			flex: 0 0 16.666667%;
			max-width: 16.666667%;
			height: 120px;
		}

		&.size-3 {
			-ms-flex: 0 0 25%;
			flex: 0 0 25%;
			max-width: 25%;
			height: 120px;
		}

		&.size-4 {
			-ms-flex: 0 0 33.333333%;
			flex: 0 0 33.333333%;
			max-width: 33.333333%;
			height: 120px;
		}

		&.size-5 {
			-ms-flex: 0 0 41.666667%;
			flex: 0 0 41.666667%;
			max-width: 41.666667%;
			height: 120px;
		}

		&.size-6 {
			-ms-flex: 0 0 50%;
			flex: 0 0 50%;
			max-width: 50%;
			height: 120px;
		}

		&.size-7 {
			-ms-flex: 0 0 58.333333%;
			flex: 0 0 58.333333%;
			max-width: 58.333333%;
			height: 120px;
		}

		&.size-8 {
			-ms-flex: 0 0 66.666667%;
			flex: 0 0 66.666667%;
			max-width: 66.666667%;
			height: 120px;
		}

		&.size-9 {
			-ms-flex: 0 0 75%;
			flex: 0 0 75%;
			max-width: 75%;
			height: 120px;
		}

		&.size-10 {
			-ms-flex: 0 0 83.333333%;
			flex: 0 0 83.333333%;
			max-width: 83.333333%;
			height: 120px;
		}

		&.size-11 {
			-ms-flex: 0 0 91.666667%;
			flex: 0 0 91.666667%;
			max-width: 91.666667%;
			height: 120px;
		}

		&.size-12 {
			-ms-flex: 0 0 100%;
			flex: 0 0 100%;
			max-width: 100%;
			height: 120px;
		}
	}

	.fa-sortable-item {
		width: 100%;
		height: 75px;
		display: block;
		flex: 0 0 100%;
		max-width: 100%;
		border: 1px dashed #d1d1d1;
		background: rgb(245, 252, 248);
		@include t(all, 0.25s);

		&.size-1 {
			-ms-flex: 0 0 8.333333%;
			flex: 0 0 8.333333%;
			max-width: 8.333333%;
			height: 120px;
		}

		&.size-2 {
			-ms-flex: 0 0 16.666667%;
			flex: 0 0 16.666667%;
			max-width: 16.666667%;
			height: 120px;
		}

		&.size-3 {
			-ms-flex: 0 0 25%;
			flex: 0 0 25%;
			max-width: 25%;
			height: 120px;
		}

		&.size-4 {
			-ms-flex: 0 0 33.333333%;
			flex: 0 0 33.333333%;
			max-width: 33.333333%;
			height: 120px;
		}

		&.size-5 {
			-ms-flex: 0 0 41.666667%;
			flex: 0 0 41.666667%;
			max-width: 41.666667%;
			height: 120px;
		}

		&.size-6 {
			-ms-flex: 0 0 50%;
			flex: 0 0 50%;
			max-width: 50%;
			height: 120px;
		}

		&.size-7 {
			-ms-flex: 0 0 58.333333%;
			flex: 0 0 58.333333%;
			max-width: 58.333333%;
			height: 120px;
		}

		&.size-8 {
			-ms-flex: 0 0 66.666667%;
			flex: 0 0 66.666667%;
			max-width: 66.666667%;
			height: 120px;
		}

		&.size-9 {
			-ms-flex: 0 0 75%;
			flex: 0 0 75%;
			max-width: 75%;
			height: 120px;
		}

		&.size-10 {
			-ms-flex: 0 0 83.333333%;
			flex: 0 0 83.333333%;
			max-width: 83.333333%;
			height: 120px;
		}

		&.size-11 {
			-ms-flex: 0 0 91.666667%;
			flex: 0 0 91.666667%;
			max-width: 91.666667%;
			height: 120px;
		}

		&.size-12 {
			-ms-flex: 0 0 100%;
			flex: 0 0 100%;
			max-width: 100%;
			height: 120px;
		}
	}
}

.fa-draggable {
	position: relative;
	border: 1px solid #ececec;
	border-top: none;

	*::selection {
		background: transparent !important;
	}

	&:after {
		position: absolute;
		content: "";
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		border: 1px dashed #d1d1d1;
		background: rgb(245, 252, 248);
		@include t(all, 0.25s);
		opacity: 0;
	}

	&.designing {
		margin: 0px !important;
		padding: 32px 0px 0px 0px !important;
		display: block;
		width: 100%;
		margin: 5px 0px !important;

		&:hover {
			&>.component-header {
				opacity: 1;
				pointer-events: all;
			}
		}

		background: #fff;

		.blank-wrapper {
			background: #ececec;
			position: relative;
			z-index: 1;
			background: transparent;
			min-height: 100px;

			.blank-box {
				position: relative;
				padding: 12px 10px;

				>i {
					font-size: 25px;
				}
			}
		}
	}
}

// .fa-draggable.is-default {
// 	.droppable-area {
// 		padding: 0px;
// 	}
// }

.blank-wrapper {
	min-height: 288px;
	width: 100%;
	position: absolute;
	top: 0;
	z-index: -1;
	cursor: move;

	&::selection {
		background: transparent;

		* {
			background: transparent;
		}
	}
}

.blank-box {
	position: absolute;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100%;
	display: flex;
	width: 100%;
	opacity: 0.4;
	left: 0;
	pointer-events: none;
	top: 0;
	z-index: -1;
	text-align: center;

	&>i {
		font-size: 30px;
		color: #a3a3a3;
		font-weight: 300;
		text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.55);
		margin-bottom: 10px;

		&::selection {
			background: transparent;
		}
	}

	&>img {
		width: 30px;
		margin-bottom: 10px;
	}

	.title {
		font-size: 14px;
		font-weight: 600;
		color: #444444;

		&::selection {
			background: transparent;
		}
	}

	.sub-text {
		font-size: 10px;
		font-weight: 400;
		color: #656565;
		margin-top: 1.5px;

		&::selection {
			background: transparent;
		}
	}
}

.fa-sortable-item.sortable-ghost {
	width: 25px;
}

.component-header {
	padding: 6.5px;
	background: #f5f5f5;
	border: 1px solid #d7d5d5;
	margin-bottom: -1px;
	top: 0;
	z-index: 9;
	width: 100%;
	position: absolute;
	align-items: center;
	cursor: move;
	@include t(all, 0.3s);

	span {
		margin-right: 13px;
		cursor: pointer;
		font-size: 11px;

		i.icon-arrowleft {
			font-size: 16px;
		}

		&.schema-copy {
			background: #569c8c;
			color: #fff;
			font-size: 9px;
			padding: 4px 7px;
		}

		&.schema-paste {
			font-size: 9px;
			padding: 4px 0px;
			border-right: 1px solid #d4d4d4;
			padding-right: 10px;
		}
	}

	.title {
		margin-left: auto;
		font-size: 11px;
		font-weight: 600;
		font-style: italic;
	}
}

.custom-component {
	width: 100%;
	display: block;
	position: relative;
	padding: 15px;
	padding-top: 50px;
}

/** Accordion **/

.fa-Accordion {
	font-size: 11.5px;

	&.tree {
		.collapse.caret-down {
			display: none;
		}

		.inner {
			padding-left: 2.5px;
		}

		.m-node {
			.children {
				background: rgba(255, 255, 255, 0.07);
			}
		}

		.fa-Accordion-item {
			.title {
				&:before {
					width: 3px;
					background: #ececec;
					height: 3px;
					border: 0;
					border-radius: 100%;
					margin-top: -1.5px;
				}
			}
		}
	}

	.fa-Accordion-item {
		font-size: 0.97em;
		border-bottom: 1px solid #494949;

		&:last-child {
			border-bottom: none;
		}

		&>.title {
			padding: 4px 27.5px;
			color: #e6e6e6;
			position: relative;
			align-items: center;
			cursor: pointer;
			font-weight: 600;

			.text {
				white-space: nowrap;
				overflow: hidden;
				display: block;
				text-overflow: ellipsis;
				max-width: 230px;
				margin-left: 10px;

				&::selection {
					background: transparent;
				}
			}

			.remove-accordion {
				font-size: 9px;
				margin-top: 1px;
				height: 16px;
				width: 14px;
				display: flex;
				align-items: center;
				justify-content: center;

				&::selection {
					background: transparent;
				}
			}

			.info-accordion {
				font-size: 11px;
				margin-top: 2px;
				margin-right: 5px;

				&::selection {
					background: transparent;
				}
			}

			&::selection {
				background: transparent;
			}

			&:before {
				position: absolute;
				width: 0;
				content: "";
				left: 15px;
				top: 50%;
				margin-top: -3.5px;
				height: 0;
				border-top: 4px solid transparent;
				border-bottom: 4px solid transparent;
				border-left: 4px solid #e6e6e6;
				@include t(all, 0.25s);
			}
		}

		&.active {
			&> {
				.title {
					&:before {
						@include r(90deg);
					}
				}

				.content {
					display: flex;
				}
			}

			&.block {
				&> {
					.content {
						display: block !important;
					}
				}
			}
		}

		.content {
			display: none;
			margin: 2.5px 0px 0px 0px;
			background: $light-color;
		}

		.info {
			position: absolute;
			top: 3px;
			right: 4px;
			color: rgb(168, 168, 168);
			@include t(all, 0.25s);
			cursor: pointer;

			&:hover {
				.text {
					opacity: 1;
				}
			}

			.text {
				opacity: 0;
				position: absolute;
				pointer-events: none;
				width: 250px;
				background: #2a2a2a;
				z-index: 9;
				padding: 5px 10px;
				color: #dadada;
				bottom: 20px;
				text-align: left;
				font-size: 9.5px;
				@include t(all, 0.25s);
			}
		}
	}
}

.icon-panel {
	position: absolute;
	width: 320px;
	min-width: 320px;
	background: #2d2d2d;
	border-right: 1px solid #4e4e4e;
	box-shadow: -10px 0 3px -10px rgba(0, 0, 0, 0.1);
	overflow-y: auto;
	max-height: 500px;
	z-index: 999;
	display: none;
	margin-top: 10px;
	left: 0;
	top: -100px;

	&.fixed {
		right: 550px;
	}

	&.callout {
		right: 270px;
		top: -89px;
	}

	&.fade {

		&::-webkit-scrollbar,
		.right-panel .right-panel-wrapper .panel-inner::-webkit-scrollbar {
			display: none;
		}

		.property-item {
			opacity: 0;
			pointer-events: none;
		}
	}

	&::-webkit-scrollbar {
		width: 5px;
	}

	&::-webkit-scrollbar-track {
		background: #888;
	}

	&::-webkit-scrollbar-thumb {
		background: rgb(70, 70, 70);
	}

	&.active {
		display: block;
	}

	.property-item {
		@include t(all, 0.15s);
	}

	&>.panel-head {
		background: $dark-color  !important;
		color: #fff;
		align-items: center;
		padding: 12px 15px !important;
		position: sticky;
		top: 0;
		z-index: 1;

		.title {
			font-size: 14px;
			font-weight: 700;

			.sub-title {
				color: #e2e2e2;
				background: transparent;
				border: none;
				width: 100%;
				display: block;
				font-size: 8.5px;
				font-weight: 400;
			}
		}

		.close {
			color: #e2e2e2;
			margin-left: auto;
			font-size: 13px;
			cursor: pointer;
		}
	}

	.fa-Accordion-item {
		&>.title {
			background: #333333;
		}

		.content {
			margin-top: 0px;
		}
	}

	.icon-list {
		.item {
			color: #ececec;
			padding: 9.5px 5px;
			display: flex;
			align-items: center;
			justify-content: center;
			border: 1px solid #434343;
			font-size: 15px;
			margin: 1.5px 0px;
			cursor: pointer;
			@include t(all, 0.15s);
			max-width: calc(16.66% - 10px);
			margin: 1.5px 0 1px 2.3px;

			&:hover {
				background: $color;
			}
		}
	}
}

/** Property Container **/
.property-container {
	.property-list {
		&.source {
			background: #f6f6f6;
			margin-top: -15px;
			margin-left: -15px;
			width: calc(100% + 30px);
			margin-bottom: 14px;
			border-bottom: 1px solid #dedede;
			padding: 0px 15px;
		}
	}
}

.property-item {
	border-top: 1px dashed #e1e1e1;
	padding: 10px;
	align-items: center;
	@include t(all, 0.25s);

	.component-binding-description {
		font-size: 11px;
		font-weight: 600;
		background: #fff;
		padding: 8px 10px;
		border: 1px solid #d3d3d3;

		i {
			padding-right: 5px;
		}
	}

	.ms-ChoiceFieldGroup-flexContainer {
		display: flex;

		.ms-ChoiceField {
			margin-right: 10px;
			margin-left: 2px;
			font-size: 11px;

			.ms-ChoiceFieldLabel {
				padding-top: 1.5px;
			}
		}
	}

	// .ms-ChoiceFieldGroup {
	// 	align-items: center;
	// 	flex-direction: row;
	// 	.ms-ChoiceField-iconWrapper {
	// 		font-size: 15px;
	// 		padding: 0px;
	// 		width: 0px;
	// 	}
	// 	.ms-ChoiceField-field {
	// 		flex-direction: row;
	// 		padding: 0px;
	// 		&:before {
	// 			width: 15px;
	// 			height: 15px;
	// 			font-weight: normal;
	// 			position: absolute;
	// 			top: 7px;
	// 			right: 10px !important;
	// 		}
	// 		&:after {
	// 			top: 10px;
	// 			right: 13px !important;
	// 			width: 6px;
	// 			height: 6px;
	// 			border-width: 4.5px;
	// 		}
	// 	}
	// 	.ms-ChoiceFieldLabel {
	// 		margin-left: -27px;
	// 		top: -1px;
	// 		position: relative;
	// 	}
	// 	.ms-ChoiceField-innerField {
	// 		padding: 0px 10px;
	// 	}
	// 	.ms-ChoiceField-labelWrapper {
	// 		height: auto;
	// 		padding: 7px;
	// 		font-size: 11px;
	// 		font-weight: 500;
	// 		min-width: 91px;
	// 		margin: 0px;
	// 		margin-top: -2px;
	// 		padding: 0px 23px;
	// 	}
	// }
	&:hover {
		background: #ebf7ff !important;
	}

	&:first-child {
		border-top: none;
	}

	.property-text {
		-ms-flex-preferred-size: 0;
		flex-basis: 0;
		-ms-flex-positive: 1;
		flex-grow: 1;
		// max-width: 100%;
		max-width: 300px;
		align-self: flex-start;

		.title {
			font-weight: 600;
			font-size: 13px;
		}

		.sub-title {
			font-size: 11px;
		}
	}

	button {
		font-size: 11px;
	}

	.property-image {
		position: relative;
		cursor: pointer;
		max-width: 130px;
		margin-bottom: 2.5px;

		&::selection {
			background: transparent;
		}

		img {
			&::selection {
				background: transparent;
			}
		}

		&:before {
			position: absolute;
			content: "";
			background: rgb(0, 0, 0);
			background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
			width: 100%;
			opacity: 0.8;
			height: 100%;
			pointer-events: none;
		}

		.remove {
			position: absolute;
			right: 7.5px;
			top: 7.5px;
			color: #fff;
			cursor: pointer;
		}
	}

	.property-video {
		position: relative;
		background: rgba(64, 64, 64, 0.68);
		color: rgb(233, 233, 233);
		border: 1px dotted $dark-color;
		padding: 4px 7.5px;
		display: flex;
		line-height: 12.5px;
		font-size: 10px;
		align-items: center;
		margin: 2.5px 0px;

		.remove {
			cursor: pointer;
			margin-left: auto;
		}
	}

	.property-alignment {
		button {
			font-size: 11px;
			border: 1px solid #d3d3d3;
			background: rgba(255, 255, 255, 0.68);
			color: #000000;
			padding: 3.5px 7.5px;
			font-weight: 700;
			cursor: pointer;
			margin-right: -1px;
			-webkit-transition: all 0.1s;
			-moz-transition: all 0.1s;
			-o-transition: all 0.1s;
			transition: all 0.1s;
			margin-right: 2px;

			&.active {
				background: rgb(80, 152, 123);
				border: 1px solid rgb(80, 152, 123);
				color: #fff;
			}
		}
	}
}

.ms-Dialog.confirm {
	@include t(all, 0s);

	.ms-Dialog-title {
		font-size: 17px;
	}

	.ms-Dialog-subText {
		font-size: 13px;
		line-height: 16px;
		margin-top: -10px;
	}

	.ms-Button {
		font-size: 13px;
	}

	.ms-Dialog-main {
		min-height: 110px !important;
	}

	.ms-Dialog-actions {
		.remove {
			font-size: 11.5px;
			margin-right: auto;
			float: left;
			color: #b73737;
			font-weight: 500;
			cursor: pointer;
			text-decoration: underline;
			margin-top: 3px;
		}
	}
}

.contributor-list {
	.custom-combobox {
		padding: 0px 5px;
	}

	>.ms-Button {
		font-size: 11px;
		margin: 5px;
	}

	.contributor-item {
		&>.remove {
			cursor: pointer;
			align-self: flex-end;
			font-size: 14px;
			padding: 6.5px 10px;
			opacity: 0;
			pointer-events: none;
			color: #cc5d5d;

			&.active {
				opacity: 1;
				pointer-events: auto;
			}
		}
	}
}

.meta-constant-list {
	margin-top: 3px;
	margin-left: -3px;
	margin-right: -5px;

	.remove {
		margin-left: auto;
		font-size: 10px;
		cursor: pointer;
	}

	.meta-item {
		padding: 2.5px;
		flex: 0 0 14.22%;
		-ms-flex: 0 0 14.22%;
		max-width: 14.22%;

		&.large {
			flex: 0 0 20%;
			-ms-flex: 0 0 20%;
			max-width: 20%;
		}

		&.big {
			flex: 0 0 33%;
			-ms-flex: 0 0 33%;
			max-width: 33%;
		}

		&.green {
			.meta-item-wrapper {
				background: #e6f3ee;
				color: #323232;
				border: 1px solid #cee0d9;

				i {
					color: #33926e;
				}
			}
		}

		&.orange {
			.meta-item-wrapper {
				background: #f7f7f7;
				color: #323232;
				border: 1px solid #e8e2de;

				i {
					color: #da5c14;
				}

				.sub-text {
					font-weight: 500;
				}
			}
		}

		.meta-item-wrapper {
			background: #f3f3f3;
			border: 1px solid #00000012;
			align-items: center;
			padding: 8.5px 5px;
			@include t(all, 0.25s);
			cursor: pointer;

			.icon {
				position: relative;
				width: 30px;
			}

			.default {
				position: relative;
			}

			.checkmark {
				position: absolute;
				left: 5px;
				top: 1px;
				width: 18px;
				height: 18px;
				border-radius: 100%;
				text-align: center;
				line-height: 18px;
				background: #fff;
				color: #33926e;
				font-size: 9px;
				@include t(all, 0.25s);
				opacity: 0;
				@include s(0);
				transform-origin: center;

				i {
					color: #33926e !important;
					padding: 0px;
				}
			}

			&.active {
				background: #33926e;
				color: #fff;

				.default {
					opacity: 0;
					@include s(0);
				}

				.checkmark {
					opacity: 1;
					@include s(1);
				}

				i {
					color: #fff;
				}

				.text {
					color: #fff;
				}
			}

			i {
				padding: 0px 3.5px;
			}

			.badge {
				font-size: 8px;
				display: inline-flex;
				padding: 2px 5px;
				margin-left: 7px;
				background: #cc5e5b;
				color: #fff;
				align-items: center;
				position: relative;
				top: -1.5px;
			}

			.text {
				font-weight: 600;
				font-size: 11px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				display: inline-block;
				width: calc(100% - 55px);
			}

			.sub-text {
				font-weight: 600;
				font-size: 10px;
			}
		}
	}
}

.thumbnail-bar {
	button {
		font-size: 11px;
	}

	.property-image {
		position: relative;
		cursor: pointer;
		margin-bottom: 2.5px;
		max-width: 100%;

		&::selection {
			background: transparent;
		}

		img {
			&::selection {
				background: transparent;
			}
		}

		&:before {
			position: absolute;
			content: "";
			width: 100%;
			opacity: 0.8;
			height: 100%;
			pointer-events: none;
		}

		.remove {
			background: #cc4646;
			color: #fff;
			padding: 3.5px 7.5px;
			cursor: pointer;
			font-size: 10px;
			right: 5px;
			top: 5px;
			position: absolute;
		}
	}
}

.ms-Button {
	&.custom-button {
		font-size: 13px !important;

		&.is-disabled {
			background: #efefef !important;
			border: 1px solid #ececec;
			color: #989393cc;
		}

		&.custom-button-green {
			background: $green-color;
			border: 1px solid #268456;

			&:hover {
				background-color: #268456;
			}

			&.is-disabled {
				background: #eeeeee;
				color: #ccc;
				border: 1px solid #eeeeee;
			}
		}

		&.custom-button-yellow {
			background: #e88012;
			border: 1px solid #da7810;

			&:hover {
				background-color: #da7810;
			}

			&.is-disabled {
				background: #eeeeee;
				color: #ccc;
				border: 1px solid #eeeeee;
			}
		}

		&.custom-button-red {
			background: #ad5e5e;
			border: 1px solid #a25555;

			&:hover {
				background-color: #a25555;
			}
		}
	}
}

.fa-Cron-builder {
	.fa-Cron-tabs {
		padding: 0px 12px;
		background: #ffffff;
		border-bottom: 1px solid #eaeaea;
		margin-left: -15px;
		width: calc(100% + 30px);
		margin-top: -15px;

		.fa-Cron-tab {
			padding: 11.5px 20px;
			font-weight: 500;
			font-size: 11px;
			cursor: pointer;
			@include t(all, 0.15s);
			opacity: 0.7;
			border-bottom: 2px solid transparent;

			&.active {
				border-bottom: 2px solid rgb(16, 110, 190);
				opacity: 1;
			}
		}
	}

	.fa-Cron-tabContent {
		padding: 10px;
		background: #fbfbfb;
		margin-left: -15px;
		width: calc(100% + 30px);
		margin-bottom: -15px;
	}

	.fa-Cron-option {
		padding: 10px;
		cursor: pointer;
		background: #f7f7f7;
		margin: 5px 0;
		align-items: center;
		opacity: 0.6;
		@include t(all, 0.15s);
		border: 1px solid #ececec;

		* {
			pointer-events: none;
		}

		&.active {
			background: #e8f7f1;
			border: 1px solid #cfe6dd;
			opacity: 1;

			.icon {
				background: #32966e;
				color: #fff;

				i {
					color: #fff;
				}
			}

			* {
				pointer-events: auto;
			}
		}
	}

	.fa-Cron-tabContent-wrapper {
		.icon {
			background: #dadada;
			width: 17px;
			height: 17px;
			text-align: center;
			line-height: 17px;
			border-radius: 100%;
			font-size: 11px;
			@include t(all, 0.15s);

			i {
				transform-origin: center;
				display: block;
				line-height: 17px;
				color: #676767;
				font-size: 13px;
				font-size: 9px;
				@include t(all, 0.15s);
			}
		}

		.text {
			font-size: 11px;
			font-weight: 500;
			padding: 0px 5px;
		}

		.form-item {
			.custom-combobox.multi {
				.ms-ComboBox {
					max-width: 100% !important;
				}
			}

			.custom-combobox.auto-width {
				.ms-ComboBox {
					max-width: 100% !important;
				}
			}

			.ms-ComboBox {
				max-width: 60px;
				height: 27px;

				&:after {
					border-color: #d4d4d4 !important;
				}

				input {
					height: 27px;
					line-height: 27px;
				}

				button {
					height: 27px;
					line-height: 27px;
				}
			}
		}
	}
}

.schedule-picker {
	.minute {
		margin-left: -1px;
	}

	.day {
		margin-right: -1px;

		.ms-Label {
			margin-bottom: 1px !important;
		}
	}
}

.schema-genarator {
	padding: 15px;

	.title {
		font-weight: 500;
		font-size: 13px;
	}

	.text {
		font-size: 11px;
		margin-trim: -1px;
	}

	.react-monaco-editor-container {
		margin-top: 10px;
	}

	.react-monaco-editor-container .overflow-guard {
		border: 1px solid #a1a1a1;
		padding-top: 10px;
	}
}

.data-wizard-field {
	.filled {
		font-size: 10px;
		padding: 6px 10px;
		background: #50987b;
		color: #fff;
		margin-left: 5px;
		align-items: center;
		font-weight: 600;

		i {
			margin-right: 5px;
		}

		.query-id {
			margin-left: 5px;
			font-style: italic;
			cursor: pointer;
		}
	}
}

.data-wizard-content {
	.property-item:hover {
		background: transparent !important;
	}

	.property-item {
		@include t(all, 0s);
	}

	.checkbox-group {
		.ms-Checkbox {
			margin-left: 0.5px;
			margin-right: 13.5px;

			.ms-Checkbox-text {
				font-size: 11px;
			}

			.ms-Checkbox-checkbox {
				width: 17px;
				height: 17px;
				margin-top: 2px;
				margin-right: 1px;

				i {
					font-size: 11px;
				}
			}
		}
	}

	.sites-mapping {
		.form-accordion {
			margin-bottom: 11px;
			margin-top: 7px;

			.title {
				background: #fcfcfc !important;
				font-size: 12.5px !important;
			}

			.accordion-content {
				background: #fcfcfc;
				padding: 12px 0px;
				border: 1px solid #d7d5d5;
				border-top: none;
			}
		}
	}
}

.wizard-item {
	margin-bottom: 10px;

	&.mapping-list {
		.wizard-item-content {
			margin-top: -2px !important;
		}

		.mapping {
			border-bottom: 1px dotted #c7c7c7;
			padding: 7.5px 0px;

			&:last-child {
				border-bottom: none;
			}

			.remove {
				cursor: pointer;
				padding: 5px;
				font-size: 11px;
				align-self: center;
				margin-top: 27px;
				display: block;
				opacity: 0;
				pointer-events: none;

				&.active {
					opacity: 1;
					pointer-events: auto;
				}
			}
		}
	}

	&>.title {
		padding: 10px;
		border-bottom: 1px dotted #d6d6d6;
		font-weight: 600;
		font-size: 13px;
		background: #f8f8f8;
		padding: 12px;
		position: relative;

		.text {
			font-weight: 400;
			font-size: 11px;
		}

		.all {
			margin-left: auto;
			color: #0078d4;
			font-size: 11px;
			text-decoration: underline;
			cursor: pointer;
		}
	}

	.wizard-item-content {
		.fa-Cron-builder {
			.fa-Cron-tabs {
				margin-left: 0px;
				width: 100%;
				margin-top: 0px;
			}

			.fa-Cron-tabContent {
				margin-left: 0px;
				width: 100%;
			}
		}

		&>.title {
			font-size: 13px;
			font-weight: 600;
			margin-top: 15px;
			border-bottom: 1px solid #ececec;
			padding-bottom: 10px;
		}

		&.site-list {
			display: flex;
			margin-top: 5px;
			padding: 5px;

			.ms-Checkbox-label {
				padding: 2.5px 0px;
				width: 100%;
			}

			.ms-Checkbox-text {
				font-size: 11px;
				font-weight: 500;
			}

			.ms-Checkbox-checkbox {
				width: 15px;
				height: 15px;
				margin-top: 3px;
				margin-right: 2px;
				font-size: 10px;
			}

			.ms-Checkbox {
				padding: 0px;
				width: 100%;
				max-width: 10%;
				flex: 10%;
			}
		}
	}
}

.rdw-editor-main {
	border: 1px solid #ccc !important;
	padding: 5px 10px !important;
	border-radius: 2px !important;
	width: 100% !important;
	min-height: 200px;
	background: #fff;
}

.rdw-option-wrapper,
.rdw-dropdown-wrapper {
	border: 1px solid #ccc !important;
}

.rdw-editor-toolbar {
	border: 1px solid #ccc !important;
	font-size: 11px !important;

	.rdw-option-wrapper {
		img {
			width: 12px;
		}
	}
}

.custom-label {
	margin-top: 10px;
	color: #323130;
	font-size: 11px !important;
	font-weight: 500 !important;
	font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto,
		"Helvetica Neue", sans-serif;
}

.specLabel {
	font-weight: bold !important;
	margin-left: 5px;
}

.chooice-group {
	display: flex;

	.chooice-item {
		background: #e7f7ef;
		border: 1px solid #00000024;
		align-items: center;
		padding: 8.5px 5px;
		-webkit-transition: all .25s;
		-moz-transition: all .25s;
		-o-transition: all .25s;
		transition: all .25s;
		cursor: pointer;
		min-width: 350px;
		margin-right: 5px;
		color: #0f0f0f;
		position: relative;

		&.passive {
			background: #ffffff;

			.brand {
				background: #e87109;
			}

			&:hover {
				background: #ececec;
			}
		}

		.brand {
			position: absolute;
			right: 5px;
			top: 5px;
			background: #50987b;
			padding: 5px 15px;
			border-radius: 20px;
			color: #fff;
			font-weight: bold;
			font-size: 9px;
		}

		&:hover {
			background: #cfebdd;
		}

		*::selection {
			background: transparent !important;
		}

		.icon {
			position: relative;
			font-size: 20px;
			width: 36px;
		}

		.default {
			position: relative;
		}

		.checkmark {
			position: absolute;
			left: 5px;
			top: 1px;
			width: 18px;
			height: 18px;
			border-radius: 100%;
			text-align: center;
			line-height: 18px;
			background: #fff;
			color: #33926e;
			font-size: 9px;
			@include t(all, 0.25s);
			opacity: 0;
			@include s(0);
			transform-origin: center;

			i {
				color: #33926e !important;
				padding: 0px;
			}
		}

		&.active {
			background: #33926e;
			color: #fff;

			.default {
				opacity: 0;
				@include s(0);
			}

			.checkmark {
				opacity: 1;
				@include s(1);
			}

			i {
				color: #fff;
			}

			.text {
				color: #fff;
			}
		}

		i {
			padding: 0px 7.5px;
		}

		.text {
			font-weight: 600;
			font-size: 12px;
		}

		.sub-text {
			font-weight: 600;
			font-size: 10px;
			max-width: 260px;
			padding: 0px 10px 0px 0px;
		}
	}
}

.file-list {
	background: #fff;
	border: 1px dotted #c2c2c2;
	padding: 10px;
	font-size: 11px;
	width: 100%;
	margin-top: 5px;
	font-weight: 500;

	&.multi {
		.file-item-wrapper {
			width: 100%;
		}

		.file-item {
			padding: 2.5px;
			max-width: 11%;
			flex: 0 0 11%;
			-ms-flex: 0 0 11%;
		}

		.edit {
			display: block;
			padding: 5px;
			text-align: center;
			background: #42969e;
			font-size: 9px;
			font-weight: 700;
			cursor: pointer;
			color: #fff;
			margin-bottom: -3px;

			i {
				padding-right: 3.5px;
				font-size: 10px;
			}
		}
	}

	.file-item {
		width: 100%;
	}

	.not-found {
		width: 100%;
		align-items: center;

		i {
			padding-right: 5px;
		}
	}

	.file-item-wrapper {
		position: relative;
		width: 50%;

		.title {
			width: 100%;
			background: #e6e6e6;
			padding: 5px;
			font-size: 10px;
			color: #2d2d2d;
			text-align: center;
			margin-top: -3px;
			font-style: italic;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			display: inline-block;
		}

		.remove {
			background: #cc4646;
			color: #fff;
			padding: 3.5px 7.5px;
			cursor: pointer;
			font-size: 10px;
			right: 5px;
			top: 5px;
			position: absolute;
		}

		.edit {
			background: #0078d4;
			color: #fff;
			padding: 3.5px 5px;
			cursor: pointer;
			font-size: 9px;
			left: 5px;
			top: 5px;
			position: absolute;
		}

		img {
			height: auto;
			width: 100%;
			border: 1px solid #ececec;
		}
	}
}

.DayPickerInput {
	width: 100%;

	input {
		width: 100%;
		border: none;
		height: 38px;
		border-radius: 0px;
		-webkit-border-radius: 0px;
		-moz-border-radius: 0px;
		-o-border-radius: 0px;
		-webkit-appearance: none;
		font-size: 13px;
		padding: 5px;
		font-family: $font;
		background: #fff;
		border: 1px solid rgb(208, 208, 208);
		@include t(all, 0.1s);
	}
}

.DayPickerInput-OverlayWrapper {
	z-index: 999999;
}

.DayPicker-Months {
	font-size: 11px;
	color: #000;
	z-index: 99999;
}

.InputFromTo .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
	background-color: #f0f8ff !important;
	color: #4a90e2;
}

.InputFromTo .DayPicker-Day {
	border-radius: 0 !important;
}

.InputFromTo .DayPicker-Day--start {
	border-top-left-radius: 50% !important;
	border-bottom-left-radius: 50% !important;
}

.InputFromTo .DayPicker-Day--end {
	border-top-right-radius: 50% !important;
	border-bottom-right-radius: 50% !important;
}

.InputFromTo .DayPickerInput-Overlay {
	width: 420px;
	margin-left: -475px;
}

.external-data-panel {
	width: calc(100% - 25px);

	.external-items-head {
		&>.property-item {
			background: #f0fbf7 !important;
			border: 1px dotted #b3b4b4 !important;
		}
	}

	.property-item {
		width: 100%;
	}

	.parent-object-field {
		width: 100%;

		.content {
			background: #fff;
			border: none;
			margin-bottom: 8px;
			// .property-item {
			// 	border-bottom: none !important;
			// }
		}
	}

	.external-data-panel {
		padding: 20px;
		background: #ebebeb;
		width: 100%;
	}

	.external-item {
		background: #f8f8f8;
		margin: 5px 0;
		border: 1px solid #ececec;

		.remove {
			margin-top: 0px;
			display: inline-flex;
			align-items: center;
			background: #a44444;
			color: #fff;
			font-size: 10px;
			cursor: pointer;
			padding: 5px 7.5px;
			margin: 10px;
			border-top: 1px solid #ececec;

			i {
				padding-right: 5px;
				font-size: 8px;
			}
		}

		.property-item {
			transition: all, 0s !important;
			border-bottom: 1px dashed #e1e1e1 !important;

			&.border-none {
				border-bottom: none !important;
			}

			&:last-child {
				margin-bottom: 8px;
			}
		}
	}

	.external-items {
		&>.fa-Accordion {
			.property-list {
				width: 100%;
			}

			.content {
				display: block !important;
			}

			&>.fa-Accordion-item,
			&>div>.fa-Accordion-item {
				background: #f8f8f8;
				margin: 5px 0;
				border: 1px solid #ececec;

				.parent-object-field {
					&>.property-item {
						border-bottom: none !important;
					}
				}

				&.active {
					&>.title {
						border-bottom: 1px solid #ececec;
						color: #141414;

						&:before {
							border-left: 4px solid #141414 !important;
						}
					}
				}

				&>.content {
					background: #f8f8f8;
					padding: 12.5px;
				}

				&>.title {
					color: #aeaeae;
					padding: 0px 17px;
					font-size: 13px;
					// background: #f6f6f6;
					background: #fcfcfc;

					.text {
						max-width: 90%;
						padding: 8px 0px !important;
					}

					i {
						font-size: 11px;
					}

					&:before {
						border-left: 4px solid #aeaeae !important;
					}
				}

				.property-list {
					&>div:last-child {

						// border-bottom: none !important;
						.property-item {
							// border-bottom: none !important;
						}
					}
				}

				.property-item {
					transition: all, 0s !important;
					border-bottom: 1px dashed #e1e1e1;

					&.no-border {
						border-bottom: none !important;
					}

					&:last-child {
						margin-bottom: 8px;
					}
				}

				&>.property-item {
					background: #e5f5ef !important;
					border: 1px dotted #b3b4b4 !important;
				}

				.parent-object-field {
					width: 100%;

					.content {
						background: #fff;
						border: none;
						margin-bottom: 0px;

						&>.property-item {
							border-bottom: none !important;
						}

						.property-item {
							border-bottom: none !important;
						}
					}
				}
			}
		}
	}
}

.react-autosuggest__container {
	position: relative;
}

.react-autosuggest__input {
	width: 100%;
	height: 38px;
	padding: 7.5px 10px;
	font-weight: 700;
	font-size: 13px;
	border: 1px solid #d0d0d0;
	color: #4b885b;

	&.disabled {
		background: #ececec;
		opacity: 0.7;
		pointer-events: none !important;

		* {
			pointer-events: none !important;
		}
	}

	&.meta {
		color: #318581;
	}
}

.react-autosuggest__input--focused {
	outline: none;
}

.react-autosuggest__input--open {
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
	display: none;
}

.react-autosuggest__suggestions-container--open {
	display: block;
	position: absolute;
	border: 1px solid #aaa;
	background-color: #fff;
	font-weight: 600;
	font-size: 11px;
	z-index: 2;
	width: auto;
	margin-top: -1px;
	overflow-y: auto;
	max-height: 120px;
	min-width: 200px;
	bottom: 100%;
}

.react-autosuggest__suggestions-container--open::-webkit-scrollbar {
	width: 2px;
}

.react-autosuggest__suggestions-container--open::-webkit-scrollbar-track {
	background: #e7e7e7;
}

.react-autosuggest__suggestions-container--open::-webkit-scrollbar-thumb {
	background: #bbb;
}

.react-autosuggest__suggestions-list {
	margin: 0;
	padding: 0;
	list-style-type: none;
}

.react-autosuggest__suggestion {
	cursor: pointer;
	border-bottom: 1px solid #e9e9e9;
	color: #000;
	font-size: 11px;
	padding: 10px;
}

.react-autosuggest__suggestion:last-child {
	border-bottom: none;
}

.react-autosuggest__suggestion--highlighted {
	background-color: #eaf6ff;
	color: #1882d4;
}

.react-autosuggest__suggestion-match {
	color: red;
	font-weight: bold;
}

.theme-color-properties {
	&>div[role="tabpanel"] {
		.ms-Pivot {
			margin-bottom: 0px !important;
		}
	}

	.ms-Pivot {
		font-size: 12px !important;
		background: #f7fbfc;
		border: 1px solid #ececec;
		margin-bottom: 10px;

		&>button {
			font-size: 12px !important;

			&:hover {
				background-color: rgb(237, 245, 247) !important;
			}
		}
	}

	div[role="tabpanel"] {
		padding: 0px 10px;
	}

	&.theme-routes {
		&>div[role="toolbar"] {
			display: flex;
		}

		.ms-Pivot {
			max-width: 215px;
			flex: 0 0 215px;
			display: block;
			min-height: calc(100vh - 422px);
			max-height: calc(100vh - 410px);
			overflow-y: auto;

			// overflow-y: auto;
			&::-webkit-scrollbar {
				width: 3px;
			}

			&::-webkit-scrollbar-track {
				background: #eeeeee;
			}

			&::-webkit-scrollbar-thumb {
				background: #bbbbbb;
			}

			&::-webkit-input-placeholder {
				color: rgb(155, 155, 155);
			}

			.ms-Button {
				display: block;
				width: 100%;
				text-align: left;
				font-weight: 600 !important;
				color: #444;
				border-bottom: 1px solid #ececec;
				font-size: 12px !important;

				&.is-selected {
					color: #0085ba;
					background: #f0faff;
				}

				&:last-child {
					border-bottom: none !important;
				}

				&:before {
					display: none !important;
				}
			}
		}

		div[role="tabpanel"] {
			max-width: calc(100% - 215px);
			flex: 0 0 calc(100% - 215px);
		}

		.static-page-picker {
			width: 100%;
		}
	}
}

.filter-management {
	min-height: auto !important;

	.filter-management-menu {
		background: #f7fbfc;
		border-bottom: 1px solid #ececec;

		.item {
			cursor: pointer;
			padding: 15px 35px !important;
			font-weight: 600;
			color: #444444;
			font-size: 11px;
			border-left: 1px solid transparent;
			border-right: 1px solid transparent;
			border-bottom: none !important;

			&.active {
				color: #0085ba;
				background: #f0faff;
				border-left: 1px solid #ececec;
				border-right: 1px solid #ececec;
			}

			i {
				width: 25px;
				font-size: 13px;
				padding-right: 10px;
			}
		}
	}

	.theme-settings-content {
		&>.title {
			padding: 20px !important;
		}

		.scrollable-content {
			min-height: calc(100vh - 400px);
			background: #fdfdfd;
			margin-top: 10px;
		}
	}
}

.theme-settings {
	width: 100%;
	background: #ffffff;
	margin-top: 15px;
	border: 1px solid #ddd3d3;
	min-height: calc(100vh - 290px);

	.theme-settings-menu {
		background: #f7fbfc;
		border-right: 1px solid #ececec;

		&-wrapper {
			position: sticky;
			top: 45px;
		}

		.item {
			cursor: pointer;
			padding: 15px;
			font-weight: 600;
			color: #444444;
			border-bottom: 1px solid #ececec;
			font-size: 12px;

			&.active {
				color: #0085ba;
				background: #f0faff;
			}

			i {
				width: 25px;
				font-size: 13px;
				padding-right: 10px;
			}
		}
	}

	.theme-settings-content {
		// padding: 15px 30px;
		overflow: hidden;

		&>.title {
			background: #fafafa;
			padding: 20px 15px;
			position: relative;
			left: -15px;
			width: calc(100% + 30px);
			border-bottom: 1px dotted #c4c4c4;
			font-weight: 600;
			font-size: 13.5px;

			.sub-text {
				font-weight: 400;
				font-size: 11px;
			}
		}

		.scrollable-content {
			&>div {
				&>div[role="toolbar"] {
					padding: 10px 0px;
					max-height: calc(100vh - 355px);
					overflow: hidden;
					overflow-y: auto;

					&::-webkit-scrollbar {
						width: 4px;
					}

					&::-webkit-scrollbar-track {
						background: #eeeeee;
					}

					&::-webkit-scrollbar-thumb {
						background: #bbbbbb;
					}

					&::-webkit-input-placeholder {
						color: rgb(155, 155, 155);
					}
				}
			}

		}
	}

	.theme-general-setting,
	.theme-css {
		margin-top: 10px;
	}

	.file-list {
		border: none;
		background: transparent !important;
		padding: 0px;

		.file-item-wrapper {
			width: 125px;
		}
	}

	.meta-file {
		padding: 7.5px;
		background: #fafafa;

		.file-item-wrapper {
			img {
				background: #c8d6de;
			}
		}
	}
}

.meta-dropdown-values {
	max-height: 150px;
	overflow-y: auto;
	margin-top: 5px;

	&.no-scroll {
		max-height: 100%;
		overflow-y: initial !important;

		.meta-dropdown-value {
			margin-bottom: 5px;
		}
	}

	&::-webkit-scrollbar {
		width: 4px;
	}

	&::-webkit-scrollbar-track {
		background: #e9e9e9;
	}

	&::-webkit-scrollbar-thumb {
		background: #bbbbbb;
	}

	&::-webkit-input-placeholder {
		color: rgb(155, 155, 155);
	}

	.meta-dropdown-value {
		align-items: center;
		background: #f6f6f6;
		border-bottom: 1px solid #ececec;
		padding: 5px 0px;

		&:last-child {
			border-bottom: none;
		}

		i.icon-close {
			font-size: 11px;
			cursor: pointer;
			padding: 5px 10px;
			position: relative;
			top: 13px;
			right: 9px;

			&.passive {
				opacity: 0;
				pointer-events: none;
			}
		}
	}
}

.picker-indicator {
	position: absolute;
	right: 20px;
	top: 5px;
	background: #2d8892;
	color: #fff;
	font-weight: 600;
	font-size: 9px;
	padding: 5px 10px;
	border-radius: 10px;
	cursor: pointer;
}

.ms-BasePicker {
	.tag-item {
		background: #33926e;
		padding: 5px;
		font-size: 11px;
		cursor: pointer;
		margin: 0px 2.5px;
		-webkit-transition: all .25s;
		-moz-transition: all .25s;
		-o-transition: all .25s;
		transition: all .25s;
		color: #fff;

		&:hover {
			background: #429978;
		}

		i {
			font-size: 10px;
			padding: 0px 5px;
		}
	}

	.ms-BasePicker-text {
		border: 1px solid #d3d3d3;
	}
}

.criteria-item {
	padding: 5px !important;

	.criteria-item-wrapper {
		background: #f6faf9;
		padding: 10px;
		border: 1px solid #ebebeb;

		.col {
			padding: 0px 5px;
		}

		.ms-Dropdown-container {
			padding-left: 5px;
		}

		.remove {
			cursor: pointer;
			padding: 5px 15px;
			align-self: flex-end;
			margin-bottom: 3px;
			color: #9f6b6b;
			opacity: 0;
			pointer-events: none;

			&.active {
				opacity: 1;
				pointer-events: all;
			}
		}

		.ms-Dropdown-label {
			margin-bottom: 0.5px !important;
		}
	}
}

.post-title {
	margin-top: 13px !important;
	margin-bottom: 13px;

	&>.ms-TextField {
		.ms-TextField-fieldGroup {
			font-size: 19px !important;
			height: auto;
		}
	}

	input {
		font-size: 19px !important;
		padding: 10px !important;
		font-weight: 700 !important;

		&::-webkit-input-placeholder {
			font-size: 19px !important;
			font-weight: 700 !important;
			color: #a4a4a4;
		}
	}

	.post-url {
		padding: 5px;
		font-size: 11px;
		align-items: center;

		.url-title {
			font-weight: 500;
			margin-right: 5px;
		}

		.url-wrapper {
			&.active {
				color: #0078d4;
				cursor: pointer;
				text-decoration: underline;
			}
		}

		.url-content {
			font-weight: 700;

			input {
				font-size: 11px !important;
				padding: 7px !important;

				&::-webkit-input-placeholder {
					font-weight: 500 !important;
					color: #000 !important;
				}
			}

			.ms-TextField-fieldGroup {
				height: 22px;
				min-width: 185px;
			}
		}

		.buttons {
			align-items: center;
		}

		button {
			height: auto;
			min-width: auto;
			padding: 3.5px 5px;
			font-size: 11px;
			margin: 0 5px;

			.ms-Button-label {
				font-size: 11px;
			}
		}

		.cancel {
			font-weight: 500;
			border-bottom: 1px solid #1483d7;
			color: #1483d7;
			cursor: pointer;
			font-size: 11px;
			margin-left: 1px;
		}
	}
}

.meta-icon-list,
.meta-text-list {
	&.working-hours {
		.ms-Pivot {
			margin-bottom: 7.5px;
			margin-top: -5px;

			.ms-Pivot-link {
				height: 33px;
				font-size: 11px;
			}
		}

		.meta-text-item {
			&>div {
				padding: 0px 3.5px;
			}

			.remove {
				margin-top: -12px;
			}

			input {
				&::-webkit-input-placeholder {
					color: #b3b3b3;
				}
			}
		}
	}

	.meta-icon-item,
	.meta-text-item {
		align-items: center;
		margin-bottom: 5px;

		.remove {
			font-size: 11px;
			cursor: pointer;
			padding: 0px 0px 0px 10px;

			&.passive {
				opacity: 0;
				pointer-events: none;
			}
		}

		.icon-picker {
			cursor: pointer;
			padding-right: 5px;
		}

		.selected-icon {
			background: #33926e;
			margin-right: 6px;
			color: #fff;
			height: 24px;
			width: 24px;
			font-size: 10px;
			text-align: center;
			line-height: 24px;

			&.passive {
				background: #e8e7e7;
			}
		}
	}
}

.meta-defaults {
	.meta-field {
		padding: 2.5px 0px !important;
	}

	.icon-panel {
		position: fixed;
		top: inherit;
		left: inherit;
		margin-top: -100px;
	}
}


.meta-fields {
	.meta-field {
		padding: 2.5px 7.5px;
		position: relative;

		.quick-insertion {
			margin-left: auto;
			font-size: 9px;
			background: #676767;
			color: #fff;
			font-weight: 600;
			padding: 3.5px 5px;
			display: flex;
			align-items: center;
			cursor: pointer;
			@include t(all, .25s);
			position: absolute;
			right: 20px;
			top: 15px;

			&:hover {
				background: #33926e;
			}

			i {
				padding-right: 1.5px;
				position: relative;
				top: -1px;
			}
		}

		&>.title {
			background: #ffffff;
			padding: 10px;
			display: flex;
			align-items: center;
			font-weight: 600;
			font-size: 11px;
			border: 1px solid #dfdfdf;

			i {
				padding-right: 5px;
			}
		}

		&>.content {
			padding: 10px;
			background: #f9f9f9;
			border: 1px solid #dfdfdf;
			margin-top: -1px;
		}

		.ms-Toggle {
			margin: 0px;

			.ms-Toggle-stateText {
				margin-top: -1px;
			}
		}

		.sub-title {
			display: block;
			width: 100%;
			padding-left: 15px;
			font-size: 10.3px;
			margin-bottom: -4px;
			color: #9c9c9c;
			margin-top: -2px;
		}
	}
}

.contributor-picker-container {
	width: 100%;

	&.selected {
		input {
			display: none;
		}
	}

	.ms-Label {
		padding-top: 0px;
		font-weight: 600 !important;
		color: #000 !important;
		font-family: "Roboto", sans-serif;
	}
}

.meta-scrollable {
	max-height: calc(100vh - 200px);
	overflow-x: hidden;
	overflow-y: auto;

	&::-webkit-scrollbar {
		width: 5px;
	}

	&::-webkit-scrollbar-track {
		background: #f3f3f3;
	}

	&::-webkit-scrollbar-thumb {
		background: #cccccc;
	}

	&::-webkit-input-placeholder {
		color: rgb(155, 155, 155);
	}
}

.clone-callout-wrapper {
	padding: 10px;

	.title {
		font-size: 13.5px;
		font-weight: 600;

		.sub-title {
			font-size: 11px;
		}
	}

	.ms-Callout-main {
		&>button {
			font-size: 11px;
			margin-top: 6px;

			i {
				font-size: 13px;
			}
		}
	}
}

.tox-tinymce-aux {
	position: relative;
	z-index: 9999999 !important;
}

.page-values {
	width: 100%;
	background: #fff;
	padding: 10px;
	border: 1px solid #d7d5d5;

	.meta-dropdown-title {
		border-bottom: 1px solid #ececec;
		padding-bottom: 7.5px;
		margin-bottom: 5px;

		.title {
			font-weight: 700;
			font-size: 12px;
		}
	}

	.meta-dropdown-values {
		overflow-y: hidden;
		max-height: 100% !important;

		.meta-dropdown-value {
			background: transparent !important;
		}

		i.icon-close {
			top: -6.5px;
		}
	}
}

.stock-picker-container .form-title {
	margin-bottom: 5px;
}

.stock-picker {
	width: 100%;

	&.selected {
		input {
			display: none;
		}
	}
}

.stock-picker input {
	font-size: 11px;
	padding: 0px 10px;
	height: 29px !important;
	font-family: $font;
}

.stock-picker .ms-BasePicker-itemsWrapper {
	width: 100%;
}

.stock-picker .stock-picker-item {
	width: 100%;
	display: flex;
	padding: 0px 10px;
	font-size: 11px;
	font-weight: 600;
	font-family: $font;
}

.stock-picker .stock-picker-item .remove {
	height: 100%;
	position: absolute;
	top: 0;
	right: 4px;
	width: 27px;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 10px;
	cursor: pointer;
}

.stock-picker .stock-picker-item .dimension {
	padding: 0px 12px;
	color: #4b7b5d;
	font-family: $font;
	margin-right: 7.5px;
}

.stock-picker .stock-picker-item .dimension .multiplier {
	color: #0079d4;
}

.stock-picker .ms-BasePicker-text {
	background: #fff;
	width: 100%;
}

.stock-picker-suggestions .is-suggested:hover {
	background: #f1f1f1 !important;
}

.stock-picker-suggestions .ms-Suggestions-spinner {
	padding: 10px 0px !important;
}

.stock-picker-suggestions .ms-Suggestions-title {
	position: relative;
	padding-left: 30px;
	display: none;
}

.stock-picker-suggestions .ms-Suggestions-title:after {
	position: absolute;
	left: 10px;
	top: -0.5px;
	content: "\e964";
	font-family: "icomoon";
	font-size: 17px;
}

.stock-picker-suggestions .ms-Suggestions-none {
	padding: 10px;
}

.stock-picker-suggestions .ms-Suggestions-container::-webkit-scrollbar {
	width: 5px;
	opacity: 0;
}

.stock-picker-suggestions .ms-Suggestions-container::-webkit-scrollbar-track {
	background: #dcdcdc;
}

.stock-picker-suggestions .ms-Suggestions-container::-webkit-scrollbar-thumb {
	background: #c0c0c0;
}

.stock-picker-suggestions .ms-Suggestions-container>div:first-child {
	margin-bottom: 1px;
	border-bottom: 1px solid #ccc;
}

.stock-picker-suggestions .ms-Suggestions-container>div:last-child .sp-suggestion-item {
	border-bottom: none !important;
}

.detail-stock-indicator {
	width: 100%;
	padding: 8px 15px;
	text-align: left;
	font-size: 12px;
	color: #000;
}

.detail-stock-indicator i {
	font-family: "icomoon" !important;
	font-size: 15px;
	position: relative;
	top: 1.5px;
	padding-right: 3px;
}

.sp-suggestion-item {
	display: flex;
	align-items: center;
	width: 100%;
	/* margin: 5px 0px; */
	border-bottom: 1px solid #eeeeee;
	padding: 7px 2px;
}

.sp-suggestion-item .sg-item-figure {
	width: 50px;
	height: 40px;
	padding: 0px 5px;
	overflow: hidden;
	align-items: center;
}

.sp-suggestion-item .sg-item-figure img {
	width: 100%;
}

.sp-suggestion-item .sg-item-article {
	font-size: 12px;
	padding-left: 5px;
	font-weight: 400;
	text-align: left;
	padding-right: 10px;
}

.sp-suggestion-item .sg-item-article .name {
	font-weight: 600;
	font-size: 11px;
}

.sp-suggestion-item .sg-item-article .dimension {
	font-size: 10px;
}

.contributor-picker-container .form-title {
	margin-bottom: 5px;
}

.contributor-picker input {
	font-size: 13px;
	padding: 0px 10px;
	height: 36px !important;
	font-family: $font;
}

.contributor-picker .ms-BasePicker-itemsWrapper {
	width: 100%;
}

.contributor-picker .contributor-picker-item {
	width: 100%;
	display: flex;
	padding: 10px 15px 10px 10px;
	font-size: 13px;
	font-weight: 500;
	font-family: $font;
}

.contributor-picker .contributor-picker-item .remove {
	height: 100%;
	position: absolute;
	top: 1px;
	right: 4px;
	width: 27px;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 11px;
	cursor: pointer;
}

.contributor-picker .contributor-picker-item .dimension {
	padding: 0px 12px;
	color: #4b7b5d;
	font-family: $font;
	margin-right: 7.5px;
}

.contributor-picker .contributor-picker-item .dimension .multiplier {
	color: #0079d4;
}

.contributor-picker .ms-BasePicker-text {
	background: #fff;
	width: 100%;
}

.contributor-picker.selected input {
	display: none !important;
}

.contributor-picker-suggestions .is-suggested:hover {
	background: #f1f1f1 !important;
}

.contributor-picker-suggestions .ms-Suggestions-spinner {
	padding: 10px 0px !important;
}

.contributor-picker-suggestions .ms-Suggestions-title {
	position: relative;
	padding-left: 30px;
	display: none;
}

.contributor-picker-suggestions .ms-Suggestions-title:after {
	position: absolute;
	left: 10px;
	top: -0.5px;
	content: "\e964";
	font-family: "icomoon";
	font-size: 17px;
}

.contributor-picker-suggestions .ms-Suggestions-none {
	padding: 10px;
}

.contributor-picker-suggestions .ms-Suggestions-container::-webkit-scrollbar {
	width: 5px;
	opacity: 0;
}

.contributor-picker-suggestions .ms-Suggestions-container::-webkit-scrollbar-track {
	background: #dcdcdc;
}

.contributor-picker-suggestions .ms-Suggestions-container::-webkit-scrollbar-thumb {
	background: #c0c0c0;
}

.contributor-picker-suggestions .ms-Suggestions-container>div:first-child {
	margin-bottom: 1px;
	border-bottom: 1px solid #ccc;
}

.contributor-picker-suggestions .ms-Suggestions-container>div:last-child .sp-suggestion-item {
	border-bottom: none !important;
}

span.order-shipping-0,
span.order-shipping-1,
span.order-shipping-2 {
	background: #ffe4bd;
	color: #000;
	font-size: 10px;
	padding: 5px 10px;
	margin-bottom: -5px;
	margin-top: -2.5px;
	font-weight: 600;
}

span.order-shipping-3 {
	background: #33926e;
	color: #fff;
	font-size: 10px;
	padding: 5px 10px;
	margin-bottom: -5px;
	margin-top: -2.5px;
	font-weight: 600;
}

span.order-shipping-4 {
	background: #ac4f4f;
	color: #fff;
	font-size: 10px;
	padding: 5px 10px;
	margin-bottom: -5px;
	margin-top: -2.5px;
	font-weight: 600;
}

.table-pricing {
	max-width: 200px;
	background: #f9f9f9;
	padding: 10px;
	margin: 10px 0px;
	border: 1px dashed #dfdfdf;

	// &.right {
	// 	text-align: right;
	// 	display: flex;
	// 	justify-content: flex-end;
	// 	flex-direction: column;
	// 	align-items: flex-end;
	// }
	.item {
		text-align: left;
		border-bottom: 1px solid #e2e2e2;
		padding: 5px 0px;
		font-size: 12px;

		&.big {
			font-size: 13px;

			strong {
				font-size: 14px;
				margin-top: -1.5px;
			}
		}

		&:last-child {
			border-bottom: none;
		}

		strong {
			margin-left: 5px;
		}
	}
}

.rule-items {
	&>.title {
		margin: 12.5px 0px 5px 0px;
		font-weight: 600;
		font-size: 12px;
		border-bottom: 1px solid #ececec;
		padding-bottom: 10px;
	}

	.rule-item {
		i {
			align-self: flex-end;
			font-size: 12px;
			padding: 10px;
			cursor: pointer;

			&.passive {
				opacity: 0;
				pointer-events: none;
			}
		}
	}
}

.fa-Dynamic-list {
	position: relative;
	width: 100%;
	z-index: 111;

	.not-found-parameter {
		font-style: italic;
		font-size: 11px;
		font-weight: 500;
		color: #000;
		padding: 15px;
		display: block;

		i {
			padding-right: 2.7px;
		}
	}

	.ms-Pivot .ms-Button {
		height: 35px;
		line-height: 35px;
		font-size: 11.5px;
		font-weight: 600;
	}

	.title {
		padding: 10px;
		background: #1882d4;
		color: #efefef;
		font-size: 10.5px;

		.up-title {
			font-weight: 600;
			font-size: 11.5px;
		}

		.sub-title {
			font-size: 9px;
		}

		.close {
			position: absolute;
			cursor: pointer;
			right: 15px;
			top: 12.5px;
			font-size: 12px;
		}
	}

	.fa-Dynamic-listWrapper {
		max-height: 250px;
		overflow-y: auto;

		.item {
			font-size: 11.5px;
			padding: 7.5px 10px;
			align-items: center;
			display: flex;
			font-weight: 600;
			border-bottom: 1px solid #d2d2d2;
			cursor: pointer;

			&.wrong-search {
				opacity: 0.5;
				pointer-events: none;
			}

			.text {
				font-size: 10.5px;
			}

			.badge {
				width: auto !important;
				margin-right: 4px;
				padding: 3px 5px;
				margin-left: 0;
				font-size: 9px;
			}

			&.request_body:before {
				background: #458ac0;
			}

			i {
				&.green:before {
					content: "\ea1c" !important;
				}

				margin-right: 0;
				color: #fff;
				font-size: 8px;
				height: 20px;
				line-height: 21px;
				width: 20px;
				text-align: center;
				padding: 0 4px 0 2px;
			}

			&:hover {
				background: #ecf6fe;
			}

			.sub-text {
				font-weight: 400;
				font-size: 11px;
				margin-top: -2px;
			}
		}

		&::-webkit-scrollbar {
			width: 2px;
		}
	}
}

.button-bar-fixed {
	position: fixed;
	bottom: 0;
	background: rgba(255, 255, 255, 0.91);
	width: calc(100% - 260px);
	left: 260px;
	padding: 15px;
	border-top: 1px solid #ececec;
	display: flex;
	justify-content: flex-end;
	z-index: 99;

	.status-bar {
		background: #e98242;
		color: #fff;
		display: flex;
		align-items: center;
		padding: 7.5px 10px;
		font-weight: 500;
		font-size: 13px;
		border: 1px solid #e98242;
		cursor: pointer;
		border-radius: 2.5px;

		.ms-TooltipHost {
			display: flex;
			align-items: center;
		}

		i {
			padding-right: 5px;
		}

		&.status-bar-green {
			background: rgb(91, 158, 133);
			color: #fff;
			border: 1px solid rgb(91, 158, 133);
		}

		&.status-bar-red {
			background: #923333;
			color: #fff;
			border: 1px solid #923333;
		}
	}

	.ms-Button {
		margin: 0px 5px;
		font-size: 13px !important;
	}

	.button-bar-wrapper {
		display: flex;
		justify-content: flex-end;
		width: 100%;
	}
}

.wizard-groups {
	margin: 15px -7px;

	.wizard-step-content {
		.translator-bar {
			margin: 8px 7px -5px;
			padding: 0px 11px;
		}
	}

	.wizard-step-header {
		position: relative;
		margin: 0px 7px;
		background: #fff;
		border: 1px solid #efefef;
		position: sticky;
		top: 45px;
		z-index: 10;

		.wizard-step-item {
			position: relative;
			z-index: 999;
			border-left: 1px solid #f5f5f5;
			border-right: 1px solid #f5f5f5;
			margin-right: 10px;

			.title {
				&:before {
					left: 0;
					bottom: 0;
					height: 3px;
					position: absolute;
					content: "";
					width: 100%;
					@include t(all, .45s);
					background: #d26363;
				}

				&:after {
					left: 0;
					bottom: 0;
					height: 3px;
					position: absolute;
					content: "";
					width: 0;
					@include t(all, .45s);
					background: #4db18b;
				}
			}

			.brandmark {
				height: 19px;
				position: absolute;
				top: 0;
				z-index: 9999;
				background: #d26363;
				color: #fff;
				width: 16px;
				text-align: center;
				line-height: 21px;
				font-size: 9.5px;
				left: 7px;
				border-bottom-left-radius: 8.5px;
				border-bottom-right-radius: 8.5px;
				@include t(all, .45s);

				i[data-icon-name="ChromeClose"] {
					font-size: 7.5px;
					line-height: 20px;
				}
			}

			&.disabled {
				pointer-events: none;
				opacity: 0.6;
			}

			&.error {
				border-bottom: 3px solid red;
			}

			&.active {
				.title {
					background: #f6fefb !important;
					color: #33926e;
				}
			}

			&.stable {
				.title {
					&:after {
						background: #a3a3a3;
					}
				}

				&.active {
					.title {
						&:after {
							background: #33926e;
						}
					}
				}
			}

			&.medium {
				.title {
					&:before {
						background: rgb(238, 173, 119);
					}
				}

				.brandmark {
					background: #eead77;
				}
			}

			&.filled {
				.title {
					&:after {
						width: 100%;
					}

					// .icon {
					// 	@include s(1);
					// }
				}

				.brandmark {
					background: #4db18b;
				}

				// &.active {
				// 	.title {
				// 		background: #4caa86 !important;
				// 		color: #fff !important;
				// 	}
				// 	&:before {
				// 		background: #bcd3cb !important;
				// 	}
				// }
			}

			.title {
				font-weight: 500;
				font-size: 13px;
				padding: 15px 25px;
				position: relative;
				z-index: 999;
				cursor: pointer;
				@include t(all, .45s);
				color: #5c5c5c;

				&:hover {
					background: #f5f5f5;
				}

				.icon {
					position: absolute;
					border-radius: 100%;
					top: 5px;
					left: 5px;
					z-index: 9999;
					padding: 0px;
					width: 25px;
					height: 25px;
					text-align: center;
					line-height: 25px;
					font-size: 9px;
					background: #4caa86;
					color: #fff;
					transform-origin: center;
					@include t(all, .55s);
					@include s(0);

					i {
						font-size: 13px;
						line-height: 25px;
					}
				}
			}
		}
	}

	.ms-MessageBar {
		margin-top: 12.5px !important;
		border: 1px solid #dae3e8;

		.ms-MessageBar-innerText {
			font-weight: 500;
			font-size: 12px;
		}
	}

	.wizard-info {
		background: #f1f7fc;
		margin-top: 10px;
		font-size: 11px;
		padding: 5px 7.5px;
		border: 1px solid #e1e7eb;
		align-items: center;
		display: flex;
		font-weight: 500;

		.ms-Button {
			height: 27px;
			margin-left: auto;
			padding: 0px 15px !important;
			min-width: 95px;
			font-size: 10px !important;
		}

		i {
			padding-right: 5px;
			padding-left: 2.5px;
			font-size: 12px;
			position: relative;
			top: -0.5px;
		}
	}

	.wizard-group {
		padding: 0px 7px !important;

		&:last-child {
			.wizard-group-wrapper {
				margin-bottom: 50px;
			}
		}

		.file-list {
			padding: 0px;
			border: none;
			margin: 0px;

			.file-item {
				.edit {
					top: 9px;
				}

				.remove {
					top: 6px !important;
				}
			}
		}

		.form-wrapper {
			margin: 0px -12.5px;
		}

		&.edited {
			.wizard-group-wrapper {
				&>.title {
					.icon {
						background: #fff4d7;
					}
				}
			}
		}

		&.filled {
			.wizard-group-wrapper {
				&>.title {
					.icon {
						background: #33926e;

						i[data-icon-name="ChromeMinimize"] {
							opacity: 0;
							@include s(0);
						}

						i[data-icon-name="Checkmark"] {
							opacity: 1;
							@include s(1);
							color: #fff;
						}
					}
				}
			}
		}

		.wizard-group-wrapper {
			padding: 15px;
			margin-top: 10px;
			// max-height: calc(100vh - 400px);
			// overflow-y: auto;
			background: #ffffff;
			border: 1px solid #ececec;
			position: relative;

			&::-webkit-scrollbar {
				width: 4px;
			}

			&::-webkit-scrollbar-track {
				background: #dcdcdc;
			}

			&::-webkit-scrollbar-thumb {
				background: #b3b3b3;
			}

			&::-webkit-input-placeholder {
				color: rgb(155, 155, 155);
			}

			&>.title {
				display: flex;
				align-items: center;
				border-bottom: 1px solid #ececec;
				padding-bottom: 10px;
				margin-bottom: 10px;
				min-height: 60px;

				.text {
					font-weight: 600;

					.sub-text {
						display: block;
						font-size: 11px;
						font-weight: 500;
						padding-right: 10px;
					}
				}

				.icon {
					width: 35px;
					height: 35px;
					border-radius: 100%;
					background: #ececec;
					text-align: center;
					line-height: 37.5px;
					margin-right: 10px;
					flex: 0 0 35px;
					max-width: 35px;
					transition-delay: 0.5s;
					@include t(all, .35s);
					position: relative;

					i[data-icon-name="Checkmark"] {
						opacity: 0;
						@include s(0);
						font-size: 14px;
					}

					i {
						position: absolute;
						left: 50%;
						top: 50%;
						margin-top: -7.5px;
						margin-left: -6px;
						@include t(all, .35s);
						transform-origin: center;
					}
				}

				.ms-Button {
					margin-left: auto;
					min-width: 80px;
					padding: 0px 5px;
					min-height: 25px;
				}
			}
		}
	}

	.wizard-not-found {
		background: #fff4d7;
		padding: 8px;
		font-size: 10.5px;
		align-items: center;

		i {
			float: left;
			max-width: 10px;
			padding: 3.5px;
			font-size: 13px;
		}

		.text {
			font-weight: 400;
		}
	}

	.data-list {
		max-height: calc(100vh - 370px);
		overflow-y: auto;

		&::-webkit-scrollbar {
			width: 4px;
		}

		&::-webkit-scrollbar-track {
			background: #747474;
		}

		&::-webkit-scrollbar-thumb {
			background: #bbbbbb;
		}

		&::-webkit-input-placeholder {
			color: rgb(155, 155, 155);
		}

		.item {
			padding: 7.5px;

			.item-wrapper {
				padding: 10px;
				background: #f3f3f3;
				cursor: pointer;
				font-size: 12px;
				font-weight: 500;
				align-items: center;
				width: 100%;
				border: 1px solid #ececec;
				min-height: 62px;

				.sub-text {
					font-size: 10px;
				}

				.new {
					position: absolute;
					right: 7.5px;
					top: 0px;
					background: #33926e;
					color: #fff;
					font-size: 10px;
					font-weight: 600;
					padding: 1px 10px;
				}
			}

			i {
				margin-right: 7.5px;
				padding-bottom: 5px;
				max-width: 15px;
				flex: 0 0 15px;
			}

			.title {
				word-break: break-word;
				max-width: calc(100% - 40px);
			}

			.settings {
				margin-left: auto;
				align-items: center;
				max-width: 15px;
				flex: 0 0 15px;

				i {
					margin-right: 0px;
				}

				.edit {
					color: #1d7bbd;
					font-size: 12px;
					margin-left: 6.5px;
					position: relative;
					top: 1.5px;
				}

				.remove {
					color: #a03939;
					font-size: 10px;
					margin-left: 6.5px;
					position: relative;
					top: 1px;
				}
			}

			&:last-child {
				margin-bottom: 0px;
			}
		}
	}
}

.translator-bar {
	background: #ffffff;
	border: 1px solid #efefef;
	padding: 8px 11px;
	margin-top: 10px;
	align-items: center;
	margin-bottom: -5px;
	font-family: $font;

	&>i {
		font-size: 15px;
		padding-right: 8.5px;
		color: #2c2c2c;
	}

	&>.row {
		margin-top: 1px;
		align-items: center;
		margin-left: 10px;
	}

	.title {
		font-weight: 600;
		font-size: 13px;
		position: relative;
		top: 1px;
		color: #2c2c2c;
		margin-right: 15px;
	}

	.text {
		font-weight: 400;
		font-size: 12px;
		position: relative;
		display: block;
		top: 1px;
	}

	.custom-button {
		height: 29px;
		font-size: 10px !important;
		margin-right: 20px;
		margin-top: -1px;
	}

	.language-picker {
		align-items: center;
		margin-left: auto;
		margin-top: 1px;

		>span {
			margin-right: 10px;
			font-size: 12px;
			font-weight: 500;
			margin-bottom: 0px !important;
		}

		.ms-Dropdown {
			min-width: 250px;
		}

		.language-picker-item {
			display: flex;
			align-items: center;
			font-size: 11px;
			padding: 0px 9.5px;
			border: 1px solid transparent;
			border-right: 1px solid #ececec;
			cursor: pointer;
			font-weight: 500;
			@include t(all, .35s);
			margin-top: -2px;
			font-family: $font;
			height: 50px;
			position: relative;

			&:last-child {
				border-right: none;
			}

			.badge {
				position: absolute;
				top: -2px;
				background: rgb(45, 141, 215);
				color: #fff;
				font-size: 9px;
				padding: 2px 5px;
				left: 11px;
			}

			&.active {
				background: #f3f2f1;

				span.percent {
					background: #dddddd;
				}
			}

			&:hover {
				background: #eaeff2;

				span.percent {
					background: #dddddd;
				}
			}

			img {
				width: 19px;
				object-fit: cover;
				padding: 2px;
				margin-right: 4px;
				position: relative;
			}

			span {
				padding-right: 2.5px;

				&.percent {
					font-size: 10px;
					font-weight: 700;
					color: #494949;
					background: #ececec;
					margin-left: 3.5px;
					margin-bottom: -5px;
					/* border-radius: 100%; */
					margin-top: -5px;
					transition-delay: .5s;
					-webkit-transition: all .25s;
					-moz-transition: all .25s;
					-o-transition: all .25s;
					transition: all .25s;
					text-align: center;
					padding: 5px;

					&.medium {
						color: #fff;
						background: #ffb07e;
					}

					&.filled {
						color: #fff;
						background: #4db18b;
					}
				}
			}
		}
	}
}

.dropdown-option {
	img {
		width: 20px;
		object-fit: cover;
		padding: 2px;
		margin-right: 5px;
		position: relative;
		top: 3px;
	}
}

.dropdown-title {
	margin-top: -2px;

	img {
		width: 20px;
		object-fit: cover;
		padding: 2px;
		margin-right: 5px;
		position: relative;
		top: 3px;

	}
}

.validation-wrapper {
	position: relative;

	&:after {
		position: absolute;
		height: calc(100% - 28px);
		bottom: 0px;
		left: 0px;
		width: 4px;
		content: "";
		background: #be464d;
		@include t(all, .25s);
	}

	.validated-icon {
		position: absolute;
		font-size: 11px;
		right: 2px;
		top: 7px;
		display: block;
		box-sizing: border-box;
		width: 16px;
		height: 16px;
		border-radius: 100%;
		text-align: center;
		line-height: 19px;
		background: #be464d;
		color: #fff;
		padding-left: 1px;
		@include t(all, .25s);
	}

	&.validated {
		&:after {
			background: #84b2a1;
		}

		.validated-icon {
			background: #4db18b !important;
		}
	}

	.ms-TextField-fieldGroup,
	.ms-Dropdown,
	.ms-ComboBox,
	.mask-input,
	.tox-tinymce,
	.custom-currency {
		margin-left: 6px;

		&.margin-right {
			width: calc(100% - 10px) !important;
		}
	}

}

.file-browser {
	.filepond--credits {
		display: none;
	}

	.filepond--drop-label {
		label {
			i {
				font-size: 22px;
			}

			font-size: 12px;
		}
	}

	&-button {
		position: absolute;
		bottom: 10px;
		padding: 7px;
		background: #2fb56b;
		z-index: 9;
		color: #fff;
		font-weight: 600;
		font-size: 11px;
		right: 12px;
		cursor: pointer;
	}

	.filepond--file {
		overflow: hidden;
	}

	.file-browser-single {
		background: rgb(218, 218, 218);
		padding: 10px;

		.file-browser-image {
			position: relative;

			&:after {
				position: absolute;
				content: "";
				width: 100%;
				height: 50px;
				background: rgb(0, 0, 0);
				background: -moz-linear-gradient(0deg, rgba(0, 0, 0, 1) 25%, rgba(0, 212, 255, 0) 100%);
				background: -webkit-linear-gradient(0deg, rgba(0, 0, 0, 1) 25%, rgba(0, 212, 255, 0) 100%);
				background: linear-gradient(0deg, rgba(0, 0, 0, 1) 25%, rgba(0, 212, 255, 0) 100%);
				filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000", endColorstr="#00d4ff", GradientType=1);
				bottom: 0;
				pointer-events: none;
				opacity: 0.5;
			}

			&:before {
				position: absolute;
				content: "";
				width: 100%;
				height: 50px;
				background: rgb(0, 0, 0);
				background: -moz-linear-gradient(180deg, rgba(0, 0, 0, 1) 25%, rgba(0, 212, 255, 0) 100%);
				background: -webkit-linear-gradient(180deg, rgba(0, 0, 0, 1) 25%, rgba(0, 212, 255, 0) 100%);
				background: linear-gradient(180deg, rgba(0, 0, 0, 1) 25%, rgba(0, 212, 255, 0) 100%);
				filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000", endColorstr="#00d4ff", GradientType=1);
				top: 0;
				pointer-events: none;
				opacity: 0.5;
			}

			.close {
				position: absolute;
				left: 10px;
				top: 10px;
				width: 24px;
				height: 24px;
				background: rgba(0, 0, 0, 0.5);
				font-size: 9px;
				text-align: center;
				line-height: 23px;
				border-radius: 100%;
				padding-left: 1px;
				color: #fff;
				cursor: pointer;
				border: 1px solid transparent;
				@include t(all, .7s);

				&:hover {
					border: 1px solid #fff;
				}
			}

			&.disabled {
				.file-browser-name {
					left: 20px;
				}
			}

			.file-browser-name {
				position: absolute;
				top: 14px;
				left: 40px;
				text-overflow: ellipsis;
				overflow: hidden;
				width: 65%;
				color: rgb(238, 238, 238);
				font-weight: 500;
				font-size: 11px;
				white-space: nowrap;
				cursor: pointer;
			}

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				display: block;
				max-height: 250px;
			}
		}
	}

	.file-browser-description {
		font-weight: 500;
		font-size: 12px;
		margin-top: 5px;
	}

	.file-browser-error {
		background: #ffe1e1;
		padding: 10px;
		font-weight: 400;
	}

	.file-browser-information {
		background: #fffbeb;
		padding: 7.5px;
		margin-bottom: 5px;
		font-size: 12px;
		font-weight: 400;
		display: flex;
		align-items: center;
		font-family: $font;

		i {
			margin-right: 5px;
		}
	}
}

.file-browser-multiple {
	background: #f7f7f7;
	margin-top: 10px;
	padding: 10px;

	&>.title {
		font-weight: 600;
		font-size: 13px;
		margin-bottom: 5px;
	}

	.file-browser-attachment {
		display: flex;
		padding: 7.5px;
		background: #ffffff;
		border: 1px solid #e3e3e3;
		align-items: center;
		margin-bottom: 5px;

		&:last-child {
			margin-bottom: 0px;
		}

		&>i {
			margin-right: 5px;
		}

		.file-browser-name {
			font-weight: 500;
			font-size: 12px;
			cursor: pointer;
			text-decoration: underline;
		}

		.close {
			display: flex;
			align-items: center;
			font-size: 12px;
			margin-left: auto;
			margin-right: 2px;
			cursor: pointer;
		}
	}
}

.react-datepicker {
	&.hide-calendar {
		display: none !important;
	}
}

.custom-date-picker {
	&.is-error {
		input {
			border-color: $red-color;
		}
	}

	&.is-disabled {
		input {
			border-color: #d3d3d3 !important;
			background: #f0f0f0 !important;
			pointer-events: none;
		}
	}

	.react-datepicker-wrapper {
		width: 100%;
	}

	input {
		font-size: 13px;
		font-weight: 400;
		outline: 0;
		background: 0 0;
		font-family: "Roboto", sans-serif;
		border: 1px solid #d3d3d3;
		height: 38px;
		padding: 0px 10px;
		color: black;
		width: 100%;
	}
}

.mask-input {
	input {
		font-size: 13px;
		font-weight: 400;
		outline: 0;
		background: 0 0;
		font-family: $font;
		border: 1px solid #d3d3d3;
		height: 38px;
		background: #fff;
		padding: 0px 10px;
		color: rgb(0, 0, 0);
		width: 100%;
	}

	&.is-error {
		input {
			border-color: $red-color
		}
	}

	&.is-disabled {
		input {
			border-color: #d3d3d3 !important;
			background: #f0f0f0 !important;
			pointer-events: none;
		}
	}
}

.error-list {
	margin-top: 15px;

	.error-item {
		font-size: 13px;
		color: #9e2d2d;
		font-weight: 500;
		position: relative;
		padding-left: 10px;

		&:before {
			content: "";
			position: absolute;
			width: 4px;
			height: 4px;
			background-color: #9e2d2d;
			left: 0;
			top: 7.5px;
		}
	}
}

.fa-FileUpload-labels {
	padding: 5px 0px;

	.col {
		font-size: 12px;
		color: #3e3e3e;
		padding: 0px;

		.title {
			font-weight: 600;
		}

		.text {
			font-style: italic;
			font-weight: 500;
		}

		&.m-left-auto {
			text-align: right;
		}
	}
}

.ms-Modal {
	.ms-Overlay {
		background-color: rgba(0, 0, 0, 0.8);
	}
}

.form-value-list {
	margin-bottom: 10px;

	&.not-found {
		background: #f7f7f7;
		padding: 10px;
		font-size: 13px;
		font-style: italic;
	}

	.form-value-item {
		background: #f7f7f7;
		margin-bottom: 5px;
		padding: 5px 10px 10px 10px;
		border: 1px solid #ececec;

		&>div {
			padding: 0px 5px;
		}

		.remove {
			align-self: flex-end;
			padding: 8px;
			font-size: 12px;
			cursor: pointer;
			max-width: 30px;
			flex: 0 0 30px;
			text-align: center;
		}
	}

	button {
		margin-top: 5px;
	}

	.ms-ComboBox-CaretDown-button {
		margin-top: 0px !important;
	}

	.ms-Label {
		margin-bottom: 1px !important;
	}
}

.tox-statusbar {
	display: none !important;
}

.custom-contextual-item {
	padding: 5px;
	border-bottom: 1px solid #ececec;
	font-size: 12px;
	font-weight: 500;
	cursor: pointer;
	@include t(all, .25s);

	&.disabled {
		opacity: 0.6;
		pointer-events: none;
		background: #f3f3f3;
	}

	&:hover {
		background: #f3f3f3;
	}

	.status-bar {
		display: block;
		color: #ea7226;
		display: flex;
		align-items: center;
		font-weight: 500;
		font-size: 10.5px;
		font-style: italic;
		cursor: pointer;

		&.status-bar-green {
			color: #33926e;
		}

		&.status-bar-red {
			color: #923333;
		}
	}
}


.auth-page {
	position: relative;
	width: 100%;
	background: #fff;
	right: 0;
	margin: 0 auto;
	box-shadow: 0px 0px 6px -2px rgba(0, 0, 0, 0.39);
	align-items: center;
	display: flex;
	flex-direction: column;
	opacity: 0;
	// transform: translateY(30px);
	// transition: 400ms;

	&.modal-form {
		background: #fff;
		box-shadow: inherit;
		margin-top: 15px;
	}

	&.current {
		opacity: 1;
		//   transform: translateY(0);
	}

	&-target {
		width: 100%;
		min-height: 100vh;
		display: flex;
		align-items: center;
		overflow: hidden;
		position: relative;
		background: url("/assets/images/home-page.png");
		background-size: cover;
		background-attachment: fixed;

		&:before {
			position: absolute;
			left: 0;
			width: 100%;
			background: rgba(0, 0, 0, 0.62);
			height: 100%;
			content: "";
			top: 0;
			z-index: 0;
		}
	}

	&-header {
		width: 100%;
		padding: 40px;
		text-align: center;
		border-bottom: 1px solid #ebebeb;

		img {
			width: 40%;
			height: auto;
			margin: 0 10px;
			background: #002481;
			padding: 20px;
			border-radius: 30px;
		}
	}

	&-description {
		width: 100%;
		font-size: 14px;
		color: #1f1f1f;
		margin-bottom: 20px;
		margin-top: 20px;
		text-align: center;
	}

	&-brand {
		text-align: center;
		color: #2d2d2d;
		font-weight: 600;
		font-size: 18px;
		padding-top: 15px;
		font-family: $font;

		strong {
			display: block;
			font-size: 13px;
			font-weight: 600;
			color: #c85454;
			line-height: 20px;
		}

		a {
			font-size: 13px;
			color: #1077bf;
			display: block;
			line-height: 20px;
		}
	}

	&-content {
		width: 100%;
		padding: 35px;
	}

	.form-title {
		font-size: 15px;
		border-bottom: 1px solid #ececec;
		font-weight: 600;
		padding: 10px 0px;
	}

	&-footer {
		width: 100%;
		display: block;
		margin-top: 16px;

		button {
			background: #1077bf;
			color: #fff !important;
			text-align: center;
			border-radius: 0px;
			margin-top: 15px;
			border: none;
			font-weight: 600;
			font-family: inherit !important;
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;
			outline: none;
			cursor: pointer;
			width: 100%;
			height: 38px;
			line-height: 38px;
			font-size: 14px;
			border: 1px solid #0c6aad !important;
			@include t(all, 0.2s);
			height: auto;
			position: relative;

			&.passive {
				pointer-events: none;
				background: #828282;
				border: 1px solid #767676 !important;
				opacity: 0.6;
			}

			.loader {
				background: rgba(0, 0, 0, 0.88) !important;
			}

			&:hover {
				background: transparent !important;
				cursor: pointer;
				color: #0c6aad !important;
			}
		}

		.information {
			width: 100%;
			text-align: center;
			font-size: 14px;
			color: #555;
			padding: 12px 0px;
			text-align: center;
			justify-content: center;
			flex-direction: column;

			.text {
				padding: 12px 0px;
				display: block;
				position: relative;

				.inner-text {
					z-index: 1;
					background: #fff;
					position: relative;
					padding: 0px 10px;
				}

				&:before {
					content: "";
					position: absolute;
					background: #ebebeb;
					left: 0;
					width: 100%;
					height: 1px;
					top: 50%;
					margin-top: -0.5px;
					z-index: 0;
					pointer-events: none;
				}
			}

			a {
				color: #fff;
				background: $green-color;
				height: 38px;
				padding: 0px 20px;
				line-height: 38px;
				font-weight: 600;
				font-size: 13px;
				display: inline-block;
				margin-top: 10px;
				border: 1px solid transparent;
				@include t(all, 0.35s);

				&.disabled {
					pointer-events: none;
					background: #828282;
					border: 1px solid #767676 !important;
					opacity: 0.6;
				}

				&:hover {
					background: transparent;
					border: 1px solid $green-color;
					color: $green-color;
				}
			}
		}
	}

	&-title {
		color: #2d2d2d;
		font-size: 18px;
		font-weight: 800;
		margin-bottom: 0;
		letter-spacing: -0.5px;
		margin-bottom: 20px;
	}

	&-navs {
		width: 100%;
		display: flex;
		flex-direction: row;

		button {
			width: 100%;
			display: block;
			border: 0;
			font-size: 16px;
			font-weight: 400;
			align-items: center;
			height: 130px;
			padding: 10px;
			cursor: pointer;
			text-align: center;
			background-color: transparent;
			font-family: $font;
			border-top: 1px solid #ebebeb;
			border-bottom: 1px solid #ebebeb;

			&.disabled {
				opacity: 0.75;
				pointer-events: none;
				background: #f5f4f4;
				color: #737373;
			}

			&:not([disabled]):hover {
				background-color: #ebebeb;
			}

			&:not([disabled]).active {
				background-color: #dbdbdb;
				font-weight: 500;
			}

			&:not(:first-child) {
				border-left: 1px solid #ebebeb;
			}

			i {
				font-size: 20px;
				margin-bottom: 13px;
				display: block;
			}

			img {
				width: 30px;
				display: block;
				margin: 0px auto 7.5px auto;
			}

			.spn-1 {
				font-size: 14px;
				display: block;
				font-weight: 500;
				margin-bottom: 7px;
			}

			.spn-2 {
				display: block;
				font-size: 12px;
			}
		}
	}

	&-back {
		position: absolute;
		border: 0;
		top: 54px;
		padding: 10px 15px;
		background-color: #ebebeb;
		cursor: pointer;
		border-radius: 3px;

		&:hover {
			background-color: #dbdbdb;
		}

		i {
			margin-right: 5px;
		}
	}

	&-links {
		text-align: center;
		margin-top: 1rem;

		p {
			color: #444;
			margin: 0 0 .5rem 0;
			display: block;

			a {
				color: #000;

				&:hover {
					color: #000;
				}
			}
		}

		a {
			font-size: 13px;
			color: #1077bf;
			margin: 0 5px;
			font-weight: 600;
			@include t(all, .25s);
			position: relative;

			&.more {
				padding: 0px 5px;

				&.no-border {
					&:after {
						display: none;
					}
				}

				&:after {
					position: absolute;
					left: -8.5px;
					content: "";
					width: 5px;
					height: 5px;
					background: #c7c7c7;
					border-radius: 100%;
					top: 5px;
				}
			}

			&:hover {
				color: #4389b8;
				text-decoration: underline;
			}

			&.faq {
				color: #fff;
				display: inline-block;
				background: #1077bf;
				border: 1px solid #1077bf;
				padding: 10px;
				margin-top: 6px;

				&:hover {
					color: #1077bf;
					background: transparent;
				}
			}
		}
	}

	.form-item {
		margin-top: 16px;

		&:first-child {
			margin-top: 0px;
		}

		&.error .ms-Dropdown-container.custom-dropdown .ms-Dropdown .ms-Dropdown-title,
		&.error .textfield-file,
		&.error input,
		&.error .ms-BasePicker-text {
			border-color: #a54141 !important;
		}

		.error-message {
			font-size: 12px;
			font-weight: 500;
			margin-top: 8px;
			display: block;
			color: #a54141;
		}

		.message {
			font-size: 12px;
			font-weight: 400;
			margin-top: 8px;
			display: block;
			color: #545454;
		}

		&>label {
			display: block;
			font-weight: 500;
			margin-bottom: 8px;
			font-size: 13px;

			span {
				display: block;
				font-size: 12px;
				font-weight: 400;
			}
		}
	}

	.textfield-file {
		position: relative;
		display: block;
		border: 1px solid #d3d3d3;

		i {
			margin: 0 12px;
		}

		span {
			font-size: 13px;
			line-height: 36px;
		}

		input {
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			opacity: 0;
			width: 100%;
			z-index: 10;
			cursor: pointer;
		}
	}

	.fa-Grid {
		padding: 0;
		margin: 0 -7.5px;
	}

	.verification-code {
		margin: 24px 0;
		width: auto !important;

		&>div {
			display: flex;
			flex-direction: row;
			align-items: center;
		}

		input[type="tel"] {
			font-family: "Roboto", sans-serif;
			font-size: 20px;
			padding: 5px;
			border: solid 1px #a8adb7 !important;
			border-radius: 6px !important;

			&:focus {
				border-color: #006fff !important;
			}

			&:not(:first-child) {
				margin-left: 8px;
			}
		}
	}

	.form-buttons {
		text-align: right;
		justify-content: flex-end;
		position: absolute;
		bottom: 0;
		padding: 15px;
		background: #eaeaea;
		width: 100%;
		left: 0;
		border-top: 1px solid #d9d9d9;

		button {
			margin-left: 5px;
			border: none;
			background: #ececec;
			border: 1px solid #d1d1d1;
			font-weight: 500;
			font-family: $font;
			padding: 5px 15px;
			font-size: 11.5px;
			cursor: pointer;
			@include t(all, 0.2s);

			&:hover {
				background: #d1d1d1;
			}

			&.primary {
				background: #3787e4;
				color: #fff;
				border: 1px solid #2d6ebb;

				&:hover {
					background: #2d6ebb;
				}
			}
		}
	}

	.login-message {
		position: relative;
		bottom: 0;
		left: 0;
		background: #ececec;
		width: 100%;
		padding: 12.5px 15px;
		font-size: 13px;
		text-align: center;
		font-weight: 600;
		opacity: 0;
		margin-top: 10px;
		margin-bottom: -20px;
		@include t(all, 0.15s);
		pointer-events: none;

		i {
			font-size: 15px;
			position: relative;
			top: 1px;
			margin-right: 5px;
		}

		&.success {
			background: #33aa5b;
			color: #ffffff;
		}

		&.error {
			background: #a54141;
			color: #ffffff;
		}

		&.active {
			opacity: 1;
		}
	}
}

@media (max-width: 749px) {
	.auth-page-back {
		left: 20px;
		padding: 9px 12px;
	}

	.auth-page-header {
		text-align: right;
	}

	.auth-page-navs {
		flex-direction: column;
	}
}

.form-accordion {
	&>.title {
		background: #ffffff !important;
		padding: 10px;
		align-items: center;
		border: 1px solid #d7d5d5;
		font-weight: 600;
		cursor: pointer;

		&::selection {
			background: none;
		}

		&.passive {
			i {
				@include r(180deg);
			}
		}

		i {
			margin-left: auto;
			@include t(all, 0.25s);
		}
	}

	.accordion-content {
		background: #fff;
	}
}

table {
	border-collapse: collapse;
	border: 1px solid rgb(135, 135, 135);

	td,
	th {
		border: 1px solid rgb(135, 135, 135);
	}

	&.basic {
		p {
			padding: 0px 5px;
		}
	}
}

.auth-error {
	height: 100vh;
	display: flex;
	align-items: center;
	overflow: hidden;
	position: relative;
	background: url("../images/home-page.png");
	background-size: cover;

	&:before {
		position: absolute;
		left: 0;
		width: 100%;
		background: rgba(0, 0, 0, .62);
		height: 100%;
		content: "";
		top: 0;
		z-index: 0;
	}

	&-wrapper {
		max-width: 350px;
		margin: 0px auto;
		box-shadow: 0 0 6px -2px rgba(0, 0, 0, 0.39%);
		background: #fff;
		position: relative;
		z-index: 9;
	}

	&-title {
		border-bottom: 1px solid #ebebeb;
		padding: 25px 30px;
		font-weight: 700;
		display: flex;
		align-items: center;
		font-size: 16px;

		i {
			padding-right: 10px;
		}
	}

	&-text {
		padding: 10px 30px 30px 30px;
		font-size: 13.5px;
	}

	button.custom-button {
		font-size: 12px !important;
		margin-top: 10px;
		color: #000;

		i {
			font-size: 13px !important;
			color: #000;
		}
	}
}

.w100 {
	width: 100%;
}

.theme-text-item {
	background: #fafafa;
	margin-bottom: 10px;
	padding: 5px 10px 7.5px 10px;

	&:last-child {
		margin-bottom: 15px;
	}

	.remove {
		align-self: flex-end;
		padding-bottom: 12px;
		padding-left: 10px;
		cursor: pointer;
	}

	.col {
		padding: 0px 5px;
	}
}

.d-none {
	display: none;
}