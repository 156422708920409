@import "reset", "mixins", "variables";

/** FA Label **/

.fa-Label {
	font-weight: 600;
	display: flex;
	align-items: center;
	color: $dark-color;
	font-family: $font;
	font-size: 11.5px;
	clear: both;
	margin: 5px 0px;
	width: 100%;
	&.left {
		justify-content: flex-start;
	}
	&.right {
		justify-content: flex-end;
	}
	&.center {
		justify-content: center;
	}
	i {
		padding-right: 5px;
	}
}

/** FA TextField **/
.fa-TextField {
	font-family: $font;
	position: relative;
	@include t(all, 0.2s);
	font-size: 12px;
	padding: 5px;
	width: 100%;
	.fa-Textfield-wrapper {
		position: relative;
	}
	.fa-Label {
		flex: 0 0 100%;
		-ms-flex: 0 0 100%;
	}
	input,
	textarea {
		width: 100%;
		border: none;
		height: 30px;
		border-radius: 0px;
		-webkit-border-radius: 0px;
		-moz-border-radius: 0px;
		-o-border-radius: 0px;
		-webkit-appearance: none;
		font-size: 0.95em;
		padding: 5px;
		font-family: $font;
		background: #fff;
		border: 1px solid rgb(165, 165, 165);
		@include t(all, 0.1s);
		&:focus,
		&:hover {
			border: 1px solid $blue-color;
		}
	}
	textarea {
		min-height: 70px;
		&.no-resizable {
			resize: none;
		}
	}
	.suffix,
	.prefix {
		right: 0;
		justify-content: center;
		height: 30px;
		display: flex;
		align-items: center;
		background: #f0f0f0;
		color: rgb(47, 47, 47);
		top: 0;
		font-size: 0.95em;
		padding: 0px 7.5px;
		flex-grow: 0;
		border: 1px solid rgb(179, 179, 179);
	}
	.prefix {
		right: inherit;
		left: 0;
		border-right: none;
	}
	.suffix {
		border-left: none;
	}
	.description {
		display: block;
		font-size: 0.9em;
		color: rgb(124, 124, 124);
		margin: 5px 0px 0px 0px;
		font-weight: 500;
		flex: 0 0 100%;
		-ms-flex: 0 0 100%;
	}
	.error-message {
		display: block;
		font-size: 0.9em;
		font-weight: 500;
		color: rgb(164, 38, 44);
		margin: 5px 0px 0px 0px;
		flex: 0 0 100%;
		-ms-flex: 0 0 100%;
	}
	&.has-error {
		input {
			border-color: rgb(164, 38, 44);
		}
	}
	&.disabled {
		input,
		textarea {
			background: #f0f0f0;
			pointer-events: none;
		}
	}
}

/** FA Checkbox **/

.fa-Checkbox {
	position: relative;
	font-size: 12px;
	padding: 5px;
	.fa-Label {
		position: relative;
		cursor: pointer;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		padding-left: 26.5px;
		align-items: center;
		font-weight: 500;
		margin: 7.5px 0px;
		display: inline-flex;
		.text {
			position: relative;
			top: 0.5px;
		}
	}
	input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;
		&:checked ~ .fa-Checkmark {
			background-color: $blue-color;
			border-color: $blue-color;
		}
		&:checked ~ .fa-Checkmark:after {
			display: block;
		}
		&:checked ~ .text {
			display: none;
		}
	}
	.fa-Checkmark {
		position: absolute;
		top: -4.5px;
		left: 0;
		height: 20px;
		width: 20px;
		background-color: rgb(241, 241, 241);
		border: 1px solid #c3c3c3;
		@include t(all, 0.1s);
		&:after {
			left: 7px;
			top: 4px;
			width: 4px;
			height: 9px;
			border: solid #fff;
			border-width: 0 2px 2px 0;
			@include t(all, 0.1s);
			@include r(45deg);
		}
	}
	.description {
		display: block;
		font-size: 0.9em;
		color: rgb(124, 124, 124);
		font-weight: 500;
		flex: 0 0 100%;
		-ms-flex: 0 0 100%;
	}
	.error-message {
		display: block;
		font-size: 0.9em;
		font-weight: 500;
		color: rgb(164, 38, 44);
		flex: 0 0 100%;
		-ms-flex: 0 0 100%;
	}
	&.has-error {
		.fa-Checkmark {
			border-color: rgb(164, 38, 44);
		}
	}
	&.disabled {
		pointer-events: none;
		.text {
			color: rgb(163, 163, 163);
		}
		.fa-Checkmark {
			pointer-events: none;
			background: #dbdbdb;
			border-color: #dbdbdb;
		}
	}
	&.read-only {
		pointer-events: none;
	}
}

.fa-Checkmark:after {
	content: "";
	position: absolute;
	display: none;
}

/** FA RadioGroup **/

.fa-RadioGroup {
	position: relative;
	font-size: 12px;
	padding: 5px;
	.fa-RadioList {
		&.row {
			.fa-Label {
				padding-right: 15px;
				width: auto;
				padding-left: 25px;
			}
		}
		.fa-Label {
			position: relative;
			cursor: pointer;
			-webkit-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
			padding-left: 26.5px;
			align-items: center;
			margin: 7.5px 0px;
			display: inline-flex;
			font-weight: 500;
			width: 100%;
			.text {
				position: relative;
				top: 1.5px;
			}
		}
	}

	input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;
		&:checked ~ .fa-Checkmark {
			background-color: $blue-color;
			border-color: $blue-color;
		}
		&:checked ~ .fa-Checkmark:after {
			display: block;
		}
		&:checked ~ .text {
			display: none;
		}
	}
	.fa-Checkmark {
		position: absolute;
		top: -2.5px;
		left: 0;
		height: 20px;
		width: 20px;
		background-color: rgb(241, 241, 241);
		border: 1px solid #c3c3c3;
		border-radius: 100%;
		@include t(all, 0.1s);
		&:after {
			left: 7px;
			top: 4px;
			width: 4px;
			height: 9px;
			border: solid #fff;
			border-width: 0 2px 2px 0;
			@include t(all, 0.1s);
			@include r(45deg);
		}
	}
	.description {
		display: block;
		font-size: 0.9em;
		color: rgb(124, 124, 124);
		font-weight: 500;
		flex: 0 0 100%;
		-ms-flex: 0 0 100%;
	}
	.error-message {
		display: block;
		font-size: 0.9em;
		font-weight: 500;
		color: rgb(164, 38, 44);
		flex: 0 0 100%;
		-ms-flex: 0 0 100%;
	}
	&.has-error {
		.fa-Checkmark {
			border-color: rgb(164, 38, 44);
		}
	}
	&.disabled {
		pointer-events: none;
		.text {
			color: rgb(163, 163, 163);
		}
		.fa-Checkmark {
			pointer-events: none;
			background: #dbdbdb;
			border-color: #dbdbdb;
		}
	}
	&.read-only {
		pointer-events: none;
	}
}

/** FA CheckboxGroup **/
.fa-CheckboxGroup {
	padding: 5px;
	.fa-CheckboxList {
		&.row {
			.fa-Label {
				padding-right: 15px;
				padding-left: 25px;
				width: auto;
			}
		}
		.fa-Label {
			margin-bottom: 0px;
		}
	}
	.description {
		display: block;
		font-size: 0.9em;
		color: rgb(124, 124, 124);
		font-weight: 500;
		flex: 0 0 100%;
		-ms-flex: 0 0 100%;
	}
	.error-message {
		display: block;
		font-size: 0.9em;
		font-weight: 500;
		color: rgb(164, 38, 44);
		flex: 0 0 100%;
		-ms-flex: 0 0 100%;
	}
}

/** FA Dropdown **/
.fa-Dropdown {
	font-size: 12px;
	font-family: $font;
	padding: 5px;
	position: relative;
	@include t(all, 0.2s);
	width: 100%;
	.fa-Dropdown-wrapper {
		position: relative;
		width: 100%;
		&.is-loading {
			pointer-events: none;
		}
	}
	.fa-Dropdown-selected {
		width: 100%;
		border: none;
		height: 30px;
		padding-right: 30px;
		border-radius: 0px;
		-webkit-border-radius: 0px;
		-moz-border-radius: 0px;
		-o-border-radius: 0px;
		-webkit-appearance: none;
		font-size: 0.95em;
		position: relative;
		display: flex;
		align-items: center;
		padding: 5px 7.5px 5px 7.5px;
		font-family: $font;
		background: #fff;
		border: 1px solid #a5a5a5;
		@include t(all, 0.1s);
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		cursor: pointer;
		text-indent: 32px;
		direction: rtl;
		text-align: left;
		justify-content: flex-end;
		&::selection {
			background: transparent;
		}
		&:before {
			content: "";
			position: absolute;
			right: 10px;
			top: 50%;
			width: 9px;
			height: 1px;
			background: rgb(58, 58, 58);
			@include r(-45deg);
		}
		&:after {
			content: "";
			position: absolute;
			right: 16px;
			top: 50%;
			width: 9px;
			height: 1px;
			background: rgb(58, 58, 58);
			@include r(45deg);
		}
		&:focus,
		&:hover {
			border: 1px solid $blue-color;
		}
	}
	.description {
		display: block;
		font-size: 0.9em;
		color: rgb(124, 124, 124);
		font-weight: 500;
		flex: 0 0 100%;
		-ms-flex: 0 0 100%;
		margin: 5px 0px 0px 0px;
	}
	.error-message {
		display: block;
		font-size: 0.9em;
		font-weight: 500;
		color: rgb(164, 38, 44);
		flex: 0 0 100%;
		margin: 5px 0px 0px 0px;
		-ms-flex: 0 0 100%;
	}
	&.has-error {
		.fa-Dropdown-selected {
			border-color: rgb(164, 38, 44);
		}
	}
	&.disabled {
		pointer-events: none;
		.fa-Dropdown-selected {
			pointer-events: none;
			background: #f0f0f0;
			border-color: #a5a5a5;
		}
	}
	&.read-only {
		pointer-events: none;
	}
}

/** FA Dropdown Options **/
.fa-Dropdown-options {
	box-shadow: rgba(0, 0, 0, 0.133) 0 3.2px 7.2px 0, rgba(0, 0, 0, 0.11) 0 0.6px 1.8px 0;
	font-size: 11.5px;
	width: 100%;
	position: absolute;
	z-index: 99;
	background: #fff;
	top: 90%;
	opacity: 0;
	pointer-events: none;
	@include t(all, 0.1s);
	max-height: 200px;
	overflow-y: auto;
	&::-webkit-scrollbar {
		width: 2px;
	}

	&::-webkit-scrollbar-track {
		background: #e7e7e7;
	}

	&::-webkit-scrollbar-thumb {
		background: #bbbbbb;
	}
	@include t(all, 0.1s);
	&.active {
		opacity: 1;
		top: 100%;
		pointer-events: auto;
	}
	.fa-Dropdown-item {
		padding: 10px;
		cursor: pointer;
		border-bottom: 1px solid #eeeeee;
		font-weight: 500;
		@include t(all, 0.1s);
		&:hover {
			background: #f5f5f5;
		}
		&:last-child {
			border-bottom: none;
		}
	}
}

.fa-LayoutContainer {
	position: relative;
}

.fa-List {
	background: #f9f9f9;
	position: relative;
}

/** FA ComboBox **/
.fa-ComboBox {
	font-size: 12px;
	font-family: $font;
	padding: 5px;
	position: relative;
	@include t(all, 0.2s);
	width: 100%;
	.fa-ComboBox-wrapper {
		position: relative;
		width: 100%;
		&:before {
			content: "";
			position: absolute;
			right: 10px;
			top: 50%;
			width: 9px;
			height: 1px;
			background: rgb(58, 58, 58);
			@include r(-45deg);
		}
		&:after {
			content: "";
			position: absolute;
			right: 16px;
			top: 50%;
			width: 9px;
			height: 1px;
			background: rgb(58, 58, 58);
			@include r(45deg);
		}
	}
	input {
		width: 100%;
		border: none;
		height: 30px;
		border-radius: 0px;
		-webkit-border-radius: 0px;
		-moz-border-radius: 0px;
		-o-border-radius: 0px;
		-webkit-appearance: none;
		font-size: 0.95em;
		padding: 5px;
		font-family: $font;
		background: #fff;
		border: 1px solid rgb(165, 165, 165);
		@include t(all, 0.1s);
		&:focus,
		&:hover {
			border: 1px solid $blue-color;
		}
	}
	.fa-ComboBox-selected {
		width: 100%;
		border: none;
		height: 30px;
		padding-right: 30px;
		border-radius: 0px;
		-webkit-border-radius: 0px;
		-moz-border-radius: 0px;
		-o-border-radius: 0px;
		-webkit-appearance: none;
		font-size: 0.95em;
		position: relative;
		display: flex;
		align-items: center;
		padding: 5px 7.5px 5px 7.5px;
		font-family: $font;
		background: #fff;
		border: 1px solid #a5a5a5;
		@include t(all, 0.1s);
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		cursor: pointer;
		text-indent: 32px;
		direction: rtl;
		text-align: left;
		justify-content: flex-end;
		&::selection {
			background: transparent;
		}
		&:focus,
		&:hover {
			border: 1px solid $blue-color;
		}
	}
	.description {
		display: block;
		font-size: 0.9em;
		color: rgb(124, 124, 124);
		font-weight: 500;
		flex: 0 0 100%;
		-ms-flex: 0 0 100%;
		margin: 5px 0px 0px 0px;
	}
	.error-message {
		display: block;
		font-size: 0.9em;
		font-weight: 500;
		color: rgb(164, 38, 44);
		flex: 0 0 100%;
		margin: 5px 0px 0px 0px;
		-ms-flex: 0 0 100%;
	}
	&.has-error {
		input {
			border-color: rgb(164, 38, 44);
		}
	}
	&.disabled {
		pointer-events: none;
		input {
			pointer-events: none;
			background: #f0f0f0;
			border-color: #a5a5a5;
		}
	}
	&.read-only {
		pointer-events: none;
	}
}

/** FA ComboBox Options **/
.fa-ComboBox-options {
	box-shadow: rgba(0, 0, 0, 0.133) 0 3.2px 7.2px 0, rgba(0, 0, 0, 0.11) 0 0.6px 1.8px 0;
	font-size: 11.5px;
	width: 100%;
	position: absolute;
	z-index: 99;
	background: #fff;
	top: 90%;
	opacity: 0;
	pointer-events: none;
	@include t(all, 0.1s);
	max-height: 200px;
	overflow-y: auto;
	&::-webkit-scrollbar {
		width: 2px;
	}

	&::-webkit-scrollbar-track {
		background: #e7e7e7;
	}

	&::-webkit-scrollbar-thumb {
		background: #bbbbbb;
	}
	&.active {
		opacity: 1;
		top: 100%;
		pointer-events: auto;
	}
	.fa-ComboBox-item {
		padding: 10px;
		cursor: pointer;
		border-bottom: 1px solid #eeeeee;
		border-left: 3px solid transparent;
		font-weight: 500;
		@include t(all, 0.1s);
		&.active {
			background: #f9fcff;
			border-left: 3px solid $blue-color;
		}
		&:hover {
			background: #f5f5f5;
		}
		&:last-child {
			border-bottom: none;
		}
	}
}

.fa-Placeholder {
	color: #8f8f8f;
	&::selection {
		background: transparent;
	}
}

/** FA Button **/
.fa-Button {
	display: inline-block;
	padding: 5px;
	&.has-align {
		width: 100%;
		display: flex;
		&.left {
			justify-content: flex-start;
		}
		&.center {
			justify-content: center;
		}
		&.right {
			justify-content: flex-end;
		}
		&.justify {
			button {
				width: 100%;
				max-width: 100%;
			}
		}
	}
	&.info {
		button,
		a {
			background: $blue-color;
			border: 1px solid #1578d0;
			color: #fff;
			&:hover {
				background: #1578d0;
			}
			&:focus {
				background: #4498e2;
			}
		}
	}
	&.success {
		button,
		a {
			background: $green-color;
			border: 1px solid #217a4e;
			color: #fff;
			&:hover {
				background: #217a4e;
			}
			&:focus {
				background: #40a572;
			}
		}
	}
	&.danger {
		button,
		a {
			background: $red-color;
			border: 1px solid #9e1c1c;
			color: #fff;
			&:hover {
				background: #9e1c1c;
			}
			&:focus {
				background: #b63535;
			}
		}
	}
	&.warning {
		button,
		a {
			background: $yellow-color;
			border: 1px solid #ebb111;
			color: rgb(34, 34, 34);
			&:hover {
				background: #ebb111;
			}
			&:focus {
				background: #ffcc41;
			}
		}
	}
	button,
	a {
		max-width: 250px;
		padding: 10px 20px;
		border: none;
		font-weight: 600;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 11.5px;
		cursor: pointer;
		@include t(all, 0.1s);
		font-family: $font;
		i {
			margin-right: 6.5px;
		}
	}
}

/** FA Heading **/
.fa-Heading {
	font-family: $font;
	display: flex;
	padding: 5px;
	width: 100%;
	&.left {
		justify-content: flex-start;
	}
	&.right {
		justify-content: flex-end;
	}
	&.center {
		justify-content: center;
	}
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		margin: 5px 0px;
		color: #000;
		a {
			color: #000;
		}
	}
	h1 {
		font-size: 24px;
	}
	h2 {
		font-size: 21px;
	}
	h3 {
		font-size: 19px;
	}
	h4 {
		font-size: 17px;
	}
	h5 {
		font-size: 14px;
	}
	h6 {
		font-size: 13px;
	}
}

/** FA Paragraph **/
.fa-Paragraph {
	font-family: $font;
	line-height: 17.5px;
	padding: 5px;
	color: #000;
	a {
		color: #000;
	}
	&.left {
		text-align: left;
	}
	&.right {
		text-align: right;
	}
	&.center {
		text-align: center;
	}
	&.justify {
		text-align: justify;
	}
}

/** FA Image **/
.fa-Image {
	width: 100%;
	&.left {
		text-align: left;
	}
	&.center {
		text-align: center;
	}
	&.right {
		text-align: right;
	}
	&.justified {
		img {
			width: 100%;
		}
	}
	img {
		max-width: 100%;
		height: auto;
	}
	.fa-Heading {
		color: #000;
	}
	a {
		display: block;
	}
}

/** FA Video **/
.fa-Video {
	padding: 5px;
	iframe {
		border: none;
	}
}

/** FA Map **/
.fa-Map {
	padding: 5px;
	iframe {
		border: none;
	}
}

/** FA Image Box **/
.fa-ImageBox {
	margin: 10px -5px;
	padding: 5px;
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		color: $blue-color;
	}
	article {
		color: #000;
	}
	.fa-Button {
		margin: 10px 0px;
	}
	&.right {
		flex-direction: row-reverse !important;
		a {
			flex-direction: row-reverse !important;
		}
		article {
			div {
				text-align: left;
				justify-content: flex-start;
				&.fa-Button {
					float: right;
				}
			}
		}
	}
	&.right {
		flex-direction: row-reverse !important;
		article {
			div {
				text-align: right;
				justify-content: flex-end;
			}
		}
	}
	&.center {
		text-align: center;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		a {
			flex-direction: column;
			justify-content: center;
			align-items: center;
		}
		figure {
			margin: 10px 0px;
		}
		div {
			text-align: center;
			justify-content: center;
		}
	}
}

/** FA Share Buttons **/
.fa-ShareButtons {
	padding: 5px;
	&.rounded {
		.item {
			border-radius: 10px;
			overflow: hidden;
		}
	}
	&.circle {
		.item {
			border-radius: 100%;
			overflow: hidden;
		}
	}
	&.center {
		justify-content: center;
	}
	&.right {
		justify-content: flex-end;
	}
	&.flat {
		.item {
			.text {
				padding-left: 0px;
			}
			&.facebook {
				.icon,
				.text {
					background: #3b5998;
				}
			}
			&.twitter {
				.icon,
				.text {
					background: #1da1f2;
				}
			}
			&.pinterest {
				.icon,
				.text {
					background: #dc0000;
				}
			}
			&.linkedin {
				.icon,
				.text {
					background: #006da5;
				}
			}
		}
	}
	&.framed {
		.item {
			.text {
				padding-left: 0px;
			}
			&.facebook {
				border: 1px solid #3b5998;
				&:hover {
					background: #3b5998;
					div {
						color: #fff;
					}
				}
				.icon,
				.text {
					color: #3b5998;
					background: transparent;
				}
			}
			&.twitter {
				border: 1px solid #1da1f2;
				&:hover {
					background: #1da1f2;
					div {
						color: #fff;
					}
				}
				.icon,
				.text {
					color: #1da1f2;
					background: transparent;
				}
			}
			&.pinterest {
				border: 1px solid #dc0000;
				&:hover {
					background: #dc0000;
					div {
						color: #fff;
					}
				}
				.icon,
				.text {
					color: #dc0000;
					background: transparent;
				}
			}
			&.linkedin {
				border: 1px solid #006da5;
				&:hover {
					background: #006da5;
					div {
						color: #fff;
					}
				}
				.icon,
				.text {
					color: #006da5;
					background: transparent;
				}
			}
		}
	}
	.item {
		height: 32px;
		margin-right: 7.5px;
		@include t(all, 0.15s);
		&.facebook {
			.icon {
				background: #3b5998;
			}
			.text {
				background: #33508f;
			}
		}
		&.twitter {
			.icon {
				background: #1da1f2;
			}
			.text {
				background: #178dd6;
			}
		}
		&.pinterest {
			.icon {
				background: #dc0000;
			}
			.text {
				background: #be0404;
			}
		}
		&.linkedin {
			.icon {
				background: #006da5;
			}
			.text {
				background: #01547e;
			}
		}
		a {
			display: flex;
		}
		.icon {
			background: rgb(93, 93, 93);
			color: #fff;
			width: 32px;
			height: 100%;
			display: flex;
			align-items: center;
			font-size: 12px;
			justify-content: center;
			@include t(all, 0.15s);
		}
		.text {
			background: gray;
			display: flex;
			padding: 0px 15px;
			align-items: center;
			color: #fff;
			font-size: 13px;
			line-height: 32px;
			font-weight: 600;
			@include t(all, 0.15s);
		}
	}
}

/** FA Slider **/
.fa-Slider {
	padding: 5px;
	.fa-Slider-item {
		position: relative;
		.fa-Slider-figure,
		.fa-Slider-content {
			height: 100%;
		}
		.fa-Slider-content {
			display: flex;
			flex-direction: column;
			position: absolute;
			width: 100%;
			height: 100%;
			left: 0;
			top: 0;
			@include t(all, 0.45s);

			&.animation-down {
				opacity: 0;
				top: 100px;
			}

			&.animation-up {
				opacity: 0;
				top: -100px;
			}

			&.animation-left {
				opacity: 0;
				left: -100px;
			}

			&.animation-right {
				opacity: 0;
				left: 100px;
			}

			&.animation-zoom {
				opacity: 0;
				transform-origin: center center;
				@include s(0);
			}

			.title {
				font-size: 21px;
				font-weight: 600;
			}
			.content {
				margin: 10px 0px;
			}
		}
		.fa-Slider-figure {
			position: relative;
			display: block;
			color: #000;
			img {
				width: 100%;
				height: auto;
			}
		}
	}

	.slick-list {
		.slick-slide {
			&.slick-active {
				.fa-Slider-content {
					&.animation-down,
					&.animation-up {
						top: 0px;
						opacity: 1;
						transition-delay: 0.5s;
					}
					&.animation-left,
					&.animation-right {
						left: 0px;
						opacity: 1;
						transition-delay: 0.5s;
					}
					&.animation-zoom {
						opacity: 1;
						transition-delay: 0.5s;
						@include s(1);
					}
				}
			}
		}
	}

	.slick-dots {
		position: absolute;
		bottom: 15px;
		li {
			margin: 0px 2.5px;
			width: 12px;
			height: 12px;
			&.slick-active {
				button {
					background: #efefef !important;
				}
			}
			button {
				background: transparent;
				border: 1px solid #efefef;
				width: 12px;
				height: 12px;
				border-radius: 100%;
				@include t(all, 0.25s);
				&:before {
					display: none;
				}
			}
		}
	}

	.slick-arrow {
		&:before {
			font-size: 25px;
		}
	}

	.slick-prev {
		left: 15px;
		z-index: 9;
	}
	.slick-next {
		right: 15px;
		z-index: 9;
	}
}

/** FA Avatar **/
.fa-Avatar {
	width: 45px;
	height: 45px;
	position: relative;
	border-radius: 100%;
	background: #d6d6d6;
	color: #151515;
	font-weight: 700;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 14px;
	font-family: $font;
}

/** FA Badge **/
.fa-Badge {
	background: #ccc;
	display: inline-block;
	font-size: 10.5px;
	font-weight: 500;
	color: #000;
	a,
	span {
		color: #000;
		padding: 2.5px 15px;
		display: block;
	}
	&.primary {
		background: $blue-color;
		color: #fff;
		a,
		span {
			color: #fff;
		}
	}
}

/** FA Cards **/
.fa-Cards {
	&:hover {
		.slick-arrow {
			opacity: 1;
			margin-top: 0px;
			pointer-events: auto;
			transition-delay: 1s;
		}
	}
	.slick-slide {
		padding: 7.5px;
		.fa-Card-item {
			padding: 0px;
		}
	}
	.slick-dots {
		position: relative;
		bottom: 0px;
		margin: 0px 0px 10px 0px;
		li {
			margin: 0px 2.5px;
			width: 13px;
			height: 13px;
			&.slick-active {
				button {
					background: #b3b3b3 !important;
				}
			}
			button {
				background: transparent;
				border: 1px solid #b8b8b8;
				width: 13px;
				height: 13px;
				border-radius: 100%;
				@include t(all, 0.15s);
				&:before {
					display: none;
				}
			}
		}
	}

	.slick-arrow {
		@include t(all, 0.2s);
		opacity: 0;
		pointer-events: none;
		margin-top: -30px;
		&:before {
			font-size: 30px;
		}
	}

	.slick-prev {
		left: 25px;
		z-index: 9;
	}
	.slick-next {
		right: 25px;
		z-index: 9;
	}
}

/** FA Card Item **/
.fa-Card-item {
	padding: 5px;
	margin: 10px 0px;
	.fa-Card-item-wrapper {
		background: #fff;
		figure {
			position: relative;
			img {
				margin-bottom: -3px;
			}
			&:after {
				pointer-events: none;
				content: "";
				left: 0;
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				background: rgb(0, 0, 0);
				background: linear-gradient(0deg, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0) 100%);
			}
			* {
				&::selection {
					background: transparent;
				}
			}
		}
		.article {
			padding: 15px;
			color: #000;
			word-break: break-word;
		}
	}
	.fa-Card-clickable {
		display: block;
	}
	&.cardOne {
		.fa-Card-item-wrapper {
			-webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
			box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
			@include t(all, 0.25s);
			&:hover {
				-webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.3);
				box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.3);
			}
		}
		.fa-Avatar {
			top: -40px;
			margin-bottom: -35px;
		}

		.fa-Badge {
			position: absolute;
			right: 15px;
			top: 10px;
		}
	}

	&.cardTwo {
		figure {
			&:after {
				top: inherit;
				bottom: 0;
				@include r(180deg);
			}
			img {
				margin-bottom: -3px;
			}
		}
		.fa-Card-item-wrapper {
			-webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
			box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
			display: flex;
			flex-direction: column-reverse;
			@include t(all, 0.25s);
			&:hover {
				-webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.3);
				box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.3);
			}
		}
		.fa-Avatar {
			bottom: -40px;
			margin-top: -25px;
		}

		.fa-Badge {
			position: absolute;
			right: 15px;
			bottom: 10px;
		}

		.fa-Meta-text {
			background: transparent;
			border: none;
			padding: 0px;
		}
	}

	&.cardThree {
		figure {
			padding: 0px;
			img {
				margin-bottom: -3px;
			}
			&:after {
				display: none;
			}
		}
		.fa-Meta-text {
			background: transparent;
			border: none;
			padding: 5px 0px;
		}
		.fa-Card-item-wrapper {
			-webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
			box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-ms-flex-wrap: wrap;
			flex-wrap: wrap;
			@include t(all, 0.25s);
			&:hover {
				-webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.3);
				box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.3);
			}
		}
		.fa-Avatar {
			bottom: 10px;
			position: absolute;
			left: 10px;
			width: 25px;
			height: 25px;
			font-size: 10px;
			z-index: 1;
		}

		.fa-Heading {
			margin-top: 10px;
			* {
				margin-bottom: 0px !important;
			}
		}

		.fa-Badge {
			position: absolute;
			right: 0px;
			top: 0px;
			a,
			span {
				padding: 2px 10px;
				font-size: 9.5px;
			}
		}
	}
	&.cardFour {
		figure {
			padding: 0px;
			img {
				margin-bottom: -3px;
			}
			&:after {
				display: none;
			}
		}
		.fa-Meta-text {
			background: transparent;
			border: none;
			padding: 5px 0px;
		}
		.fa-Card-item-wrapper {
			-webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
			box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-ms-flex-wrap: wrap;
			flex-wrap: wrap;
			flex-direction: row-reverse;
			-ms-flex-direction: row-reverse;
			@include t(all, 0.25s);
			&:hover {
				-webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.3);
				box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.3);
			}
		}
		.fa-Avatar {
			bottom: 10px;
			position: absolute;
			right: 10px;
			width: 25px;
			height: 25px;
			font-size: 10px;
			z-index: 1;
		}

		.fa-Heading {
			* {
				margin-bottom: 0px !important;
			}
		}

		.fa-Badge {
			left: 0px;
			top: 0px;
			a,
			span {
				padding: 2px 10px;
				font-size: 9.5px;
			}
		}
	}
	&.cardFive {
		figure {
			padding: 0px;
			width: 100%;
			img {
				margin-bottom: -3px;
			}
			&:after {
				top: inherit;
				bottom: 0;
			}
			.fa-Image {
				width: 100px;
				height: 100px;
				border-radius: 100%;
				overflow: hidden;
				border: 1px solid #ececec;
				position: relative;
				display: flex;
				align-items: center;
				justify-content: center;
				margin: 15px auto;
			}
			&:after {
				display: none;
			}
		}
		.fa-Meta-text {
			background: transparent;
			border: none;
			padding: 5px 0px;
		}
		.article {
			text-align: center;
			padding-top: 0px;
		}
		.fa-Card-item-wrapper {
			-webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
			box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-ms-flex-wrap: wrap;
			flex-wrap: wrap;
			@include t(all, 0.25s);
			&:hover {
				-webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.3);
				box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.3);
			}
		}

		.fa-Heading {
			justify-content: center;
			* {
				margin-bottom: 0px !important;
			}
		}

		.fa-Paragraph {
			text-align: center;
		}

		.fa-Badge {
			left: 0px;
			top: 0px;
			a,
			span {
				padding: 2px 10px;
				font-size: 9.5px;
			}
		}
	}
	&.cardSix {
		figure {
			padding: 0px;
			width: 100%;
			img {
				margin-bottom: -3px;
			}
			&:after {
				top: inherit;
				bottom: 0;
			}
			.fa-Image {
				width: 100px;
				height: 100px;
				border-radius: 100%;
				overflow: hidden;
				border: 1px solid #ececec;
				position: relative;
				display: flex;
				align-items: center;
				justify-content: center;
				margin: 15px auto;
			}
			&:after {
				display: none;
			}
		}
		.fa-Meta-text {
			background: transparent;
			border: none;
			padding: 5px 0px;
		}
		.article {
			padding-bottom: 0px;
			text-align: center;
		}
		.fa-Card-item-wrapper {
			-webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
			box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-ms-flex-wrap: wrap;
			flex-wrap: wrap;
			flex-direction: column-reverse;
			-ms-flex-direction: column-reverse;
			@include t(all, 0.25s);
			&:hover {
				-webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.3);
				box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.3);
			}
		}

		.fa-Heading {
			justify-content: center;
			* {
				margin-bottom: 0px !important;
			}
		}

		.fa-Paragraph {
			text-align: center;
		}

		.fa-Badge {
			left: 0px;
			top: 0px;
			a,
			span {
				padding: 2px 10px;
				font-size: 9.5px;
			}
		}
	}
}

/** FA Meta Text **/
.fa-Meta-text {
	padding: 7.5px 12.5px;
	border-top: 1px solid #f1f1f1;
	font-size: 11px;
	background: #fbfbfb;
	color: #a9a9a9;
	font-family: $font;
}

/** FA Icon **/
.fa-Icon {
	display: block;
	&.left {
		text-align: left;
	}
	&.right {
		text-align: right;
	}
	&.center {
		text-align: center;
	}
}

/** FA Icon List **/
.fa-Icon-list {
	padding: 5px;
	flex-direction: column;
	&.display-inline {
		flex-direction: row;
	}
	&.left {
		justify-content: flex-start;
	}
	&.right {
		justify-content: flex-end;
	}
	&.center {
		justify-content: center;
	}
	.fa-Icon-item {
		padding: 2.5px 5px;
		align-items: center;
		.text {
			color: #000;
			a {
				color: #000;
			}
			font-weight: 500;
		}
	}
}

/** FA Menu **/
.fa-Menu {
	width: 100%;
	z-index: 1;
	padding: 5px;
	position: relative;
	background-size: cover;
	background-repeat: no-repeat;
	&.left {
		& > ul {
			justify-content: flex-start;
		}
	}
	&.right {
		& > ul {
			justify-content: flex-end;
		}
	}
	&.center {
		& > ul {
			justify-content: center;
		}
	}
	&.justify {
		& > ul {
			justify-content: space-between;
		}
	}
	ul {
		margin: 0px;
	}
	&.horizontal {
		ul {
			padding: 0px;
			li {
				position: relative;
				&:hover {
					& > ul {
						top: 100%;
						opacity: 1;
						pointer-events: auto;
					}
				}
				&:first-child {
					a {
						border-left: none;
					}
				}
				& > a,
				& > div {
					display: flex;
					align-items: center;
					font-weight: 500;
					cursor: pointer;
					color: #000;
					padding: 15px;
					@include t(all, 0.15s);
					border-left: 1px solid rgba(101, 101, 101, 0.1);
					& > .fa-Icon {
						padding: 0px 4px 0px 0px !important;
					}
					&:hover {
						color: $blue-color;
					}
				}
				ul {
					position: absolute;
					background-color: rgba(39, 39, 39, 0.95);
					z-index: 99;
					min-width: 200px;
					@include t(all, 0.15s);
					top: calc(100% + 5px);
					pointer-events: none;
					opacity: 0;
					left: 0;
					box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.54);
					li {
						&:last-child {
							& > a,
							& > div {
								border-bottom: none;
							}
						}
						& > a,
						& > div {
							font-size: 11px;
							border-bottom: 1px solid rgba(255, 255, 255, 0.05);
							border-left: none;
							color: #fff;
							padding: 5px 10px;
							&:hover {
								background-color: rgba(104, 104, 104, 0.3);
								color: #fff;
							}
							.fa-Triangle {
								@include r(-90deg);
								margin-left: auto;
								.line {
									background: #fff;
									width: 4px;
								}
							}
						}
						&:hover {
							& > ul {
								top: 0;
							}
						}
						ul {
							left: 100%;
							top: 10px;
						}
					}
				}
			}
		}
		& > ul {
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-ms-flex-wrap: wrap;
			flex-wrap: wrap;
		}
	}
	&.vertical {
		& > ul {
			padding-left: 0px;
		}
		ul {
			li {
				&:last-child {
					& > a,
					& > div {
						border-bottom: none;
					}
				}
				& > div,
				& > a {
					display: flex;
					align-items: center;
					cursor: pointer;
					font-weight: 500;
					color: #000;
					border-bottom: 1px solid rgba(101, 101, 101, 0.05);
					padding: 4.5px 10px;
					@include t(all, 0.15s);
					.fa-Triangle {
						@include r(-90deg);
						@include t(all, 0.15s);
					}
					&:hover {
						color: $blue-color;
					}
				}
				ul {
					display: none;
					background: rgba(0, 0, 0, 0.05);
					padding: 0px;
					li {
						& > a,
						& > div {
							padding: 4.5px 15px;
						}
					}
				}
				&.active {
					& > a,
					& > div {
						color: $blue-color;
						.line {
							background: $blue-color;
						}
					}
					& > ul {
						display: block;
					}
					& > a,
					& > div {
						.fa-Triangle {
							@include r(0deg);
						}
					}
				}
			}
		}
	}
}

.fa-Triangle {
	color: inherit;
	display: flex;
	margin-left: 7.5px;
	.line {
		width: 5px;
		height: 1px;
		color: inherit;
		background: #000;
		@include r(45deg);
		position: relative;
		&:last-child {
			@include r(-45deg);
			left: -2px;
		}
	}
}

/** FA Table **/
.fa-Table {
	padding: 5px;
	.fa-Table-heading {
		display: flex;
		flex-flow: row wrap;
		border-bottom: 1px solid #ececec;
		.fa-Table-th {
			padding: 5px;
			font-weight: 600;
			font-size: 13px;
		}
	}
	.fa-Table-body {
		.fa-Table-row {
			width: 100%;
			@include t(all, 0.3s);
			border-bottom: 1px solid #ececec;
			&:last-child {
				border-bottom: none;
			}
			&:nth-child(2n) {
				background: #f8f8f8;
			}
			&:hover {
				background: #f2f2fb;
			}
			.fa-Table-td {
				padding: 5px;
				font-weight: 400;
				font-size: 12px;
			}
		}
	}
}

.fa-FileUpload {
	padding: 7.5px;
	&.hide-upload {
		.filepond--action-process-item {
			display: none !important;
		}
	}
	.filepond--root {
		margin-bottom: 0px !important;
		.filepond--drop-label {
			height: 100px;
			label {
				display: flex;
				flex-direction: column;
				font-size: 13.5px;
				font-weight: 500;
				i {
					color: #676565;
					font-size: 27px;
				}
				.upload-label {
					font-size: 11px;
					text-decoration: underline;
					cursor: pointer;
				}
			}
		}
	}
	.filepond--action-revert-item-processing {
		display: block !important;
	}
	.fa-FileUpload-labels {
		padding: 5px 0px;
		.col {
			font-size: 11px;
			color: #3e3e3e;
			padding: 0px;
			.title {
				font-weight: 600;
			}
			.text {
				font-style: italic;
				font-weight: 500;
			}
			&.m-left-auto {
				text-align: right;
			}
		}
	}
}

/** Responsive **/

@media (max-width: 992px) {
	.table-loader {
		height: auto !important;
	}
}

/** Dynamic Field **/
.fa-Dynamic-field {
	position: relative;
	.fa-Dynamic-fieldWrapper {
		width: 100%;
		position: relative;
		.fa-Dynamic-input {
			width: 100%;
			border: none;
			border-radius: 0px;
			min-height: 28px;
			-webkit-border-radius: 0px;
			-moz-border-radius: 0px;
			-o-border-radius: 0px;
			-webkit-appearance: none;
			font-size: 0.95em;
			padding: 0px;
			font-family: $font;
			background: #fff;
			@include t(all, 0.1s);
			&::selection {
				background: transparent;
			}
			* {
				&::selection {
					background: transparent;
				}
			}
			
			.parameter-item {
				margin-right: 0px;
				align-items: center;
				display: inline-flex;
				font-weight: 500;
				cursor: pointer;
				color: rgb(0, 0, 0);
				background: #f3debf;
				font-size: 10px;
				padding: 0px 5px;
				height: 18px;
				top: -1px;
				position: relative;
				user-select: none;
				-webkit-user-select: none;
				-moz-user-select: none;

				.parameter-decoration .opacity-none {
					position: absolute;
					opacity: 0;
				}

				&:before {
					padding: 0 2.5px;
					position: relative;
					left: -4px;
					width: 15px;
					height: 100%;
					background: #e8cea6;
					content: "U";
					text-align: center;
					line-height: 17px;
					font-weight: 700;
					font-size: 10px;
				}

				&.string {
					&:before {
						padding: 1px 3.5px;
						position: relative;
						left: -5px;
						width: 15px;
						height: 100%;
						background: #f8b279;
						color: #fff;
						content: "S";
						text-align: center;
						line-height: 17px;
						font-weight: 700;
						font-size: 10px;
					}

					background: #d87c32;
					color: #fff;
				}
			}

			.fa-Dynamic-input.dark .parameter-item.string {
				background: #c78f60 !important;
				color: #fff !important;
			}

			.parameter-item {
				&.number {
					&:before {
						background: #5ed4ed;
						content: "N";
						color: #fff;
						padding-top: 1px !important;
					}

					background: #0aa3c3;
					color: #fff;
				}

				span[data-text="true"]::nth-first-letter(1) {
					display: none;
				}

				&.array:before,
				&.boolean:before,
				&.number:before {
					position: relative;
					left: -5px;
					width: 15px;
					height: 100%;
					text-align: center;
					padding: 1px 3.5px;
					line-height: 17px;
					font-weight: 700;
					font-size: 10px;
				}
			}

			.fa-Dynamic-input.dark .parameter-item.array {
				background: #9f5e5e !important;
				color: #fff !important;
			}

			.parameter-item.array {
				background: #c36a6a;
				color: #fff;

				&:before {
					background: #e88888;
					content: "A";
					color: #fff;
				}
			}

// .fa-Dynamic-input.dark .parameter-item {
// 	&.number {
// 		background: #84aeb6 !important;
// 		color: #fff !important;
// 	}

// 	&.boolean {
// 		background: #73a798 !important;
// 		color: #fff !important;
// 	}

// 	&.null {
// 		background: #8168a0 !important;
// 		color: #fff !important;
// 	}
// }

			// .parameter-item {
			// 	i {
			// 		margin-right: 2.5px;
			// 		color: #efefef;
			// 		font-size: 8px;
			// 		height: 18px;
			// 		line-height: 18px;
			// 		text-align: center;
			// 		padding: 0px 2px;
			// 	}
			// 	&.request_body {
			// 		background: #458ac0;
			// 		&:before {
			// 			background: #3a75a3;
			// 			content: "\ea50" !important;
			// 		}
			// 	}
			// 	&.response_body {
			// 		background: #51a596;
			// 		&:before {
			// 			background: #438579;
			// 			content: "\ea1c" !important;
			// 		}
			// 	}
			// 	font-size: 10.5px;
			// 	align-items: center;
			// 	display: inline-flex;
			// 	font-weight: 600;
			// 	cursor: pointer;
			// 	background: #b25454;
			// 	color: #fff;
			// 	padding: 0px 5px 0px 5px;
			// 	height: 18px;
			// 	margin-top: 1px;
			// 	top: -1px;
			// 	position: relative;
			// 	&:before {
			// 		content: "\e9e5";
			// 		font-family: "icomoon";
			// 		color: #fff;
			// 		font-size: 9px;
			// 		padding: 0 5px 0 6px;
			// 		background: #964848;
			// 		height: 100%;
			// 		line-height: 19px;
			// 		margin-left: -5px;
			// 		margin-right: 5px;
			// 		text-align: center;
			// 	}
			// 	// .text {

			// 	// }
			// }
		}
	}
}

.fa-Dynamic-list {
	position: relative;
	width: 100%;
	z-index: 111;
	.not-found-parameter {
		font-style: italic;
		font-size: 11px;
		font-weight: 500;
		color: #000;
		padding: 15px;
		display: block;
		i {
			padding-right: 2.7px;
		}
	}
	.title {
		padding: 10px;
		background: #1882d4;
		color: #efefef;
		font-size: 10.5px;
		.up-title {
			font-weight: 600;
			font-size: 11.5px;
		}
		.close {
			position: absolute;
			cursor: pointer;
			right: 15px;
			top: 12.5px;
			font-size: 12px;
		}
	}
	.fa-Dynamic-listWrapper {
		.item {
			&.request_body {
				&:before {
					background: #458ac0;
				}
			}
			&.response_body {
				i {
					background: #51a596;
					&:before {
						content: "\ea1c" !important;
					}
				}
			}
			i {
				margin-right: 5px;
				// background: #458ac0;
				color: #efefef;
				font-size: 10px;
				height: 20px;
				line-height: 20px;
				padding: 0px;
				width: 20px;
				text-align: center;
			}
			font-size: 11.5px;
			padding: 7.5px 10px;
			align-items: center;
			display: flex;
			font-weight: 600;
			border-bottom: 1px solid #d2d2d2;
			cursor: pointer;
			&:hover {
				background: #ecf6fe;
			}
			.sub-text {
				font-weight: 400;
				font-size: 11px;
				margin-top: -2px;
			}
		}
		max-height: 250px;
		overflow-y: auto;
		&::-webkit-scrollbar {
			width: 2px;
		}

		&::-webkit-scrollbar-track {
			background: #e7e7e7;
		}

		&::-webkit-scrollbar-thumb {
			background: #bbbbbb;
		}
	}
}
.fa-Dynamic-search {
	background: #e5e5e5;
	padding: 5px;
}
.InputFromTo .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
    background-color: #f0f8ff !important;
    color: #4a90e2;
  }
  .InputFromTo .DayPicker-Day {
    border-radius: 0 !important;
  }
  .InputFromTo .DayPicker-Day--start {
    border-top-left-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
  }
  .InputFromTo .DayPicker-Day--end {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }
  .InputFromTo .DayPickerInput-Overlay {
    width: 30% !important;
  }
  .InputFromTo-to .DayPickerInput-Overlay {
    margin-left: -28px;
  }

.datepicker-display-block {
    display: inline-block;
	width: 45%;
	input {
		font-size: 13px !important;
		font-weight: 500;
		outline: 0;
		background: 0 0;
		border: 1px solid #d3d3d3 !important;
	}
}
.DayPicker-Months {
	width: 100%;
	padding: 20px;
}

.InputFromTo .DayPickerInput-Overlay {
	width: inherit !important;
	margin-left: 0px !important;
}

.DayPicker-Month {
	flex: 0 0 170px;
    user-select: none;
	max-width: 170px;
	margin: 0px !important;
    padding: 0px 30px;
}