// -----------------------------------------------------------------------------
// Formapps IO
// -----------------------------------------------------------------------------


/**
Transition
**/
@mixin t ($property, $time) {
    -webkit-transition: $property $time;
    -moz-transition: $property $time;
    -o-transition: $property $time;
    transition: $property $time;
  }

  /**
Transition
**/
@mixin r ($deg) {
  -webkit-transform: rotate($deg);
  -moz-transform: rotate($deg);
  -o-transform: rotate($deg);
  transform: rotate($deg);
}

  /**
Scale
**/
@mixin s ($deg) {
  -webkit-transform: scale($deg);
  -moz-transform: scale($deg);
  -o-transform: scale($deg);
  transform: scale($deg);
}