@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?b36ku7');
  src:  url('fonts/icomoon.eot?b36ku7#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?b36ku7') format('truetype'),
    url('fonts/icomoon.woff?b36ku7') format('woff'),
    url('fonts/icomoon.svg?b36ku7#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

i {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon';
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-menu:before {
  content: "\e9c2";
}
.icon-accreditation:before {
  content: "\e900";
}
.icon-arrow-down:before {
  content: "\e901";
}
.icon-arrow-left:before {
  content: "\e902";
}
.icon-arrow-right:before {
  content: "\e903";
}
.icon-arrow-up:before {
  content: "\e904";
}
.icon-body:before {
  content: "\e905";
}
.icon-bone:before {
  content: "\e906";
}
.icon-brain:before {
  content: "\e907";
}
.icon-call-center:before {
  content: "\e908";
}
.icon-check-2:before {
  content: "\e909";
}
.icon-check:before {
  content: "\e90a";
}
.icon-chevron-down:before {
  content: "\e90b";
}
.icon-chevron-left:before {
  content: "\e90c";
}
.icon-chevron-right:before {
  content: "\e90d";
}
.icon-chevron-up:before {
  content: "\e90e";
}
.icon-close:before {
  content: "\e90f";
}
.icon-dolar:before {
  content: "\e910";
}
.icon-dubble-arrow:before {
  content: "\e911";
}
.icon-edit:before {
  content: "\e912";
}
.icon-email:before {
  content: "\e913";
}
.icon-embryo:before {
  content: "\e914";
}
.icon-error:before {
  content: "\e915";
}
.icon-eye:before {
  content: "\e916";
}
.icon-facebook:before {
  content: "\e917";
}
.icon-facebook-filled:before {
  content: "\e918";
}
.icon-filter:before {
  content: "\e919";
}
.icon-headphones:before {
  content: "\e91a";
}
.icon-heart:before {
  content: "\e91b";
}
.icon-hearth-beat:before {
  content: "\e91c";
}
.icon-help:before {
  content: "\e91d";
}
.icon-home:before {
  content: "\e91e";
}
.icon-horizontal-list:before {
  content: "\e91f";
}
.icon-info:before {
  content: "\e920";
}
.icon-instagram:before {
  content: "\e921";
}
.icon-insurance:before {
  content: "\e922";
}
.icon-knife:before {
  content: "\e923";
}
.icon-language:before {
  content: "\e924";
}
.icon-linkedin:before {
  content: "\e925";
}
.icon-medicine:before {
  content: "\e926";
}
.icon-message:before {
  content: "\e927";
}
.icon-more:before {
  content: "\e928";
}
.icon-navigation:before {
  content: "\e929";
}
.icon-phone:before {
  content: "\e92a";
}
.icon-plus:before {
  content: "\e92b";
}
.icon-ribbon:before {
  content: "\e92c";
}
.icon-right-arrow:before {
  content: "\e92d";
}
.icon-search:before {
  content: "\e92e";
}
.icon-spinner:before {
  content: "\e92f";
}
.icon-state:before {
  content: "\e930";
}
.icon-stethoscope:before {
  content: "\e931";
}
.icon-success:before {
  content: "\e932";
}
.icon-teeth:before {
  content: "\e933";
}
.icon-trash:before {
  content: "\e934";
}
.icon-twitter:before {
  content: "\e935";
}
.icon-upload:before {
  content: "\e936";
}
.icon-vertical-list:before {
  content: "\e937";
}
.icon-warning:before {
  content: "\e939";
}
.icon-web:before {
  content: "\e93a";
}
.icon-whatsapp:before {
  content: "\e93b";
}
.icon-youtube:before {
  content: "\e93c";
}
.icon-f_label:before {
  content: "\eab8";
  color: #999;
}
.icon-f_md-1 .path1:before {
  content: "\eab9";
  color: rgb(206, 206, 206);
}
.icon-f_md-1 .path2:before {
  content: "\eaba";
  margin-left: -1em;
  color: rgb(153, 153, 153);
}
.icon-f_md-1 .path3:before {
  content: "\eabb";
  margin-left: -1em;
  color: rgb(153, 153, 153);
}
.icon-f_md-2 .path1:before {
  content: "\eabc";
  color: rgb(206, 206, 206);
}
.icon-f_md-2 .path2:before {
  content: "\eabd";
  margin-left: -1em;
  color: rgb(153, 153, 153);
}
.icon-f_md-2 .path3:before {
  content: "\eabe";
  margin-left: -1em;
  color: rgb(153, 153, 153);
}
.icon-f_md-3 .path1:before {
  content: "\eabf";
  color: rgb(206, 206, 206);
}
.icon-f_md-3 .path2:before {
  content: "\eac0";
  margin-left: -1em;
  color: rgb(153, 153, 153);
}
.icon-f_md-3 .path3:before {
  content: "\eac1";
  margin-left: -1em;
  color: rgb(153, 153, 153);
}
.icon-f_md-4 .path1:before {
  content: "\eac2";
  color: rgb(206, 206, 206);
}
.icon-f_md-4 .path2:before {
  content: "\eac3";
  margin-left: -1em;
  color: rgb(153, 153, 153);
}
.icon-f_md-4 .path3:before {
  content: "\eac4";
  margin-left: -1em;
  color: rgb(153, 153, 153);
}
.icon-f_md-5 .path1:before {
  content: "\eac5";
  color: rgb(206, 206, 206);
}
.icon-f_md-5 .path2:before {
  content: "\eac6";
  margin-left: -1em;
  color: rgb(153, 153, 153);
}
.icon-f_md-5 .path3:before {
  content: "\eac7";
  margin-left: -1em;
  color: rgb(153, 153, 153);
}
.icon-f_md-6 .path1:before {
  content: "\eac8";
  color: rgb(206, 206, 206);
}
.icon-f_md-6 .path2:before {
  content: "\eac9";
  margin-left: -1em;
  color: rgb(153, 153, 153);
}
.icon-f_md-6 .path3:before {
  content: "\eaca";
  margin-left: -1em;
  color: rgb(153, 153, 153);
}
.icon-f_md-7 .path1:before {
  content: "\eacb";
  color: rgb(206, 206, 206);
}
.icon-f_md-7 .path2:before {
  content: "\eacc";
  margin-left: -1em;
  color: rgb(153, 153, 153);
}
.icon-f_md-7 .path3:before {
  content: "\eacd";
  margin-left: -1em;
  color: rgb(153, 153, 153);
}
.icon-f_md-8 .path1:before {
  content: "\eace";
  color: rgb(206, 206, 206);
}
.icon-f_md-8 .path2:before {
  content: "\eacf";
  margin-left: -1em;
  color: rgb(153, 153, 153);
}
.icon-f_md-8 .path3:before {
  content: "\ead0";
  margin-left: -1em;
  color: rgb(153, 153, 153);
}
.icon-f_md-9 .path1:before {
  content: "\ead1";
  color: rgb(206, 206, 206);
}
.icon-f_md-9 .path2:before {
  content: "\ead2";
  margin-left: -1em;
  color: rgb(153, 153, 153);
}
.icon-f_md-9 .path3:before {
  content: "\ead3";
  margin-left: -1em;
  color: rgb(153, 153, 153);
}
.icon-f_md-10 .path1:before {
  content: "\ead4";
  color: rgb(206, 206, 206);
}
.icon-f_md-10 .path2:before {
  content: "\ead5";
  margin-left: -1em;
  color: rgb(153, 153, 153);
}
.icon-f_md-10 .path3:before {
  content: "\ead6";
  margin-left: -1em;
  color: rgb(153, 153, 153);
}
.icon-f_md-11 .path1:before {
  content: "\ead7";
  color: rgb(206, 206, 206);
}
.icon-f_md-11 .path2:before {
  content: "\ead8";
  margin-left: -1em;
  color: rgb(153, 153, 153);
}
.icon-f_md-11 .path3:before {
  content: "\ead9";
  margin-left: -1em;
  color: rgb(153, 153, 153);
}
.icon-f_md-12:before {
  content: "\eada";
  color: #999;
}
.icon-f_wrapper:before {
  content: "\eadb";
  color: #999;
}
.icon-f_button:before {
  content: "\ea70";
  color: #c2c1c1;
}
.icon-f_card:before {
  content: "\ea71";
  color: #c2c1c1;
}
.icon-f_cardlist:before {
  content: "\ea72";
  color: #c2c1c1;
}
.icon-f_column:before {
  content: "\ea73";
  color: #c2c1c1;
}
.icon-f_container:before {
  content: "\ea74";
  color: #c2c1c1;
}
.icon-f_custom:before {
  content: "\ea75";
  color: #c2c1c1;
}
.icon-f_fancybox:before {
  content: "\ea76";
  color: #c2c1c1;
}
.icon-f_footer:before {
  content: "\ea77";
  color: #c2c1c1;
}
.icon-f_header:before {
  content: "\ea78";
  color: #c2c1c1;
}
.icon-f_heading:before {
  content: "\ea79";
  color: #c2c1c1;
}
.icon-f_helmet:before {
  content: "\ea7a";
  color: #c2c1c1;
}
.icon-f_horizantalrule:before {
  content: "\ea7d";
  color: #c2c1c1;
}
.icon-f_html:before {
  content: "\ea7e";
  color: #c2c1c1;
}
.icon-f_icon:before {
  content: "\ea7f";
  color: #c2c1c1;
}
.icon-f_iconlist:before {
  content: "\ea80";
  color: #c2c1c1;
}
.icon-f_iframe:before {
  content: "\ea81";
  color: #c2c1c1;
}
.icon-f_image:before {
  content: "\ea82";
  color: #c2c1c1;
}
.icon-f_instagram:before {
  content: "\ea83";
  color: #c2c1c1;
}
.icon-f_news:before {
  content: "\ea84";
  color: #c2c1c1;
}
.icon-f_paragraph:before {
  content: "\ea85";
  color: #c2c1c1;
}
.icon-f_partial:before {
  content: "\ea86";
  color: #c2c1c1;
}
.icon-f_photogallery:before {
  content: "\ea87";
  color: #c2c1c1;
}
.icon-f_previewmodel:before {
  content: "\ea9b";
  color: #c2c1c1;
}
.icon-f_row:before {
  content: "\eaac";
  color: #c2c1c1;
}
.icon-f_section:before {
  content: "\eaad";
  color: #c2c1c1;
}
.icon-f_senses:before {
  content: "\eaae";
  color: #c2c1c1;
}
.icon-f_slideshow:before {
  content: "\eaaf";
  color: #c2c1c1;
}
.icon-f_socialmedia:before {
  content: "\eab0";
  color: #c2c1c1;
}
.icon-f_socialmedialist:before {
  content: "\eab1";
  color: #c2c1c1;
}
.icon-f_socialmediaslider:before {
  content: "\eab2";
  color: #c2c1c1;
}
.icon-f_video:before {
  content: "\eab3";
  color: #c2c1c1;
}
.icon-f_videolist:before {
  content: "\eab4";
  color: #c2c1c1;
}
.icon-f_videoslides:before {
  content: "\eab5";
  color: #c2c1c1;
}
.icon-f_ancor:before {
  content: "\eab6";
  color: #c2c1c1;
}
.icon-f_animation:before {
  content: "\eab7";
  color: #c2c1c1;
}
.icon-workflow:before {
  content: "\ea5e";
  color: #fff;
}
.icon-ui-flow:before {
  content: "\ea66";
  color: #fff;
}
.icon-cursor_zoom_in:before {
  content: "\ea5d";
  color: #d8d8d8;
}
.icon-cursor_move:before {
  content: "\ea5f";
  color: #d7d7d7;
}
.icon-cursor_pointer:before {
  content: "\ea60";
  color: #d7d7d7;
}
.icon-cursor_text:before {
  content: "\ea61";
  color: #d8d8d8;
}
.icon-cursor_grabbing:before {
  content: "\ea62";
  color: #d7d7d7;
}
.icon-cursor_grab:before {
  content: "\ea63";
  color: #d8d8d8;
}
.icon-cursor_default:before {
  content: "\ea64";
  color: #d7d7d7;
}
.icon-cursor_zoom_out:before {
  content: "\ea65";
  color: #d7d7d7;
}
.icon-typo_capitalize_uppercase:before {
  content: "\ea2d";
  color: #d8d8d8;
}
.icon-typo_capitalize_lovercase:before {
  content: "\ea2e";
  color: #d8d8d8;
}
.icon-typo_capitalize_everyworld:before {
  content: "\ea2f";
  color: #d8d8d8;
}
.icon-typo_deco_none:before {
  content: "\ea4d";
  color: #d8d8d8;
}
.icon-unlock:before {
  content: "\ea4e";
  color: #d7d7d7;
}
.icon-background_classiccolor:before {
  content: "\ea4f";
  color: #d7d7d7;
}
.icon-background_gredient:before {
  content: "\ea50";
  color: #d7d7d7;
}
.icon-colorpicker:before {
  content: "\ea51";
  color: #d7d7d7;
}
.icon-display_align_center:before {
  content: "\ea52";
  color: #d8d8d8;
}
.icon-display_align_end:before {
  content: "\ea53";
  color: #d8d8d8;
}
.icon-display_align_start:before {
  content: "\ea54";
  color: #d8d8d8;
}
.icon-display_align_stretch:before {
  content: "\ea55";
  color: #d8d8d8;
}
.icon-display_justify_center:before {
  content: "\ea56";
  color: #d8d8d8;
}
.icon-display_justify_end:before {
  content: "\ea57";
  color: #d8d8d8;
}
.icon-display_justify_spacebetween:before {
  content: "\ea58";
  color: #d8d8d8;
}
.icon-display_justify_start:before {
  content: "\ea59";
  color: #d8d8d8;
}
.icon-lock:before {
  content: "\ea5a";
  color: #d7d7d7;
}
.icon-position_static:before {
  content: "\ea5b";
  color: #d8d8d8;
}
.icon-rotatedegree:before {
  content: "\ea5c";
  color: #d7d7d7;
}
.icon-t-upload:before {
  content: "\ea4c";
  color: #c1c1c1;
}
.icon-object-stroge:before {
  content: "\ea4b";
  color: #fff;
}
.icon-borders_left:before {
  content: "\ea19";
  color: #d7d7d7;
}
.icon-borders_right:before {
  content: "\ea1a";
  color: #d7d7d7;
}
.icon-borders_top:before {
  content: "\ea1b";
  color: #d7d7d7;
}
.icon-display_block:before {
  content: "\ea1c";
  color: #d8d8d8;
}
.icon-display_flex:before {
  content: "\ea1d";
  color: #d8d8d8;
}
.icon-display_inlineblock:before {
  content: "\ea1e";
  color: #d8d8d8;
}
.icon-display_none:before {
  content: "\ea1f";
  color: #d8d8d8;
}
.icon-none:before {
  content: "\ea20";
  color: #c2c1c1;
}
.icon-overflow_auto:before {
  content: "\ea21";
  color: #d8d8d8;
}
.icon-overflow_hidden:before {
  content: "\ea22";
  color: #d8d8d8;
}
.icon-overflow_scroll:before {
  content: "\ea23";
  color: #d8d8d8;
}
.icon-overflow_visible:before {
  content: "\ea24";
  color: #d8d8d8;
}
.icon-position_absolute:before {
  content: "\ea25";
  color: #d8d8d8;
}
.icon-position_fixed:before {
  content: "\ea26";
  color: #d8d8d8;
}
.icon-position_left:before {
  content: "\ea27";
  color: #d8d8d8;
}
.icon-position_relative:before {
  content: "\ea28";
  color: #d8d8d8;
}
.icon-position_right:before {
  content: "\ea29";
  color: #d8d8d8;
}
.icon-radius_style_dashed:before {
  content: "\ea2a";
  color: #d7d7d7;
}
.icon-radius_style_dotted:before {
  content: "\ea2b";
  color: #d7d7d7;
}
.icon-radius_style_solid:before {
  content: "\ea2c";
  color: #d7d7d7;
}
.icon-typo_center:before {
  content: "\ea30";
  color: #d8d8d8;
}
.icon-typo_deco_overline:before {
  content: "\ea31";
  color: #d8d8d8;
}
.icon-typo_deco_strikethrough:before {
  content: "\ea32";
  color: #d8d8d8;
}
.icon-typo_deco_underline:before {
  content: "\ea33";
  color: #d8d8d8;
}
.icon-typo_direction_lefttoright:before {
  content: "\ea34";
  color: #d8d8d8;
}
.icon-typo_direction_righttoleft:before {
  content: "\ea35";
  color: #d8d8d8;
}
.icon-typo_italicize_italic:before {
  content: "\ea36";
  color: #d8d8d8;
}
.icon-typo_italicize_regular:before {
  content: "\ea37";
  color: #d8d8d8;
}
.icon-typo_justify:before {
  content: "\ea38";
  color: #d8d8d8;
}
.icon-typo_left:before {
  content: "\ea39";
  color: #d8d8d8;
}
.icon-typo_more:before {
  content: "\ea3a";
  color: #d7d7d7;
}
.icon-typo_plus:before {
  content: "\ea3b";
  color: #d8d8d8;
}
.icon-typo_right:before {
  content: "\ea3c";
  color: #d8d8d8;
}
.icon-borders_all:before {
  content: "\ea3d";
  color: #d8d8d8;
}
.icon-borders_bottom:before {
  content: "\ea47";
  color: #d7d7d7;
}
.icon-t-table:before {
  content: "\e9c0";
  color: #c1c1c1;
}
.icon-t-text:before {
  content: "\e9c1";
  color: #c1c1c1;
}
.icon-t-text1 .path1:before {
  content: "\e9c4";
  color: rgb(63, 63, 63);
}
.icon-t-text1 .path2:before {
  content: "\eaeb";
  margin-left: -1em;
  color: rgb(188, 188, 188);
}
.icon-t-text1 .path3:before {
  content: "\eaec";
  margin-left: -1em;
  color: rgb(188, 188, 188);
}
.icon-t-textbox:before {
  content: "\e9c5";
  color: #c1c1c1;
}
.icon-t-video:before {
  content: "\e9c6";
  color: #c1c1c1;
}
.icon-t-websocket:before {
  content: "\e9c7";
  color: #c1c1c1;
}
.icon-workspace-icon .path1:before {
  content: "\e9c8";
  color: rgb(204, 204, 204);
}
.icon-workspace-icon .path2:before {
  content: "\e9c9";
  margin-left: -1em;
  color: rgb(204, 204, 204);
}
.icon-workspace-icon .path3:before {
  content: "\e9ca";
  margin-left: -1em;
  color: rgb(204, 204, 204);
}
.icon-workspace-icon .path4:before {
  content: "\e9cb";
  margin-left: -1em;
  color: rgb(204, 204, 204);
}
.icon-workspace-icon .path5:before {
  content: "\e9cc";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-workspace-icon .path6:before {
  content: "\e9cd";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-workspace-icon .path7:before {
  content: "\e9ce";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-workspace-icon .path8:before {
  content: "\e9cf";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-workspace-icon .path9:before {
  content: "\e9d0";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-workspace-icon .path10:before {
  content: "\e9d1";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-workspace-icon .path11:before {
  content: "\e9d2";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-workspace-icon .path12:before {
  content: "\e9d3";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-app-icon:before {
  content: "\e9d4";
  color: #7d3da5;
}
.icon-c-bar:before {
  content: "\e9d5";
  color: #c1c1c1;
}
.icon-c-doughnut:before {
  content: "\e9d6";
  color: #c1c1c1;
}
.icon-c-horizontal-bar:before {
  content: "\e9d7";
  color: #c1c1c1;
}
.icon-c-line:before {
  content: "\e9d8";
  color: #c1c1c1;
}
.icon-c-pie:before {
  content: "\e9d9";
  color: #c1c1c1;
}
.icon-divider-icon:before {
  content: "\e9da";
}
.icon-file-icon .path1:before {
  content: "\e9db";
  color: rgb(171, 171, 171);
}
.icon-file-icon .path2:before {
  content: "\e9dc";
  margin-left: -1em;
  color: rgb(153, 105, 242);
}
.icon-file-icon .path3:before {
  content: "\e9dd";
  margin-left: -1em;
  color: rgb(153, 105, 242);
}
.icon-flow-alert:before {
  content: "\e9de";
  color: #c1c1c1;
}
.icon-flow-code:before {
  content: "\e9df";
  color: #c1c1c1;
}
.icon-flow-condition:before {
  content: "\e9e0";
  color: #c1c1c1;
}
.icon-flow-confirmationbox:before {
  content: "\e9e1";
  color: #c1c1c1;
}
.icon-flow-redirect:before {
  content: "\e9e2";
  color: #c1c1c1;
}
.icon-flow-setdata:before {
  content: "\e9e3";
  color: #c1c1c1;
}
.icon-flow-solid-alert:before {
  content: "\e9e4";
  color: #c1c1c1;
}
.icon-flow-solid-code:before {
  content: "\e9e5";
  color: #c1c1c1;
}
.icon-flow-solid-condition:before {
  content: "\e9e6";
  color: #c1c1c1;
}
.icon-flow-solid-confirmationbox:before {
  content: "\e9e7";
  color: #c1c1c1;
}
.icon-flow-solid-redirect:before {
  content: "\e9e8";
  color: #c1c1c1;
}
.icon-flow-solid-setdata:before {
  content: "\e9e9";
  color: #c1c1c1;
}
.icon-flow-solid-uitrigger:before {
  content: "\e9ea";
  color: #c1c1c1;
}
.icon-flow-solid-wac:before {
  content: "\e9eb";
  color: #c1c1c1;
}
.icon-flow-uitrigger:before {
  content: "\e9ec";
  color: #c1c1c1;
}
.icon-flow-wac:before {
  content: "\e9ed";
  color: #c1c1c1;
}
.icon-t-button:before {
  content: "\e9ee";
  color: #c1c1c1;
}
.icon-t-card:before {
  content: "\e9ef";
  color: #c1c1c1;
}
.icon-t-cardlist:before {
  content: "\e9f0";
  color: #c1c1c1;
}
.icon-t-checkbox:before {
  content: "\e9f1";
  color: #c1c1c1;
}
.icon-t-checkboxgroup:before {
  content: "\e9f2";
  color: #c1c1c1;
}
.icon-t-column:before {
  content: "\e9f3";
  color: #c1c1c1;
}
.icon-t-combobox:before {
  content: "\e9f4";
  color: #c1c1c1;
}
.icon-t-container:before {
  content: "\e9f5";
  color: #c1c1c1;
}
.icon-t-dropdown:before {
  content: "\e9f6";
  color: #c1c1c1;
}
.icon-test-icon .path1:before {
  content: "\e9f7";
  color: rgb(204, 204, 204);
}
.icon-test-icon .path2:before {
  content: "\e9f8";
  margin-left: -1em;
  color: rgb(204, 204, 204);
}
.icon-test-icon .path3:before {
  content: "\e9f9";
  margin-left: -1em;
  color: rgb(204, 204, 204);
}
.icon-test-icon .path4:before {
  content: "\e9fa";
  margin-left: -1em;
  color: rgb(204, 204, 204);
}
.icon-test-icon .path5:before {
  content: "\e9fb";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-test-icon .path6:before {
  content: "\e9fc";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-test-icon .path7:before {
  content: "\e9fd";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-test-icon .path8:before {
  content: "\e9fe";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-test-icon .path9:before {
  content: "\e9ff";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-test-icon .path10:before {
  content: "\ea00";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-test-icon .path11:before {
  content: "\ea01";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-test-icon .path12:before {
  content: "\ea02";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-t-grid:before {
  content: "\ea03";
  color: #c1c1c1;
}
.icon-t-heading:before {
  content: "\ea04";
  color: #c1c1c1;
}
.icon-t-icon:before {
  content: "\ea05";
  color: #c1c1c1;
}
.icon-t-iconbox:before {
  content: "\ea06";
  color: #c1c1c1;
}
.icon-t-iconlist:before {
  content: "\ea07";
  color: #c1c1c1;
}
.icon-t-image:before {
  content: "\ea08";
  color: #c1c1c1;
}
.icon-t-imagebox:before {
  content: "\ea09";
  color: #c1c1c1;
}
.icon-t-images:before {
  content: "\ea0a";
  color: #c1c1c1;
}
.icon-t-imageslider:before {
  content: "\ea0b";
  color: #c1c1c1;
}
.icon-t-label .path1:before {
  content: "\ea0c";
  color: rgb(63, 63, 63);
}
.icon-t-label .path2:before {
  content: "\ea0d";
  margin-left: -1em;
  color: rgb(188, 188, 188);
}
.icon-t-label .path3:before {
  content: "\ea0e";
  margin-left: -1em;
  color: rgb(188, 188, 188);
}
.icon-t-label .path4:before {
  content: "\ea0f";
  margin-left: -1em;
  color: rgb(188, 188, 188);
}
.icon-t-label .path5:before {
  content: "\ea10";
  margin-left: -1em;
  color: rgb(188, 188, 188);
}
.icon-t-label .path6:before {
  content: "\ea11";
  margin-left: -1em;
  color: rgb(188, 188, 188);
}
.icon-t-label1:before {
  content: "\ea12";
  color: #c1c1c1;
}
.icon-t-list:before {
  content: "\ea13";
  color: #c1c1c1;
}
.icon-t-map:before {
  content: "\ea14";
  color: #c1c1c1;
}
.icon-t-menu:before {
  content: "\ea15";
  color: #c1c1c1;
}
.icon-t-paragraph:before {
  content: "\ea16";
  color: #c1c1c1;
}
.icon-t-radiogroup:before {
  content: "\ea17";
  color: #c1c1c1;
}
.icon-t-sharebuttons:before {
  content: "\ea18";
  color: #c1c1c1;
}
.icon-container .path1:before {
  content: "\e938";
  color: rgb(77, 77, 77);
}
.icon-container .path2:before {
  content: "\eae0";
  margin-left: -1em;
  color: rgb(77, 77, 77);
}
.icon-container .path3:before {
  content: "\eae1";
  margin-left: -1em;
  color: rgb(170, 170, 170);
}
.icon-container .path4:before {
  content: "\eae2";
  margin-left: -1em;
  color: rgb(170, 170, 170);
}
.icon-container .path5:before {
  content: "\eae3";
  margin-left: -1em;
  color: rgb(170, 170, 170);
}
.icon-container .path6:before {
  content: "\eae4";
  margin-left: -1em;
  color: rgb(170, 170, 170);
}
.icon-container .path7:before {
  content: "\eae5";
  margin-left: -1em;
  color: rgb(170, 170, 170);
}
.icon-container .path8:before {
  content: "\eae6";
  margin-left: -1em;
  color: rgb(170, 170, 170);
}
.icon-container .path9:before {
  content: "\eae7";
  margin-left: -1em;
  color: rgb(170, 170, 170);
}
.icon-container .path10:before {
  content: "\eae8";
  margin-left: -1em;
  color: rgb(170, 170, 170);
}
.icon-dropdown .path1:before {
  content: "\e949";
  color: rgb(170, 170, 170);
}
.icon-dropdown .path2:before {
  content: "\e94a";
  margin-left: -1em;
  color: rgb(170, 170, 170);
}
.icon-dropdown .path3:before {
  content: "\e94b";
  margin-left: -1em;
  color: rgb(170, 170, 170);
}
.icon-dropdown .path4:before {
  content: "\e94c";
  margin-left: -1em;
  color: rgb(77, 77, 77);
}
.icon-flow1:before {
  content: "\e94d";
  color: #4d4d4d;
}
.icon-grid .path1:before {
  content: "\e94e";
  color: rgb(77, 77, 77);
}
.icon-grid .path2:before {
  content: "\e94f";
  margin-left: -1em;
  color: rgb(77, 77, 77);
}
.icon-grid .path3:before {
  content: "\e950";
  margin-left: -1em;
  color: rgb(77, 77, 77);
}
.icon-grid .path4:before {
  content: "\e951";
  margin-left: -1em;
  color: rgb(77, 77, 77);
}
.icon-grid .path5:before {
  content: "\e952";
  margin-left: -1em;
  color: rgb(77, 77, 77);
}
.icon-grid .path6:before {
  content: "\e953";
  margin-left: -1em;
  color: rgb(170, 170, 170);
}
.icon-grid .path7:before {
  content: "\e954";
  margin-left: -1em;
  color: rgb(170, 170, 170);
}
.icon-grid .path8:before {
  content: "\e955";
  margin-left: -1em;
  color: rgb(170, 170, 170);
}
.icon-grid .path9:before {
  content: "\e956";
  margin-left: -1em;
  color: rgb(170, 170, 170);
}
.icon-grid .path10:before {
  content: "\e957";
  margin-left: -1em;
  color: rgb(170, 170, 170);
}
.icon-grid .path11:before {
  content: "\e958";
  margin-left: -1em;
  color: rgb(170, 170, 170);
}
.icon-grid .path12:before {
  content: "\e959";
  margin-left: -1em;
  color: rgb(170, 170, 170);
}
.icon-grid .path13:before {
  content: "\e95a";
  margin-left: -1em;
  color: rgb(170, 170, 170);
}
.icon-grid .path14:before {
  content: "\e95b";
  margin-left: -1em;
  color: rgb(170, 170, 170);
}
.icon-grid .path15:before {
  content: "\e95c";
  margin-left: -1em;
  color: rgb(170, 170, 170);
}
.icon-grid .path16:before {
  content: "\e95d";
  margin-left: -1em;
  color: rgb(170, 170, 170);
}
.icon-grid .path17:before {
  content: "\e95e";
  margin-left: -1em;
  color: rgb(170, 170, 170);
}
.icon-grid .path18:before {
  content: "\e95f";
  margin-left: -1em;
  color: rgb(170, 170, 170);
}
.icon-grid .path19:before {
  content: "\e960";
  margin-left: -1em;
  color: rgb(170, 170, 170);
}
.icon-grid .path20:before {
  content: "\e961";
  margin-left: -1em;
  color: rgb(170, 170, 170);
}
.icon-grid .path21:before {
  content: "\e962";
  margin-left: -1em;
  color: rgb(170, 170, 170);
}
.icon-grid .path22:before {
  content: "\e963";
  margin-left: -1em;
  color: rgb(170, 170, 170);
}
.icon-grid .path23:before {
  content: "\e964";
  margin-left: -1em;
  color: rgb(170, 170, 170);
}
.icon-grid .path24:before {
  content: "\e965";
  margin-left: -1em;
  color: rgb(170, 170, 170);
}
.icon-grid .path25:before {
  content: "\e966";
  margin-left: -1em;
  color: rgb(170, 170, 170);
}
.icon-grid .path26:before {
  content: "\e967";
  margin-left: -1em;
  color: rgb(170, 170, 170);
}
.icon-grid .path27:before {
  content: "\e968";
  margin-left: -1em;
  color: rgb(170, 170, 170);
}
.icon-grid .path28:before {
  content: "\e969";
  margin-left: -1em;
  color: rgb(170, 170, 170);
}
.icon-grid .path29:before {
  content: "\e96a";
  margin-left: -1em;
  color: rgb(170, 170, 170);
}
.icon-grid .path30:before {
  content: "\e96b";
  margin-left: -1em;
  color: rgb(170, 170, 170);
}
.icon-grid .path31:before {
  content: "\e96c";
  margin-left: -1em;
  color: rgb(170, 170, 170);
}
.icon-grid .path32:before {
  content: "\e96d";
  margin-left: -1em;
  color: rgb(170, 170, 170);
}
.icon-grid .path33:before {
  content: "\e96e";
  margin-left: -1em;
  color: rgb(170, 170, 170);
}
.icon-grid .path34:before {
  content: "\e96f";
  margin-left: -1em;
  color: rgb(170, 170, 170);
}
.icon-grid .path35:before {
  content: "\e970";
  margin-left: -1em;
  color: rgb(170, 170, 170);
}
.icon-grid .path36:before {
  content: "\e971";
  margin-left: -1em;
  color: rgb(170, 170, 170);
}
.icon-grid .path37:before {
  content: "\e972";
  margin-left: -1em;
  color: rgb(170, 170, 170);
}
.icon-images .path1:before {
  content: "\e973";
  color: rgb(170, 170, 170);
}
.icon-images .path2:before {
  content: "\e974";
  margin-left: -1em;
  color: rgb(77, 77, 77);
}
.icon-images .path3:before {
  content: "\e975";
  margin-left: -1em;
  color: rgb(77, 77, 77);
}
.icon-images .path4:before {
  content: "\e976";
  margin-left: -1em;
  color: rgb(170, 170, 170);
}
.icon-images .path5:before {
  content: "\e977";
  margin-left: -1em;
  color: rgb(170, 170, 170);
}
.icon-label .path1:before {
  content: "\e978";
  color: rgb(77, 77, 77);
}
.icon-label .path2:before {
  content: "\e979";
  margin-left: -1em;
  color: rgb(170, 170, 170);
}
.icon-label .path3:before {
  content: "\e97a";
  margin-left: -1em;
  color: rgb(170, 170, 170);
}
.icon-label .path4:before {
  content: "\e97b";
  margin-left: -1em;
  color: rgb(170, 170, 170);
}
.icon-label .path5:before {
  content: "\e97c";
  margin-left: -1em;
  color: rgb(170, 170, 170);
}
.icon-label .path6:before {
  content: "\e97d";
  margin-left: -1em;
  color: rgb(170, 170, 170);
}
.icon-list .path1:before {
  content: "\e97e";
  color: rgb(170, 170, 170);
}
.icon-list .path2:before {
  content: "\e97f";
  margin-left: -1em;
  color: rgb(170, 170, 170);
}
.icon-list .path3:before {
  content: "\e980";
  margin-left: -1em;
  color: rgb(170, 170, 170);
}
.icon-list .path4:before {
  content: "\e981";
  margin-left: -1em;
  color: rgb(77, 77, 77);
}
.icon-list .path5:before {
  content: "\e982";
  margin-left: -1em;
  color: rgb(170, 170, 170);
}
.icon-list .path6:before {
  content: "\e983";
  margin-left: -1em;
  color: rgb(170, 170, 170);
}
.icon-list .path7:before {
  content: "\e984";
  margin-left: -1em;
  color: rgb(170, 170, 170);
}
.icon-objects:before {
  content: "\e985";
  color: #4d4d4d;
}
.icon-properties:before {
  content: "\e986";
  color: #4d4d4d;
}
.icon-save:before {
  content: "\e987";
  color: #4d4d4d;
}
.icon-style:before {
  content: "\e988";
  color: #4d4d4d;
}
.icon-table .path1:before {
  content: "\e989";
  color: rgb(77, 77, 77);
}
.icon-table .path2:before {
  content: "\e98a";
  margin-left: -1em;
  color: rgb(170, 170, 170);
}
.icon-table .path3:before {
  content: "\e98b";
  margin-left: -1em;
  color: rgb(170, 170, 170);
}
.icon-text .path1:before {
  content: "\e98c";
  color: rgb(77, 77, 77);
}
.icon-text .path2:before {
  content: "\e98d";
  margin-left: -1em;
  color: rgb(170, 170, 170);
}
.icon-text1 .path1:before {
  content: "\e98e";
  color: rgb(77, 77, 77);
}
.icon-text1 .path2:before {
  content: "\e98f";
  margin-left: -1em;
  color: rgb(170, 170, 170);
}
.icon-text1 .path3:before {
  content: "\e990";
  margin-left: -1em;
  color: rgb(170, 170, 170);
}
.icon-textbox .path1:before {
  content: "\e991";
  color: rgb(77, 77, 77);
}
.icon-textbox .path2:before {
  content: "\e992";
  margin-left: -1em;
  color: rgb(77, 77, 77);
}
.icon-textbox .path3:before {
  content: "\e993";
  margin-left: -1em;
  color: rgb(77, 77, 77);
}
.icon-textbox .path4:before {
  content: "\e994";
  margin-left: -1em;
  color: rgb(170, 170, 170);
}
.icon-textbox .path5:before {
  content: "\e995";
  margin-left: -1em;
  color: rgb(170, 170, 170);
}
.icon-textbox .path6:before {
  content: "\e996";
  margin-left: -1em;
  color: rgb(170, 170, 170);
}
.icon-textbox .path7:before {
  content: "\e997";
  margin-left: -1em;
  color: rgb(170, 170, 170);
}
.icon-textbox .path8:before {
  content: "\e998";
  margin-left: -1em;
  color: rgb(170, 170, 170);
}
.icon-textbox .path9:before {
  content: "\e999";
  margin-left: -1em;
  color: rgb(170, 170, 170);
}
.icon-textbox .path10:before {
  content: "\e99a";
  margin-left: -1em;
  color: rgb(170, 170, 170);
}
.icon-toolbox:before {
  content: "\e99b";
  color: #4d4d4d;
}
.icon-utilities:before {
  content: "\ea43";
  color: #4d4d4d;
}
.icon-widgets:before {
  content: "\e99d";
  color: #4d4d4d;
}
.icon-workspaces:before {
  content: "\e99e";
  color: #4d4d4d;
}
.icon-button .path1:before {
  content: "\e99f";
  color: rgb(77, 77, 77);
}
.icon-button .path2:before {
  content: "\e9a0";
  margin-left: -1em;
  color: rgb(170, 170, 170);
}
.icon-button .path3:before {
  content: "\e9a1";
  margin-left: -1em;
  color: rgb(170, 170, 170);
}
.icon-button .path4:before {
  content: "\e9a2";
  margin-left: -1em;
  color: rgb(170, 170, 170);
}
.icon-button .path5:before {
  content: "\e9a3";
  margin-left: -1em;
  color: rgb(170, 170, 170);
}
.icon-button .path6:before {
  content: "\e9a4";
  margin-left: -1em;
  color: rgb(170, 170, 170);
}
.icon-button .path7:before {
  content: "\e9a5";
  margin-left: -1em;
  color: rgb(170, 170, 170);
}
.icon-button .path8:before {
  content: "\e9a6";
  margin-left: -1em;
  color: rgb(170, 170, 170);
}
.icon-card .path1:before {
  content: "\e9a7";
  color: rgb(77, 77, 77);
}
.icon-card .path2:before {
  content: "\e9a8";
  margin-left: -1em;
  color: rgb(170, 170, 170);
}
.icon-card .path3:before {
  content: "\e9a9";
  margin-left: -1em;
  color: rgb(77, 77, 77);
}
.icon-card .path4:before {
  content: "\e9aa";
  margin-left: -1em;
  color: rgb(102, 102, 102);
}
.icon-card .path5:before {
  content: "\e9ab";
  margin-left: -1em;
  color: rgb(102, 102, 102);
}
.icon-card .path6:before {
  content: "\e9ac";
  margin-left: -1em;
  color: rgb(102, 102, 102);
}
.icon-column .path1:before {
  content: "\e9ad";
  color: rgb(77, 77, 77);
}
.icon-column .path2:before {
  content: "\e9ae";
  margin-left: -1em;
  color: rgb(77, 77, 77);
}
.icon-column .path3:before {
  content: "\e9af";
  margin-left: -1em;
  color: rgb(77, 77, 77);
}
.icon-column .path4:before {
  content: "\e9b0";
  margin-left: -1em;
  color: rgb(170, 170, 170);
}
.icon-column .path5:before {
  content: "\e9b1";
  margin-left: -1em;
  color: rgb(170, 170, 170);
}
.icon-column .path6:before {
  content: "\e9b2";
  margin-left: -1em;
  color: rgb(170, 170, 170);
}
.icon-column .path7:before {
  content: "\e9b3";
  margin-left: -1em;
  color: rgb(170, 170, 170);
}
.icon-column .path8:before {
  content: "\e9b4";
  margin-left: -1em;
  color: rgb(170, 170, 170);
}
.icon-column .path9:before {
  content: "\e9b5";
  margin-left: -1em;
  color: rgb(170, 170, 170);
}
.icon-column .path10:before {
  content: "\e9b6";
  margin-left: -1em;
  color: rgb(170, 170, 170);
}
.icon-column .path11:before {
  content: "\e9b7";
  margin-left: -1em;
  color: rgb(170, 170, 170);
}
.icon-column .path12:before {
  content: "\e9b8";
  margin-left: -1em;
  color: rgb(170, 170, 170);
}
.icon-column .path13:before {
  content: "\e9b9";
  margin-left: -1em;
  color: rgb(170, 170, 170);
}
.icon-column .path14:before {
  content: "\e9ba";
  margin-left: -1em;
  color: rgb(170, 170, 170);
}
.icon-column .path15:before {
  content: "\e9bb";
  margin-left: -1em;
  color: rgb(170, 170, 170);
}
.icon-column .path16:before {
  content: "\e9bc";
  margin-left: -1em;
  color: rgb(170, 170, 170);
}
.icon-column .path17:before {
  content: "\e9bd";
  margin-left: -1em;
  color: rgb(170, 170, 170);
}
.icon-column .path18:before {
  content: "\e9be";
  margin-left: -1em;
  color: rgb(170, 170, 170);
}
.icon-column .path19:before {
  content: "\e9bf";
  margin-left: -1em;
  color: rgb(170, 170, 170);
}
.icon-micro-functions-logo2:before {
  content: "\ea6b";
}
.icon-formapps-digitise:before {
  content: "\ea7b";
  color: #fff;
}
.icon-folder-o-micro:before {
  content: "\ea7c";
  color: #ccc;
}
.icon-hamb-3:before {
  content: "\ea4a";
}
.icon-hamb-2:before {
  content: "\ea49";
}
.icon-hamb-1:before {
  content: "\ea48";
}
.icon-explore:before {
  content: "\ea46";
}
.icon-apps-o:before {
  content: "\ea45";
}
.icon-apps:before {
  content: "\ea44";
}
.icon-datasources-logo:before {
  content: "\eaea";
  color: #fff;
}
.icon-flow-logo:before {
  content: "\ea41";
  color: #fff;
}
.icon-studio-logo:before {
  content: "\ea42";
  color: #fff;
}
.icon-log-app:before {
  content: "\ea40";
}
.icon-stop-app:before {
  content: "\ea3e";
  color: #fff;
}
.icon-start-app:before {
  content: "\ea3f";
  color: #fff;
}
.icon-passive:before {
  content: "\e93d";
  color: #e94e24;
}
.icon-pause1:before {
  content: "\e93e";
}
.icon-portal-logo:before {
  content: "\e93f";
  color: #fff;
}
.icon-start:before {
  content: "\e940";
}
.icon-stop:before {
  content: "\e941";
}
.icon-active:before {
  content: "\e942";
  color: #a1b71d;
}
.icon-dottle-r:before {
  content: "\e943";
  color: #808080;
}
.icon-formapps-logo-icon:before {
  content: "\e945";
  color: #fff;
}
.icon-notr:before {
  content: "\ea8b";
  color: #a8a8a8;
}
.icon-excel:before {
  content: "\e944";
}
.icon-edit1:before {
  content: "\e946";
}
.icon-checkmark:before {
  content: "\e947";
  color: #fff;
}
.icon-delete:before {
  content: "\e948";
  color: #fff;
}
.icon-info1:before {
  content: "\e99c";
}
.icon-pause2:before {
  content: "\e9c3";
  color: #0070c9;
}
.icon-profile1:before {
  content: "\ea67";
}
.icon-refresh:before {
  content: "\ea68";
}
.icon-resfresh2:before {
  content: "\ea69";
  color: #0070c9;
}
.icon-search1:before {
  content: "\ea6a";
  color: #e6e6e6;
}
.icon-setting:before {
  content: "\ea6c";
  color: #e6e6e6;
}
.icon-settingbottom:before {
  content: "\ea6d";
}
.icon-signout:before {
  content: "\ea6e";
}
.icon-studio:before {
  content: "\ea6f";
  color: #f2f2f2;
}
.icon-test:before {
  content: "\ea88";
  color: #0070c9;
}
.icon-timer:before {
  content: "\ea89";
  color: #4d4d4d;
}
.icon-transactionlogs:before {
  content: "\ea8a";
}
.icon-viewfiles:before {
  content: "\ea9e";
  color: #0070c9;
}
.icon-webhookapi:before {
  content: "\ea8c";
  color: #4d4d4d;
}
.icon-activitylogs:before {
  content: "\ea8d";
}
.icon-add:before {
  content: "\ea8e";
  color: #fff;
}
.icon-allresources:before {
  content: "\ea8f";
  color: #f2f2f2;
}
.icon-appmanager:before {
  content: "\ea90";
  color: #f2f2f2;
}
.icon-arrowleft:before {
  content: "\ea91";
}
.icon-clear:before {
  content: "\ea92";
  color: #0070c9;
}
.icon-close1:before {
  content: "\ea93";
  color: #f2f2f2;
}
.icon-close-2:before {
  content: "\ea94";
  color: #0070c9;
}
.icon-div-classNameitem-span-i-classfas-fa-playi-span-div:before {
  content: "\ea95";
  color: #e6e6e6;
}
.icon-console:before {
  content: "\ea96";
  color: #f2f2f2;
}
.icon-dataprocessing:before {
  content: "\ea97";
  color: #4d4d4d;
}
.icon-datasources:before {
  content: "\ea98";
  color: #f2f2f2;
}
.icon-date-end:before {
  content: "\ea99";
  color: #4e4d4d;
}
.icon-date-start:before {
  content: "\ea9a";
  color: #4e4d4d;
}
.icon-dottle:before {
  content: "\ea9c";
  color: #808080;
}
.icon-download1:before {
  content: "\ea9d";
  color: #0070c9;
}
.icon-dublucate:before {
  content: "\eae9";
  color: #0070c9;
}
.icon-expand:before {
  content: "\ea9f";
  color: #0070c9;
}
.icon-filestorage:before {
  content: "\eaab";
  color: #f2f2f2;
}
.icon-flow:before {
  content: "\eaaa";
  color: #f2f2f2;
}
.icon-flowmanager:before {
  content: "\eaa9";
  color: #e6e6e6;
}
.icon-folder:before {
  content: "\eaa8";
}
.icon-function-symbol:before {
  content: "\eaa7";
}
.icon-gateway:before {
  content: "\eaa6";
  color: #f2f2f2;
}
.icon-key:before {
  content: "\eaa5";
  color: #0070c9;
}
.icon-logs:before {
  content: "\eaa4";
  color: #0070c9;
}
.icon-menumore:before {
  content: "\eaa3";
  color: #f2f2f2;
}
.icon-microfunctions:before {
  content: "\eaa2";
  color: #f2f2f2;
}
.icon-monitoring:before {
  content: "\eaa1";
  color: #f2f2f2;
}
.icon-new-create:before {
  content: "\eadf";
  color: #f2f2f2;
}
.icon-notification:before {
  content: "\eade";
  color: #e6e6e6;
}
.icon-notification2:before {
  content: "\eadd";
}
.icon-overview:before {
  content: "\eadc";
}
.icon-password:before {
  content: "\eaa0";
}
