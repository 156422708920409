@import "variables";

.fa-FileManager {
  position: fixed;
  background: rgba(0, 0, 0, 0.6);
  width: 100%;
  left: 0;
  height: 100%;
  top: 0;
  padding: 30px;
  z-index: 999999;
  .fa-FileManager-wrapper {
    background: #f2f2f2;

    .fa-FileManager-title {
      background: $color;
      padding: 10px 15px;
      align-items: center;

      .title {
        color: #fff;
        font-weight: 600;
        font-size: 16px;

        .sub-text {
          color: rgb(172, 172, 172);
          font-weight: 400;
          font-size: 10.5px;
        }
      }

      .close {
        cursor: pointer;
        margin-left: auto;
        font-size: 16px;
        color: rgb(194, 194, 194);
        padding-right: 10px;
      }
    }
  }

  .fa-FileManager-body {
    position: relative;
    &.none {
      background: #434343;
    }

    .sidebar {
      background: #ececec;
      flex: 0 0 250px;
      -ms-flex: 0 0 250px;
      max-width: 250px;
      z-index: 1;

      & > .title {
        align-items: center;
        padding: 5px;
        font-weight: 600;
        background: #484848;
        color: #dcdcdc;

        button {
          margin-left: auto;
          height: 28px;

          i {
            font-size: 14px;
          }

          .ms-Button-label {
            font-size: 11px;
            margin-top: -1px;
          }
        }
      }

      .fa-FileManager-buckets {
        .not-found {
          padding: 5px 10px;
          font-size: 12px;
          font-style: italic;
        }
        .item {
          cursor: pointer;
          padding: 7.5px 6px;
          font-weight: 500;
          border-bottom: 1px solid #dedede;
          font-size: 12px;
          @include t(all, 0.25s);
          align-items: center;
          display: flex;

          i {
            padding-right: 4px;
            color: #0a406a;
            padding-left: 1px;
          }

          &.active {
            background: #cfe0ec;
            border-color: #bbd1e0;
          }

          &:last-child {
            border-bottom: none;
          }
        }
      }
    }

    .bucket-wrapper {
      height: calc(100vh - 115px);
      padding: 0px;
      overflow-y: hidden;

      .bucket-header {
        align-items: center;
        padding: 5px;
        font-weight: 600;
        background: #484848;
        position: sticky;
        z-index: 10;
        top: 0px;
        color: #dcdcdc;

        .title {
          // span.active {
          //   margin-left: 10px;
          //   background: #757575;
          //   font-size: 10px;
          //   font-weight: 600;
          //   padding: 2px 7px;
          //   color: #e4e4e4;
          // }
          .text {
            padding: 1px 10.5px 1px 6.5px;
          }
          .ms-Dropdown-caretDownWrapper {
            height: 18px !important;
            i {
              height: 18px !important;
              position: relative;
              top: -8px;
              left: 4px;
              font-size: 9px;
              color: #fff;
            }
          }
          .ms-Dropdown-title {
            border: none;
            background: #797979;
            color: #fff;
            height: 18px;
            line-height: 18px;
            font-size: 10px;
            min-width: 100px;
          }
          span.new {
            margin-left: 10px;
            background: #4e8467;
            font-size: 10px;
            font-weight: 600;
            padding: 2px 10px;
            color: #e4e4e4;
            cursor: pointer;
            line-height: 14px;
          }
          span.delete {
            margin-left: 10px;
            background: #b14a4a;
            font-size: 10px;
            font-weight: 600;
            padding: 2px 10px;
            color: #e4e4e4;
            cursor: pointer;
            line-height: 14px;
          }
        }

        button {
          margin-left: auto;
          height: 28px;

          i {
            font-size: 14px;
          }

          .ms-Button-label {
            font-size: 11px;
            margin-top: -1px;
          }
        }
      }

      .bucket-search {
        padding: 5px 6px;
        background: #ececec;
        align-items: center;
        position: relative;
        z-index: 1;

        .custom-textfield {
          .ms-TextField-fieldGroup {
            height: 27px;
            width: 250px;

            &:after {
              display: none;
            }

            input {
              font-weight: 500;
              font-size: 11px;
              font-family: $font;
            }
          }
        }

        .custom-dropdown {
          .ms-Dropdown {
            width: 250px;

            .ms-Dropdown-caretDownWrapper {
              line-height: 27px;
            }

            .ms-Dropdown-title {
              height: 27px;
              line-height: 25px;
              border-color: #ccc;
              color: rgb(41, 41, 41);
              font-size: 11px;
            }
          }
        }

        .name {
          align-items: center;
          margin-right: 12.5px;

          .title {
            font-size: 11.5px;
            font-weight: 600;
            margin-right: 6px;
          }
        }

        button {
          height: 28px;

          i {
            font-size: 14px;
          }

          .ms-Button-label {
            font-size: 11px;
            margin-top: -1px;
          }
        }
      }

      .bucket-files {
        display: block;
        clear: both;
        height: calc(100vh - 228px);
        overflow-y: auto;
        width: 100%;
        padding: 10px;
        position: relative;

        &::-webkit-scrollbar {
          width: 5px;
        }

        &::-webkit-scrollbar-track {
          background: rgb(73, 73, 73);
        }

        &::-webkit-scrollbar-thumb {
          background: #888;
        }

        .dark-wrapper {
          top: 0;
          position: absolute;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.7);
          left: 0;
          z-index: 99;
        }

        .item {
          flex: 0 0 10%;
          width: 10%;
          max-width: 10%;
          -ms-flex: 0 0 10%;
          padding: 3px;
          float: left;

          &.active {
            .item-wrapper {
              border-color: #418a68;
              border-width: 1px;

              // &:after {
              //   content: "";
              //   position: absolute;
              //   left: 0;
              //   width: 100%;
              //   height: 100%;
              //   background: rgba(0, 0, 0, 0.25);
              //   @include t(all, 0.25s);
              //   top: 0;
              //   opacity: 1;
              //   pointer-events: none;
              // }

              .title {
                background: #418a68;
                color: #fff;
              }

              .check {
                opacity: 1;
                @include s(1);
                z-index: 9;
              }
            }
          }

          .item-wrapper {
            border: 1px solid #626262;
            background: #ccc;
            cursor: pointer;
            height: 10vw;
            overflow: hidden;
            position: relative;
            @include t(all, 0.25s);
            figure {
              height: 100%;
              img {
                height: 100% !important;
                object-fit: cover;
                width: 100% !important;
              }
            }

            .info {
              position: absolute;
              left: 0px;
              top: 0px;
              background: #6f6f6f;
              color: #fff;
              width: 20px;
              height: 20px;
              z-index: 10;
              text-align: center;
              line-height: 20px;
              transform-origin: top right;
              font-size: 12px;
              opacity: 1;
              @include s(1);
              @include t(all, 0.25s);
              &::selection {
                background: transparent !important;
              }
            }

            .check {
              position: absolute;
              right: 0px;
              top: 0px;
              background: #4a9c76;
              color: #fff;
              width: 20px;
              height: 20px;
              text-align: center;
              line-height: 20px;
              transform-origin: top right;
              font-size: 12px;
              opacity: 0;
              @include s(0);
              @include t(all, 0.25s);
            }

            &:hover {
              &:after {
                opacity: 1;
              }
            }

            .title {
              position: absolute;
              bottom: 0;
              width: 100%;
              padding: 5px 7.5px;
              font-size: 10px;
              background: #626262;
              line-height: 10px;
              color: rgb(211, 211, 211);
              z-index: 1;
              font-weight: 400;
              @include t(all, 0.25s);

              .text {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 75%;
                display: inline-block;
              }

              .ext {
                font-weight: 600;
                position: relative;
                top: -0.5px;
              }
            }

            &:after {
              content: "";
              position: absolute;
              left: 0;
              width: 100%;
              height: 100%;
              background: rgba(0, 0, 0, 0.25);
              @include t(all, 0.25s);
              top: 0;
              opacity: 0;
              pointer-events: none;
            }
          }
        }
      }

      .upload-screen {
        position: absolute;
        z-index: 99;
        background: #fff;
        width: 100%;
        top: 38px;
        height: calc(100% - 38px);

        .title {
          background: #ececec;
          margin-bottom: 20px;
          padding: 10px;
          font-weight: 600;
          font-size: 14px;

          .sub-text {
            font-size: 11.5px;
            font-weight: 400;
          }
        }

        .filepond--wrapper {
          padding: 5px 25px;
          max-height: calc(100vh - 200px);
          overflow-y: auto;
          padding: 10px 10px 50px 10px;

          &::-webkit-scrollbar {
            width: 3px;
          }
  
          &::-webkit-scrollbar-track {
            background: rgb(73, 73, 73);
          }
  
          &::-webkit-scrollbar-thumb {
            background: #888;
          }

          .filepond--drop-label.filepond--drop-label label {
            font-size: 13px;
          }
        }
      }

      .file-information {
        position: fixed;
        right: 30px;
        width: 280px;
        background: #ececec;
        z-index: 11;
        height: calc(100vh - 155px);
        overflow-y: auto;

        & > .title {
          padding: 11px 15px;
          align-items: center;
          font-weight: 500;
          font-size: 12.5px;
          background: #535353;
          color: #e7e7e7;

          i {
            font-size: 11px;
          margin-right: 3px;
          }

          .close {
            margin-left: auto;
            padding-right: 10px;
            cursor: pointer;
          }
        }

        &::-webkit-scrollbar {
          width: 3px;
        }

        &::-webkit-scrollbar-track {
          background: rgb(73, 73, 73);
        }

        &::-webkit-scrollbar-thumb {
          background: #888;
        }

        .information-list {
          .item {
            padding: 5px 12.5px;
            border-bottom: 1px solid #cacaca;

            figure {
              img {
                max-width: 50% !important;
              }
            }

            .custom-textfield {
              .ms-TextField-fieldGroup {
                height: auto;
                height: 32px;

                &:after {
                  display: none;
                }

                input,
                textarea {
                  font-weight: 500;
                  font-size: 11px;
                  color: #000;
                  font-family: $font;
                }
              }
            }

            .text {
              font-weight: 700;
              font-size: 12px;
              margin-bottom: 5px;
            }

            .value {
              padding-bottom: 5px;
              font-weight: 400;
            }
          }

          .item-indicators {
            padding: 12.5px;

            .view {
              background: $green-color;
            }

            .delete {
              background: #ce6060;
              &:hover {
                background: #b95757;
              }
            }

            button {
              margin-right: 5px;
              border: none;
              margin-right: 5px;
              background: #0078d4;
              font-family: $font;
              color: #fff;
              font-size: 11px;
              cursor: pointer;
              font-weight: 600;
              padding: 7.5px 20px;
              @include t(all, 0.25s);
            }
          }
        }
      }

      .bucket-footer {
        position: sticky;
        width: 100%;
        bottom: 0;
        z-index: 10;
        background: #484848;
        padding: 5px;
        align-items: center;

        .pager {
          align-items: center;

          .pager-indicator {
            cursor: pointer;
            background: rgb(134, 134, 134);
            margin-right: 2.5px;
            text-align: center;
            color: rgb(226, 226, 226);
            padding: 0px 5px;
            font-size: 10px;
            line-height: 20px;
            i {
              &::selection {
                background: transparent;
              }
            }
          }

          .info {
            color: rgb(197, 197, 197);
            font-size: 10.5px;
            margin-left: 5px;
            font-weight: 500;
            position: relative !important;
            top: 0;
            right: 0;
          }
        }

        .bucket-indicators {
          margin-left: auto;

          .select {
            border: none;
            margin-right: 5px;
            background: #0078d4;
            font-family: $font;
            color: #fff;
            font-size: 11px;
            cursor: pointer;
            font-weight: 600;
            padding: 7.5px 25px;
            @include t(all, 0.25s);
            &[disabled] {
              opacity: 0.2;
              background: #ffffff;
              color: rgb(49, 49, 49) !important;
              pointer-events: none;
            }

            &:hover {
              background: #0d71be;
            }
          }

          .remove {
            border: 0;
            font-family: Arial, Helvetica sans-serif;
            color: #e6d7d7;
            text-decoration: underline;
            font-size: 10.5px;
            font-weight: 500;
            cursor: pointer;
            padding: 7.5px 7px;
            @include t(all, 0.25s);
            background: transparent;
          }
        }
      }

      &::-webkit-scrollbar {
        width: 5px;
      }

      &::-webkit-scrollbar-track {
        background: rgb(73, 73, 73);
      }

      &::-webkit-scrollbar-thumb {
        background: #888;
      }
    }
  }

  .loader {
    position: absolute !important;
    top: 0px;
    width: 100%;
    height: 100%;
    left: 0%;
    background: rgba(42, 42, 42, 0.94);
    z-index: 9999999;
    display: flex;
    justify-content: center;
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
    opacity: 0;
    pointer-events: none;
    align-items: center;

    &.full {
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 9999999;
    }

    &.wrapper-loader {
      position: absolute;
      z-index: 99999 !important;

      .ms-Spinner-circle {
        border-color: rgb(75, 75, 132) rgb(144, 144, 185) rgb(144, 144, 185);
      }
    }

    &.verification-loader {
      z-index: 99999999;
      background: rgba(255, 255, 255, 0.98);
    }

    .largeContainer .ms-Spinner-circle,
    .ms-Spinner-circle.ms-Spinner--xLarge {
      width: 45px;
      height: 45px;
    }

    &.active {
      opacity: 1;
      pointer-events: auto;
    }

    &.absolute {
      position: absolute;
      z-index: 20;

      .ms-Spinner-circle.ms-Spinner--xLarge {
        width: 45px;
        height: 45px;
      }
    }
  }
}

.fa-FileManager-Modal {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 999;
  background: rgba(42, 42, 42, 0.94);
  display: flex;
  align-items: center;
  justify-content: center;

  .modal-wrapper {
    width: 350px;
    background: #fff;

    & > .title {
      background: $color;
      padding: 10px 15px;
      align-items: center;

      .text {
        color: #fff;
        font-weight: 600;
        font-size: 16px;

        .sub-text {
          color: rgb(172, 172, 172);
          font-weight: 400;
          font-size: 10.5px;
        }
      }

      .close {
        margin-left: auto;
        cursor: pointer;
        margin-left: auto;
        font-size: 14px;
        color: rgb(194, 194, 194);
        padding-right: 10px;
      }
    }
  }

  .modal-content {
    padding: 15px;

    &:after {
      clear: both;
      display: block;
      content: "";
    }

    .ms-Label {
      font-size: 12.5px;
    }

    .ms-TextField-fieldGroup {
      height: 30px !important;
      width: 100%;

      .ms-TextField-errorMessage {
        font-size: 11px;
        font-weight: 500;
      }

      &:after {
        display: none;
      }

      input {
        font-weight: 500;
        font-size: 11px;
        font-family: $font;
      }
    }

    .ms-Button {
      margin-top: 10px;
      height: 28.5px;
      padding: 0px 10px;
    }
  }
}

.filepond--action-revert-item-processing {
  display: none !important;
}

.filepond--root {
  margin-bottom: 0px !important;
  .filepond--drop-label {
    height: 100px;
    label {
      display: flex;
      flex-direction: column;
      font-size: 13.5px;
      font-weight: 500;
      i {
        color: #676565;
        font-size: 27px;
        display: block;
      }
      .upload-label {
        font-size: 11px;
        text-decoration: underline;
        cursor: pointer;
        display: block;
      }
    }
  }
}