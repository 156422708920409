// -----------------------------------------------------------------------------
// Formapps IO
// -----------------------------------------------------------------------------

/// Color
/// @type Color
$color: #404040;

/// Dark Color
/// @type Color
$dark-color: #2d2d2d;

/// Soft Color
/// @type Color
$soft-color: #4d4d4d;

/// Light Color
/// @type Color
$light-color: #525252;

/// Blue Color
/// @type Color
$blue-color: #178df7;

/// Yellow Color
/// @type Color
$yellow-color: #e88012;

/// Red Color
/// @type Color
$red-color: #b92828;

/// Green Color
/// @type Color
$green-color: #2b925f;

/// Font
/// @type Font
$font: 'Roboto', sans-serif;
