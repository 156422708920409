// -----------------------------------------------------------------------------
// Formapps IO
// -----------------------------------------------------------------------------

* {
	outline: none;
}

ul {
	list-style-type: none !important;
}

ul.disc {
	list-style-type: disc !important;
}

a {
	text-decoration: none !important;
	cursor: pointer !important;
}

figure {
	margin: 0px;
}

.row {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.col {
	-ms-flex-preferred-size: 0;
	flex-basis: 0;
	-webkit-box-flex: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	max-width: 100%;
}

.d-none {
	display: none !important;
}

i:before {
	color: inherit !important;
}

// .right {
//   float: right !important;
//   display: block;
// }

// .left {
//   float: left !important;
//   display: block;
// }

.m-left-auto {
	margin-left: auto !important;
}

.m-right-auto {
	margin-right: auto !important;
}

.align-center {
	align-items: center !important;
}

.text-align-center {
	text-align: center !important;
}

.text-align-left {
	text-align: left !important;
}

.text-align-right {
	text-align: right !important;
}

.justify-center {
	justify-content: center !important;
}

.no-background,
.container {
	background: none !important;
	position: relative !important;
}

.clear {
	clear: both;
	display: block;
}

.fluid-image {
	max-width: 100%;
	height: auto;
}

.full-image img {
	max-width: 100% !important;
	display: block !important;
	height: auto !important;
}

.no-padding {
	padding: 0 !important;
}

.no-margin {
	margin: 0 !important;
}

.padding {
	padding: 0px 15px !important;
}

.margin {
	margin: 0px 15px !important;
}

.margin-10 {
	margin: 10px 0px !important;
}

.reverse-margin-5 {
	margin: 5px -7.5px !important;
}

.reverse-margin {
	margin: 0px -15px !important;
}

.no-selection {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
