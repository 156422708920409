// FA Grid **/
.fa-Grid {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	z-index: 1;
	width: 100%;
	padding: 15px;

	// &.is-designing {
	// 	@include t(all, 0.25s);
	// 	&:hover {
	// 		background: rgba(254, 255, 238, 0.35);
	// 	}
	// }
}

.fa-Col,
.fa-Col-1,
.fa-Col-10,
.fa-Col-11,
.fa-Col-12,
.fa-Col-2,
.fa-Col-3,
.fa-Col-4,
.fa-Col-5,
.fa-Col-6,
.fa-Col-7,
.fa-Col-8,
.fa-Col-9,
.fa-Col-auto {
	position: relative;
	min-height: 1px;
	padding-right: 7.5px;
	padding-left: 7.5px;
	width: 100%;
}

.fa-Col {
	-ms-flex-preferred-size: 0;
	flex-basis: 0;
	-ms-flex-positive: 1;
	flex-grow: 1;
	max-width: 100%;
	z-index: 1;
	// &.is-designing {
	// 	@include t(all, 0.25s);
	// 	&:hover {
	// 		background: rgba(254, 255, 238, 0.45);
	// 	}
	// }
}

.fa-Col-auto {
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	width: auto;
	max-width: none;
}

.fa-Col-1 {
	-ms-flex: 0 0 8.333333%;
	flex: 0 0 8.333333%;
	max-width: 8.333333%;
}

.fa-Col-2 {
	-ms-flex: 0 0 16.666667%;
	flex: 0 0 16.666667%;
	max-width: 16.666667%;
}

.fa-Col-3 {
	-ms-flex: 0 0 25%;
	flex: 0 0 25%;
	max-width: 25%;
}

.fa-Col-4 {
	-ms-flex: 0 0 33.333333%;
	flex: 0 0 33.333333%;
	max-width: 33.333333%;
}

.fa-Col-5 {
	-ms-flex: 0 0 41.666667%;
	flex: 0 0 41.666667%;
	max-width: 41.666667%;
}

.fa-Col-6 {
	-ms-flex: 0 0 50%;
	flex: 0 0 50%;
	max-width: 50%;
}

.fa-Col-7 {
	-ms-flex: 0 0 58.333333%;
	flex: 0 0 58.333333%;
	max-width: 58.333333%;
}

.fa-Col-8 {
	-ms-flex: 0 0 66.666667%;
	flex: 0 0 66.666667%;
	max-width: 66.666667%;
}

.fa-Col-9 {
	-ms-flex: 0 0 75%;
	flex: 0 0 75%;
	max-width: 75%;
}

.fa-Col-10 {
	-ms-flex: 0 0 83.333333%;
	flex: 0 0 83.333333%;
	max-width: 83.333333%;
}

.fa-Col-11 {
	-ms-flex: 0 0 91.666667%;
	flex: 0 0 91.666667%;
	max-width: 91.666667%;
}

.fa-Col-12 {
	-ms-flex: 0 0 100%;
	flex: 0 0 100%;
	max-width: 100%;
}

.fa-Container {
	width: 100%;
	padding-right: 7.5px;
	padding-left: 7.5px;
	margin-right: auto;
	margin-left: auto;
	z-index: 1;
}

@media (min-width: 1200px) {
	.fa-Container {
		max-width: 1140px;
	}
}

.fa-List {
	z-index: 1;
}

@media (max-width: 992px) {
	.fa-Col,
	.fa-Col-1,
	.fa-Col-10,
	.fa-Col-11,
	.fa-Col-12,
	.fa-Col-2,
	.fa-Col-3,
	.fa-Col-4,
	.fa-Col-5,
	.fa-Col-6,
	.fa-Col-7,
	.fa-Col-8,
	.fa-Col-9,
	.fa-Col-auto {
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%;
	}
	.droppable-area {
		display: block !important;
		width: 100% !important;
	}
	.table-loader {
		height: auto !important;
	}
	.fa-List {
		min-height: auto !important;
	}
}
